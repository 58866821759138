/**
 * Advanced home page.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/
import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Header from './components/Header';
import DeviceList from './components/DeviceList';
import DeviceListHeader from './components/DeviceListHeader';
import useTranslation from 'hooks/translations';
import { useStoreState } from 'easy-peasy';
import spinGif from 'assets/img/Ellipsis58px.svg';
import MessageCard from './components/Message/MessageCard';

export default function AdvancedHomePage({
  user,
  setUser,
  logout,
  setPage,
  pageSettings,
  db
}) {
  const translation = useTranslation();
  const scrollRef = useRef();
  const [opacity, setOpacity] = useState(false);
  console.log(pageSettings);
  var messageCard = (
    <MessageCard
      setPage={setPage}
      user={user}
      pageSettings={pageSettings}
      db={db}
    />
  );
  if (pageSettings !== undefined) {
    if (pageSettings.groupId !== undefined) {
      messageCard = '';
    }
  }
  //const [messageCenter] = useState(messageCard);
  //const [isPending, startTransition] = useTransition();

  const loading = useStoreState(state => state.loading);
  const [loadingTxt, setLoading] = useState('');

  useEffect(() => {
    if (loading) {
      //if (devices.length == 0) {
      setLoading(<img src={spinGif} alt="loading..." />);
      //}
    } else {
      setLoading('');
    }
  }, [loading]);

  function scrolled() {
    if (opacity) {
      if (document.getElementById('personal-home-page').scrollTop < 60) {
        setOpacity(false);
      }
    } else {
      if (document.getElementById('personal-home-page').scrollTop > 40) {
        setOpacity(true);
      }
    }
  }

  return (
    <div
      className="d-flex flex-column home-page h-100"
      id="personal-home-page"
      onScroll={scrolled}
      ref={scrollRef}
    >
      <Header user={user} setUser={setUser} setPage={setPage} logout={logout} />

      <div className="text-center fixed-headings">
        <DeviceListHeader opacity={opacity} />
        <p>{loadingTxt} </p>
      </div>
      <div className="space-for-headings"></div>

      <div className="container">
        <div className="flex-row justify-content-center device-cards-container row gx-3 gy-3">
          {messageCard}
          <DeviceList
            setPage={setPage}
            user={user}
            pageSettings={pageSettings}
            db={db}
          />
        </div>
      </div>
      <footer className="text-light d-flex flex-grow-1 justify-content-center align-items-end footer-styling mt-4">
        <p>©&nbsp;{translation.brand_company}</p>
      </footer>
    </div>
  );
}

AdvancedHomePage.propTypes = {
  props: PropTypes.object,
  user: PropTypes.object,
  setUser: PropTypes.func,
  logout: PropTypes.func,
  setPage: PropTypes.func,
  pageSettings: PropTypes.object,
  db: PropTypes.object
};
