/**
 * Safera FlowHero.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2025. All rights reserved. Safera Oy
 **/
import * as utils from 'utils';

export default function FlowHero(deviceData) {
  var variant = utils.SAFERA_FLOWHERO_VARIANT['DCKV'];
  if (
    deviceData.hw_version === utils.SENSE_HW_MAJOR['DCKV_HUB'] ||
    deviceData.hw_version === utils.SENSE_HW_MAJOR['DCKV_HUB_V2']
  ) {
    variant = utils.SAFERA_FLOWHERO_VARIANT['HUB'];
  } else {
    if (!isWiFiCapabilitySet(deviceData.hw_capabilities_id)) {
      variant = utils.SAFERA_FLOWHERO_VARIANT['NODE'];
    }
  }
  function isWiFiCapabilitySet(capability) {
    if (capability === undefined) {
      return true;
    }
    return (
      (capability & (1 << utils.SENSE_HW_CAPABILITY_BITS['HWCAPS_WIFI'])) !== 0
    );
  }

  return {
    id: utils.SAFERA_PRODUCTS['FLOWHERO'],
    mechanicVariant: deviceData.mechanic_variant,
    variant: variant
  };
}
