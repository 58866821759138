/**
 * Set user terms and policy
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/
import * as utils from '../../utils';

export default async function SetUserPolicy(db, uid, policy) {
  try {
    console.log(db, uid, policy);
    if (db === undefined || uid === undefined || policy === undefined) {
      console.error(db, uid, policy);
      return;
    }
    const setDevRef = db.collection(utils.USERS_KEY).doc(uid);

    await setDevRef.set(
      {
        policy_accepted: policy,
        policy_accepted_timestamp: Date.now()
      },
      { merge: true }
    );

    return;
  } catch (error) {
    console.error(error);
    return;
  }
}
