/**
 * Settings.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import StatusIcon from './StatusIcon';
import * as utils from '../../../utils';
import NotificationContact from './Notification/NotificationContact';
import NotificationSettings from './Notification/NotificationSettings';
import NotificationAdditionalService from './Notification/NotificationAdditionalService';
import DeviceHistory from './History/DeviceHistory';
import InstallationInfo from './Info/InstallationInfo';
import DeviceInfo from './Info/DeviceInfo';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEllipsisH,
  faChevronLeft,
  faBullhorn,
  faChartBar,
  faTools,
  faExclamationCircle,
  faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons';
import DeviceTypeImage from './DeviceTypeImage';
import BattryOrGeaseFilterIcon from './BattryOrGeaseFilterIcon';
import ToastMessage from '../ToastMessage';
import Modal from 'react-bootstrap/Modal';
import writeModel from '../../../components/DataBase/DataBaseWriteModel';
import { useStoreActions } from 'easy-peasy';
import TextSanitizing from '../../../components/TextSanitizing';
import useTranslation from 'hooks/translations';
import { ReadDeviceData } from 'components/DataBase/Device/ReadDeviceData';
import RemoveDevice from 'components/DataBase/Device/RemoveDevice';
import TagManager from 'react-gtm-module';

export default function DeviceSettings(props) {
  //console.log(props);
  const translation = useTranslation();
  const notificationSettingsMenuRef = useRef();
  const historyMenuRef = useRef();
  const deviceInfoMenuRef = useRef();
  const subPage = useRef(props.pageSettings.subPage);
  const [show, setShow] = useState(false);
  const [toastContent, setToastContent] = useState({
    message: translation.changes_saved,
    success: true
  });
  const [showModal, setShowModal] = useState(false);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const handleRemoveClose = () => setShowRemoveModal(false);
  const handleRemoveShow = () => setShowRemoveModal(true);

  const { setDevice, dbUpdate } = useStoreActions(actions => ({
    setDevice: actions.setDevice,
    dbUpdate: actions.dbUpdate
  }));

  const newDeviceNameRef = useRef();

  const [currentDevice, setCurrentDevice] = useState(props.pageSettings.device);

  if (currentDevice.data === undefined) {
    if (currentDevice.device_id !== undefined && props.user !== undefined) {
      readDeveceDataFromDB(currentDevice.device_id, props.db);
    }
  }

  async function readDeveceDataFromDB(deviceId, db) {
    try {
      if (
        props.user.uid === undefined ||
        deviceId === undefined ||
        db === undefined
      ) {
        console.error('Data undefined: ', db);
        return;
      }
      const newDevice = await ReadDeviceData(db, deviceId, props.user.uid);
      console.log('New:', newDevice);
      if (newDevice !== undefined) {
        setCurrentDevice(newDevice);
      } else {
        setToastContent({ message: 'Access denied!', success: false });

        setShow(true);
        setTimeout(() => {
          props.setPage({
            page: utils.PERSONAL_PAGES['DEVICE_LIST']
          });
        }, 4000);
      }
    } catch (error) {
      console.error(error);
    }
  }
  //var currentDevice = props.pageSettings.device;
  function showModalContent(selectedDevice) {
    console.log(selectedDevice);
    setCurrentDevice(selectedDevice);
    handleShow();
  }

  function showRemoveModalContent(selectedDevice) {
    console.log(selectedDevice);
    setCurrentDevice(selectedDevice);
    handleRemoveShow();
  }

  async function handleRemoveDevice(device) {
    await RemoveDevice(props.db, device, props.user.uid);
    //removeDevice(device);
    handleRemoveClose();
    props.setPage({
      page: utils.PERSONAL_PAGES['DEVICE_LIST']
    });
  }

  function showToast(isSaved) {
    console.log(isSaved);
    if (!isSaved) {
      setToastContent({ message: translation.saving_failed, success: false });
    }
    setShow(true);
  }

  async function changeDeviceName(deviceObj) {
    try {
      handleClose();
      const newDevName = TextSanitizing(newDeviceNameRef.current.value, false);
      deviceObj.name = newDevName;
      console.log(deviceObj.docId);
      setDevice(deviceObj);
      writeModel.path =
        utils.USERS_KEY + '/' + props.user.uid + '/' + utils.DEVICE_KEY;
      writeModel.doc = deviceObj.docId;
      writeModel.data = {
        name: newDevName
      };
      writeModel.callback = showToast;
      dbUpdate(writeModel);
    } catch (error) {
      console.error(error);
    }
  }

  const scrollContainer = useRef(null);
  const tabPaneRef = useRef(null);

  useEffect(() => {
    if (
      subPage.current === utils.PERSONAL_DEVICE_SETTINGS_SUB_PAGE['DEVICE_INFO']
    ) {
      scrollContainer.current.scrollLeft = 200;
    }
  }, []);

  var notificationSettingsActive = 'active';
  var historyActive = '';
  var infoActive = '';

  function newTabSelected(page) {
    tabPaneRef.current.scrollTo(0, 0);
    const SMALL_SCREEN_SIZE_WIDTH = 650;
    // console.log(scrollContainer.current.scrollLeft, menuWidth);
    if (scrollContainer.current.clientWidth < SMALL_SCREEN_SIZE_WIDTH) {
      switch (page) {
        case utils.PERSONAL_DEVICE_SETTINGS_SUB_PAGE['NOTIFICATION_SETTINGS']:
          //scrollContainer.current.scrollLeft = 0;
          moveScrollToPosition(0);
          break;
        case utils.PERSONAL_DEVICE_SETTINGS_SUB_PAGE['HISTORY']:
          moveScrollToPosition(
            notificationSettingsMenuRef.current.clientWidth -
              historyMenuRef.current.clientWidth / 4
          );
          break;
        case utils.PERSONAL_DEVICE_SETTINGS_SUB_PAGE['DEVICE_INFO']:
          //scrollContainer.current.scrollLeft =
          moveScrollToPosition(
            notificationSettingsMenuRef.current.clientWidth +
              historyMenuRef.current.clientWidth
          );
          break;

        default:
          break;
      }
    }
    gaEventTracker(getPageName(page));
  }

  function getPageName(page) {
    var pageName = '';
    switch (page) {
      case utils.PERSONAL_DEVICE_SETTINGS_SUB_PAGE['NOTIFICATION_SETTINGS']:
        pageName = 'Notification settings';
        break;
      case utils.PERSONAL_DEVICE_SETTINGS_SUB_PAGE['HISTORY']:
        pageName = 'Device history';
        break;
      case utils.PERSONAL_DEVICE_SETTINGS_SUB_PAGE['DEVICE_INFO']:
        pageName = 'Device info';
        break;

      default:
        pageName = 'Unknown device settings';
        break;
    }
    return pageName;
  }

  function gaEventTracker(message) {
    console.log(message);
    const analyticId =
      props.user.analytics_id !== undefined
        ? props.user.analytics_id
        : props.user.uid;
    TagManager.dataLayer({
      dataLayer: {
        userId: analyticId,
        userProject: 'Personal',
        page: message
      },
      dataLayerName: 'SaferaDataLayer'
    });
  }

  async function moveScrollToPosition(position) {
    const currentPosition = scrollContainer.current.scrollLeft;
    if (currentPosition > position) {
      for (let index = 0; index < currentPosition - position; index++) {
        await new Promise(resolve => setTimeout(resolve, 2));
        if (scrollContainer.current !== null) {
          scrollContainer.current.scrollLeft--;
        }
      }
    } else {
      for (let index = 0; index < position - currentPosition; index++) {
        await new Promise(resolve => setTimeout(resolve, 2));
        if (scrollContainer.current !== null) {
          scrollContainer.current.scrollLeft++;
        }
      }
    }
  }

  switch (props.pageSettings.subPage) {
    case utils.PERSONAL_DEVICE_SETTINGS_SUB_PAGE['NOTIFICATION_SETTINGS']:
      notificationSettingsActive = 'active';
      historyActive = '';
      infoActive = '';
      gaEventTracker('Notification settings');
      break;
    case utils.PERSONAL_DEVICE_SETTINGS_SUB_PAGE['HISTORY']:
      notificationSettingsActive = '';
      historyActive = 'active';
      infoActive = '';
      gaEventTracker('Device history');
      break;
    case utils.PERSONAL_DEVICE_SETTINGS_SUB_PAGE['DEVICE_INFO']:
      notificationSettingsActive = '';
      historyActive = '';
      infoActive = 'active';
      gaEventTracker('Device info');
      break;
    default:
      break;
  }

  return (
    <>
      <div className="device-settings-page h-100">
        <div className="settings-page-container container p-0 h-100 pb-md-3">
          <div className="container settings-container h-100">
            <div className="row hero-background-gradient h-100">
              <div
                className={`col hero-image sense-${DeviceTypeImage(
                  currentDevice.data
                )} h-100`}
              >
                <div className="row sticky-top">
                  <div className="col">
                    <div className="row justify-content-start flex-nowrap nav-bar-settings-page">
                      <div className="col-4 d-flex justify-content-start align-items-center settings-navbar-left-column overflow-hidden p-0">
                        <button
                          className="btn btn-link flex-shrink-0 text-white"
                          onClick={() =>
                            props.setPage({
                              page: utils.PERSONAL_PAGES['DEVICE_LIST']
                            })
                          }
                        >
                          <FontAwesomeIcon
                            icon={faChevronLeft}
                            className="text-primary me-2"
                          />
                          {translation.back}
                        </button>
                      </div>
                      <div className="col-4 d-flex flex-grow-0 justify-content-center align-items-center p-0 ps-2">
                        <h4 className="text-nowrap text-start text-white m-0 p-0 lh-base">
                          {translation.device_settings}
                        </h4>
                      </div>
                      <div className="col-4 d-flex flex-fill justify-content-end align-items-center"></div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-auto d-inline-flex hero-settings-page w-100 p-0 py-3">
                    <div className="w-100">
                      <div className="container">
                        <div className="row row-cols-3">
                          <div className="col-auto d-flex justify-content-center align-items-center p-0 settings-hero-first-column">
                            <StatusIcon device={currentDevice} />
                          </div>
                          <div className="col-1 d-flex flex-grow-1 align-items-center p-0 ps-2">
                            <h4 className="device-cards-name m-0">
                              {currentDevice.name}
                            </h4>
                          </div>
                          <div className="col-auto d-inline-flex justify-content-end align-items-center p-0 ps-3">
                            <div className="dropdown d-inline-flex justify-content-end align-items-center justify-content-sm-center align-items-sm-center">
                              <button
                                className="btn bg-white d-inline-flex justify-content-center align-items-center flex-nowrap m-auto btn-falcon-default btn-circle-with-icon shadow"
                                aria-expanded="false"
                                data-bs-toggle="dropdown"
                                type="button"
                              >
                                <FontAwesomeIcon
                                  icon={faEllipsisH}
                                  className="text-primary"
                                />
                              </button>
                              <div className="dropdown-menu dropdown-menu-end">
                                <p
                                  className="dropdown-item"
                                  onClick={() =>
                                    showModalContent(currentDevice)
                                  }
                                >
                                  {translation.rename_the_device}
                                </p>
                                <p
                                  className="dropdown-item link-danger"
                                  onClick={() =>
                                    showRemoveModalContent(currentDevice)
                                  }
                                >
                                  {translation.remove_the_device}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="container">
                        <BattryOrGeaseFilterIcon
                          device={currentDevice}
                          divClass={'settings-hero-first-column'}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row d-flex flex-column row-tabs-system-container">
                  <div className="col tabs-system-container-settings-page p-0 h-100 w-100">
                    <div className="d-md-flex tabbable col-auto h-100">
                      <ul
                        className="nav nav-pills flex-shrink-0 align-items-end flex-md-column align-items-md-start flex-md-nowrap tab-items-settings-page w-auto h-md-100 overflow-scroll p-3 pb-md-5 gap-md-2 gap-3 py-3"
                        role="tablist"
                        ref={scrollContainer}
                      >
                        <li
                          className="nav-item col-auto w-md-100"
                          role="presentation"
                          ref={notificationSettingsMenuRef}
                        >
                          <a
                            className={`${notificationSettingsActive} 
                            nav-link  d-flex align-items-center`}
                            role="tab"
                            data-bs-toggle="pill"
                            href="#tab-1"
                            onClick={() =>
                              newTabSelected(
                                utils.PERSONAL_DEVICE_SETTINGS_SUB_PAGE[
                                  'NOTIFICATION_SETTINGS'
                                ]
                              )
                            }
                          >
                            <div className="d-inline-flex dropdown-menu-icon">
                              <FontAwesomeIcon icon={faBullhorn} />
                            </div>
                            {translation.remote_alarm_settings}
                          </a>
                        </li>
                        <li
                          className="nav-item col-auto w-md-100"
                          role="presentation"
                          ref={historyMenuRef}
                        >
                          <a
                            className={`nav-link d-flex align-items-center ${historyActive}`}
                            role="tab"
                            data-bs-toggle="pill"
                            href="#tab-2"
                            onClick={() =>
                              newTabSelected(
                                utils.PERSONAL_DEVICE_SETTINGS_SUB_PAGE[
                                  'HISTORY'
                                ]
                              )
                            }
                          >
                            <div className="d-inline-flex dropdown-menu-icon">
                              <FontAwesomeIcon icon={faChartBar} />
                            </div>
                            {translation.history_view}
                          </a>
                        </li>
                        <li
                          className="nav-item col-auto w-md-100"
                          role="presentation"
                          ref={deviceInfoMenuRef}
                        >
                          <a
                            className={`nav-link d-flex align-items-center me-md-0 me-3 ${infoActive}`}
                            role="tab"
                            data-bs-toggle="pill"
                            href="#tab-3"
                            onClick={() =>
                              newTabSelected(
                                utils.PERSONAL_DEVICE_SETTINGS_SUB_PAGE[
                                  'DEVICE_INFO'
                                ]
                              )
                            }
                          >
                            <div className="d-inline-flex dropdown-menu-icon">
                              <FontAwesomeIcon icon={faTools} />
                            </div>
                            {translation.device_information}
                          </a>
                        </li>
                      </ul>
                      <div
                        className="tab-content flex-grow-1 tab-content-settings-page w-auto p-3 mb-sm-3 h-md-100 pt-5"
                        ref={tabPaneRef}
                      >
                        <div
                          className={`tab-pane ${notificationSettingsActive}`}
                          role="tabpanel"
                          id="tab-1"
                        >
                          <NotificationContact
                            setPage={props.setPage}
                            user={props.user}
                          />
                          <NotificationSettings
                            db={props.db}
                            user={props.user}
                            device={currentDevice}
                          />
                          <NotificationAdditionalService
                            user={props.user}
                            device={currentDevice}
                            auth_user={props.auth_user}
                          />
                        </div>
                        <div
                          className={`tab-pane ${historyActive}`}
                          role="tabpanel"
                          id="tab-2"
                        >
                          <DeviceHistory
                            db={props.db}
                            device={currentDevice}
                            user={props.user}
                          />
                        </div>
                        <div
                          className={`tab-pane ${infoActive}`}
                          role="tabpanel"
                          id="tab-3"
                        >
                          <InstallationInfo
                            db={props.db}
                            device={currentDevice}
                            user={props.user}
                          />
                          <DeviceInfo device={currentDevice} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        role="dialog"
        tabIndex="-1"
        id="modal-rename-device"
        show={showModal}
        onHide={handleClose}
      >
        <Modal.Header className="pe-4 border-0">
          <h4 className="modal-title pe-5">{currentDevice.name}</h4>
          <button
            type="button"
            className="btn-close"
            onClick={handleClose}
            aria-label="Close"
          ></button>
        </Modal.Header>
        <Modal.Body>
          <label className="form-label">Anna uusi nimi</label>
          <input
            type="text"
            className="form-control"
            placeholder={currentDevice.name}
            ref={newDeviceNameRef}
          />
        </Modal.Body>
        <Modal.Footer className="border-0 pt-0 pb-4">
          <div className="w-100">
            <button
              className="btn btn-primary w-100 shadow"
              type="button"
              data-bs-dismiss="modal"
              onClick={() => changeDeviceName(currentDevice)}
            >
              {translation.save}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal
        role="dialog"
        tabIndex="-1"
        id="modal-remove-device"
        show={showRemoveModal}
        onHide={handleRemoveClose}
      >
        <div className="modal-header pe-4 border-0">
          <button
            type="button"
            className="btn-close"
            onClick={handleRemoveClose}
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <h2 className="text-center">
            {translation.confirm_the_removal_of_the_device}
          </h2>
          <FontAwesomeIcon
            icon={faExclamationCircle}
            className="text-center text-danger w-100 fs-4 mt-2"
          />
          <hr />
          <p className="text-start text-black">
            {translation.remove_device_be_sure}
          </p>
          <p className="fw-bold text-start text-black">{currentDevice.name}</p>

          <p className="text-start text-black">
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              className="text-warning me-2"
            />
            {translation.remove_device_info_1}
          </p>
          <p className="text-start text-muted fs--1">
            {translation.remove_device_info_2}{' '}
            {translation.remove_device_info_1} {translation.by_app}
          </p>
          <hr />
          <div className="col">
            <button
              className="btn btn-primary w-100 shadow mb-3"
              type="button"
              onClick={handleRemoveClose}
            >
              {translation.cancel_device_remove}
            </button>
            <div>
              <button
                className="btn btn-primary link-danger w-100 shadow btn-falcon-default"
                type="button"
                onClick={() => handleRemoveDevice(currentDevice)}
              >
                {translation.remove_device}
              </button>
            </div>
          </div>
        </div>
        <div className="modal-footer d-flex justify-content-center border-0 pt-0 pb-4">
          <div id="paypal-button-container-3"></div>
        </div>
      </Modal>
      <ToastMessage
        setShow={setShow}
        success={toastContent.success}
        show={show}
        message={toastContent.message}
      />
    </>
  );
}
DeviceSettings.propTypes = {
  setPage: PropTypes.func,
  pageSettings: PropTypes.object,
  user: PropTypes.object,
  db: PropTypes.object,
  auth_user: PropTypes.object
};
