/**
 * Device type image.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/
import * as utils from '../../../utils';

export default function DeviceTypeImage(deviceData) {
  //console.log(type);
  //var deviceType = '';
  var deviceProduct = deviceData.product;
  if (deviceProduct === undefined) {
    return 'wifi';
  }
  switch (deviceProduct.id) {
    case utils.SAFERA_PRODUCTS['CR']:
      return 'integral';
    case utils.SAFERA_PRODUCTS['FLOWHERO']:
      return 'integral';
    case utils.SAFERA_PRODUCTS['PRO']:
      return 'pro';
    case utils.SAFERA_PRODUCTS['WIFI']:
      if (
        deviceProduct.mechanicVariant ===
        utils.SENSE_MECHANICAL_VARIANT['BLACK_BLACK']
      ) {
        return 'pro';
      } else {
        return 'wifi';
      }
    default:
      return 'unknown';
  }
  // if (
  //   deviceProduct.id == utils.SAFERA_PRODUCTS['CR'] ||
  //   deviceProduct.id == utils.SAFERA_PRODUCTS['FLOWHERO']
  // ) {
  //   return 'integral';
  // } else if (deviceProduct.id == utils.SAFERA_PRODUCTS['PRO']) {
  //   return 'pro';
  // } else if (deviceProduct.id == utils.SAFERA_PRODUCTS['WIFI']) {
  //   if (
  //     deviceProduct.mechanicVariant ==
  //     utils.SENSE_MECHANICAL_VARIANT['BLACK_BLACK']
  //   ) {
  //     return 'pro';
  //   } else {
  //     return 'wifi';
  //   }
  // } else {
  //   return 'wifi';
  // }
  // function getHWVersion(hwId) {
  //   var hex = hwId !== undefined ? hwId.toString(16) : 0;
  //   hex = '0'.repeat(8 - hex.length) + hex;
  //   var hwIdBytes = [];

  //   for (var c = 0; c < hex.length; c += 2) {
  //     hwIdBytes.push(parseInt(hex.substring(c, c + 2), 16));
  //   }

  //   return hwIdBytes[1] !== undefined ? hwIdBytes[1] : 0;
  // }
}
