/**
 * Wizard remote alarm
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/
import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import useTranslation from 'hooks/translations';
import { ReadDevice } from 'components/DataBase/Device/ReadDevice';
import { useStoreActions } from 'easy-peasy';
import * as utils from 'utils';
import ProductCatalog from 'components/Product/ProductCatalog';

export default function WizardRemoteAlarms({ props }) {
  console.log(props);
  const translation = useTranslation();
  const addDevice = useStoreActions(actions => actions.addDevice);
  var buttonTxt = translation.wizard_cloud_remote_alarms_button;
  const product = ProductCatalog({
    hw_version: parseInt(props?.config?.hw_major),
    mechanic_variant: parseInt(props?.config?.mech_variant),
    variant: 0,
    hw_id: 0
  });
  if (product.id === utils.SAFERA_PRODUCTS['FLOWHERO']) {
    buttonTxt = translation.short_brand_name;
  }

  const deviceRef = useRef();
  const propsRef = useRef(props);
  const addDeviceRef = useRef(addDevice);
  useEffect(() => {
    if (props.currentStep === 4) {
      if (
        propsRef.current.form !== undefined &&
        propsRef.current.form.deviceName !== undefined &&
        propsRef.current.db !== undefined &&
        propsRef.current.user !== undefined
      ) {
        prepareDevice();
      } else {
        console.error('Error!');
      }
    }
    async function prepareDevice() {
      try {
        const data = await ReadDevice(
          propsRef.current.db,
          propsRef.current.deviceId,
          propsRef.current.user.uid
        );
        deviceRef.current = {
          device_id: propsRef.current.deviceId,
          name: propsRef.current.form.deviceName,
          registered_timestamp: new Date().getTime(),
          isAlarm_notify_enabled: false,
          isAlarm_sms_notify_enabled: false,
          isBattery_notify_enabled: false,
          isBattery_sms_notify_enabled: false,
          isError_notify_enabled: false,
          isError_sms_notify_enabled: false,
          isOffline_notify_enabled: false,
          isOffline_sms_notify_enabled: false,
          isPoor_air_quality_notify_enabled: false,
          isPoor_air_quality_sms_notify_enabled: false,
          data: data
        };
        addDeviceRef.current(deviceRef.current);
      } catch (error) {
        console.error(error);
      }
    }
  }, [props.currentStep]);

  const done = e => {
    e.preventDefault();
    if (product.id === utils.SAFERA_PRODUCTS['FLOWHERO']) {
      window.location.replace('/advanced/index.html');
    }
    if (deviceRef.current !== undefined) {
      props.showCustomPage({
        page: utils.PERSONAL_PAGES['DEVICE_SETTINGS'],
        subPage:
          utils.PERSONAL_DEVICE_SETTINGS_SUB_PAGE['NOTIFICATION_SETTINGS'],
        device: deviceRef.current
      });
    } else {
      props.showCustomPage({
        page: utils.PERSONAL_PAGES['DEVICE_LIST']
      });
    }
  };

  return (
    <div className="wizard-div-above-container p-0">
      <div className="container wizard-container p-0 shadow-lg">
        <div className="d-flex flex-column wizard-content pb-4">
          <div className="d-flex flex-column wizard-upper-content">
            <div className="container h-100 py-4">
              <div className="row d-flex flex-column justify-content-start align-items-center h-100">
                <div className="col d-flex justify-content-center align-items-center mb-2">
                  <i className="fas fa-bullhorn wizard-icon fs--2 me-2 text-light bg-dark p-1 rounded-pill"></i>
                  <h6 className="text-start d-inline-flex mb-0">
                    {translation.wizard_cloud_remote_alarms_step}
                  </h6>
                </div>
                <div className="col d-flex flex-grow-0 justify-content-center">
                  <h3 className="text-center">
                    {translation.wizard_cloud_remote_alarms_heading}
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-grow-1 align-items-start wizard-lower-content">
            <div className="row d-flex flex-column align-items-start w-100 m-0 h-100">
              <div className="col d-inline-flex flex-column flex-grow-1 justify-content-start h-100">
                <div className="row">
                  <div className="col d-flex flex-column justify-content-center align-items-center">
                    <p className="text-center">
                      {translation.wizard_cloud_remote_alarms_p1}
                      <br />
                      <br />
                      {translation.wizard_cloud_remote_alarms_p2}
                    </p>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col">
                    <div></div>
                  </div>
                </div>
                <div className="row mt-1">
                  <div className="col">
                    <button
                      className="btn btn-primary text-center mb-3 btn-falcon-default bg-primary text-white border-primary w-100"
                      type="button"
                      onClick={done}
                    >
                      {buttonTxt}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

WizardRemoteAlarms.propTypes = {
  props: PropTypes.object,
  nextStep: PropTypes.func,
  previousStep: PropTypes.func,
  currentStep: PropTypes.func,
  form: PropTypes.object,
  user: PropTypes.object,
  deviceId: PropTypes.string,
  db: PropTypes.func,
  showCustomPage: PropTypes.func,
  config: PropTypes.object
};
