/**
 * Header.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/

import React from 'react';
import PropTypes from 'prop-types';
import logo from '../../assets/img/Safera-logo-cloud.svg';
import { Container, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as utils from '../../utils';
import useTranslation from 'hooks/translations';
import {
  faCube,
  faShieldAlt,
  faUserCircle
  //faStore
} from '@fortawesome/free-solid-svg-icons';
import {
  faAddressCard,
  faCreditCard,
  faBell,
  faQuestionCircle
} from '@fortawesome/free-regular-svg-icons';

export default function Header(props) {
  const translation = useTranslation();

  return (
    <nav className="navbar navbar-light sticky-top">
      <div className="container-fluid">
        <Container>
          <Row>
            <div className="col-6 col-md-6 d-flex align-items-center p-0">
              <p className="navbar-brand d-flex flex-fill">
                <img
                  alt="Safera logo"
                  className="d-inline-flex navbar-brand"
                  src={logo}
                  width=""
                />
              </p>
            </div>
            <div className="col-6 col-md-6 d-flex justify-content-end p-0">
              <div className="d-flex flex-fill justify-content-between top-right-menu-items">
                <a
                  className="d-inline-flex align-items-center"
                  href={translation.support_home_page_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon
                    icon={faQuestionCircle}
                    className="text-white fs-2 mb-3"
                  />
                </a>
                <p
                  className="d-inline-flex align-items-center"
                  style={{ cursor: 'pointer' }}
                >
                  <FontAwesomeIcon icon={faBell} className="text-white fs-2" />
                  {/* <span className="translate-middle bg-danger rounded-circle p-2"></span> */}
                </p>
                <div className="dropdown d-inline-flex align-items-center">
                  <p
                    className="d-inline-flex align-items-center "
                    aria-expanded="false"
                    data-bs-toggle="dropdown"
                  >
                    <FontAwesomeIcon
                      icon={faUserCircle}
                      className="text-white fs-5"
                    />
                  </p>

                  <div className="dropdown-menu dropdown-menu-end shadow-lg">
                    <div
                      className="dropdown-item d-flex align-items-center"
                      style={{ cursor: 'pointer' }}
                      onClick={() =>
                        props.setPage({
                          page: utils.PERSONAL_PAGES['ACCOUNT_SETTINGS'],
                          subPage:
                            utils.PERSONAL_ACCOUNT_SETTINGS_SUB_PAGE['CONTACT']
                        })
                      }
                    >
                      <p className="d-inline-flex dropdown-menu-icon">
                        <FontAwesomeIcon
                          icon={faAddressCard}
                          className="text-primary"
                        />
                      </p>
                      {translation.contact_information}
                    </div>
                    <div
                      className="dropdown-item d-flex align-items-center"
                      style={{ cursor: 'pointer' }}
                      onClick={() =>
                        props.setPage({
                          page: utils.PERSONAL_PAGES['ACCOUNT_SETTINGS'],
                          subPage:
                            utils.PERSONAL_ACCOUNT_SETTINGS_SUB_PAGE[
                              'ACCOUT_SETTINGS'
                            ]
                        })
                      }
                    >
                      <p className="d-inline-flex dropdown-menu-icon">
                        <FontAwesomeIcon
                          icon={faCube}
                          className="text-primary"
                        />
                      </p>
                      {translation.plans_and_subscriptions}
                    </div>
                    <div
                      className="dropdown-item d-flex align-items-center"
                      style={{ cursor: 'pointer' }}
                      onClick={() =>
                        props.setPage({
                          page: utils.PERSONAL_PAGES['ACCOUNT_SETTINGS'],
                          subPage:
                            utils.PERSONAL_ACCOUNT_SETTINGS_SUB_PAGE['PAYMENT']
                        })
                      }
                    >
                      <p className="d-inline-flex dropdown-menu-icon">
                        <FontAwesomeIcon
                          icon={faCreditCard}
                          className="text-primary"
                        />
                      </p>
                      {translation.billing}
                    </div>
                    <div
                      className="dropdown-item d-flex align-items-center"
                      style={{ cursor: 'pointer' }}
                      onClick={() =>
                        props.setPage({
                          page: utils.PERSONAL_PAGES['ACCOUNT_SETTINGS'],
                          subPage:
                            utils.PERSONAL_ACCOUNT_SETTINGS_SUB_PAGE['POLICY']
                        })
                      }
                    >
                      <p className="d-inline-flex dropdown-menu-icon">
                        <FontAwesomeIcon
                          icon={faShieldAlt}
                          className="text-primary"
                        />
                      </p>
                      {translation.privacy}
                    </div>
                    {/* <a
                      className="dropdown-item d-flex align-items-center"
                      style={{ cursor: 'pointer' }}
                      onClick={() =>
                        window.location.assign('/advanced/index.html?view=5')
                      }
                    >
                      <div className="d-inline-flex dropdown-menu-icon">
                        <FontAwesomeIcon
                          icon={faStore}
                          className="text-primary"
                        />
                      </div>
                      Store
                    </a> */}
                    <div className="dropdown-divider"></div>
                    <p
                      className="dropdown-item"
                      onClick={props.logout}
                      style={{ cursor: 'pointer' }}
                    >
                      {translation.sign_out}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    </nav>
  );
}

Header.propTypes = {
  user: PropTypes.object,
  setUser: PropTypes.func,
  setPage: PropTypes.func,
  logout: PropTypes.func
};
