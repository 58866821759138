/**
 * Wizard log in successfully
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/
import React from 'react';
import PropTypes from 'prop-types';
import useTranslation from 'hooks/translations';

export default function WizardLogInSuccessfully({ props }) {
  console.log(props);
  const translation = useTranslation();
  function signout() {
    props.firebaseSignout(props.auth);
  }

  return (
    <div className="wizard-div-above-container p-0">
      <div className="container wizard-container p-0 shadow-lg">
        <div className="d-flex flex-column wizard-content pb-4">
          <div className="d-flex flex-column wizard-upper-content">
            <div className="container h-100 py-4">
              <div className="row d-flex flex-column justify-content-start align-items-center h-100">
                <div className="col d-flex justify-content-center align-items-center mb-2">
                  <i className="fas fa-user-circle fs-1 me-2"></i>
                  <h6 className="text-start d-inline-flex mb-0">
                    {translation.wizard_cloud_log_in_step}
                  </h6>
                </div>
                <div className="col d-flex flex-grow-0 justify-content-center">
                  <h3 className="text-center">
                    {translation.wizard_cloud_logged_in_heading}
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-grow-1 align-items-start wizard-lower-content">
            <div className="row d-flex flex-column align-items-start w-100 m-0 h-100">
              <div className="col d-inline-flex flex-column flex-grow-1 justify-content-start h-100">
                <div className="row mb-3">
                  <div className="col d-flex justify-content-center">
                    <div className="card card-user-account">
                      <div className="card-body px-3">
                        <div className="d-flex align-items-center w-100 h-100">
                          <div className="me-2">
                            <i className="fa fa-user-circle text-dark fa-lg"></i>
                          </div>
                          <div className="text-truncate">
                            <h5 className="text-truncate mb-0">
                              {props.user !== undefined ? props.user.email : ''}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col">
                    <button
                      className="btn btn-primary text-center mb-3 btn-falcon-default bg-primary text-white border-primary w-100"
                      type="button"
                      onClick={props.nextStep}
                    >
                      {translation.wizard_cloud_next_button}
                    </button>
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    <button
                      className="btn btn-link text-center mb-3 bg-white w-100"
                      type="button"
                      onClick={signout}
                    >
                      {translation.wizard_cloud_logged_log_out_button}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
WizardLogInSuccessfully.propTypes = {
  props: PropTypes.object,
  nextStep: PropTypes.func,
  firebaseSignout: PropTypes.func,
  user: PropTypes.object,
  auth: PropTypes.object
};
