/**
 * Device type.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/

import React from 'react';
import PropTypes from 'prop-types';
import * as utils from 'utils';

export default function DeviceType({ deviceData }) {
  //console.log(deviceData);
  var product = deviceData?.product;
  if (product === undefined) {
    return 'Sense';
  }
  var type = product.id;
  switch (product.id) {
    case utils.SAFERA_PRODUCTS['BT']:
      type = 'Sense BT';
      break;
    case utils.SAFERA_PRODUCTS['WIFI']:
      type = 'Sense Wi-Fi';
      break;
    case utils.SAFERA_PRODUCTS['PRO']:
      type = 'Sense Pro';
      break;
    case utils.SAFERA_PRODUCTS['CR']:
      type = 'Sense Integral';
      break;
    case utils.SAFERA_PRODUCTS['FLOWHERO']:
      type = 'FlowHero';
      break;
    case utils.SAFERA_PRODUCTS['WAQM']:
      type = 'WAQM';
      break;
    default:
      type = 'Unknown';
  }
  return <>{type}</>;
}

DeviceType.propTypes = {
  deviceData: PropTypes.object
};
