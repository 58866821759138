/**
 * Notifications additional service.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import writeModel from '../../../../components/DataBase/DataBaseWriteModel';
import { useStoreActions } from 'easy-peasy';
import * as utils from '../../../../utils';
import useTranslation from 'hooks/translations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { sendEmailVerification } from 'firebase/auth';

export default function NotificationAdditionalService({
  user,
  device,
  auth_user
}) {
  const translation = useTranslation();
  //console.log(device);
  const setDevice = useStoreActions(actions => actions.setDevice);
  const dbUpdate = useStoreActions(actions => actions.dbUpdate);

  const [showEmailVerification, setShowEmailVerification] = useState(false);
  const handleEmailVerificationClose = () => setShowEmailVerification(false);
  const handleEmailVerificationShow = () => setShowEmailVerification(true);

  const [modalBody, setModalBody] = useState(
    <>
      <p>{translation.email_verification_message_a}</p>
      <button
        className="btn btn-primary w-100 shadow"
        type="button"
        onClick={handleVerification}
      >
        {translation.email_verification_button}
      </button>
    </>
  );

  const [isAirQualityEmailEnabled, setIsAirQualityEmailEnabled] = useState(
    device.isPoor_air_quality_notify_enabled !== undefined
      ? device.isPoor_air_quality_notify_enabled
      : true
  );

  const [isOfflineEmailEnabled, setIsOfflineEmailEnabled] = useState(
    device.isOffline_notify_enabled !== undefined
      ? device.isOffline_notify_enabled
      : true
  );
  const [isBatteryEmailEnabled, setIsBatteryEmailEnabled] = useState(
    device.isBattery_notify_enabled !== undefined
      ? device.isBattery_notify_enabled
      : true
  );
  const [isErrorEmailEnabled, setIsErrorEmailEnabled] = useState(
    device.isError_notify_enabled !== undefined
      ? device.isError_notify_enabled
      : true
  );

  var hideBatterySettingsStr = '';
  if (
    device.data !== undefined &&
    device.data.hw_capabilities_id !== undefined
  ) {
    if (
      device.data.hw_capabilities_id === utils.SENSE_HW_CAPABILITIES['CR_PRO']
    ) {
      hideBatterySettingsStr = 'd-none';
    }
  }

  useEffect(() => {
    if (device.isPoor_air_quality_notify_enabled === undefined) {
      setIsAirQualityEmailEnabled(true);
    } else if (device.isPoor_air_quality_notify_enabled) {
      setIsAirQualityEmailEnabled(true);
    } else {
      setIsAirQualityEmailEnabled(false);
    }

    if (device.isOffline_notify_enabled === undefined) {
      setIsOfflineEmailEnabled(true);
    } else if (device.isOffline_notify_enabled) {
      setIsOfflineEmailEnabled(true);
    } else {
      setIsOfflineEmailEnabled(false);
    }

    if (device.isError_notify_enabled === undefined) {
      setIsErrorEmailEnabled(true);
    } else if (device.isError_notify_enabled) {
      setIsErrorEmailEnabled(true);
    } else {
      setIsErrorEmailEnabled(false);
    }

    if (device.isBattery_notify_enabled === undefined) {
      setIsBatteryEmailEnabled(true);
    } else if (device.isBattery_notify_enabled) {
      setIsBatteryEmailEnabled(true);
    } else {
      setIsBatteryEmailEnabled(false);
    }
  }, [device]);

  function handleVerification() {
    sendEmailVerification(auth_user);
    setModalBodyToCheckVerification();
  }

  function setModalBodyToCheckVerification() {
    setModalBody(
      <>
        <p>
          {translation.email_verification_message_b1} {user.email}.{' '}
          {translation.email_verification_message_b2}
        </p>
        <button
          type="button"
          className="btn btn-light w-100 shadow mb-3"
          onClick={handleVerificationDone}
        >
          {translation.email_verification_done_button}
        </button>
      </>
    );
  }

  function handleVerificationDone() {
    console.log('done');
    window.location.reload(false);
  }
  function handleChange({ target }) {
    var emailSettingName;
    if (target.checked && !user.emailVerified) {
      console.log(target.checked);
      handleEmailVerificationShow();
      return;
    }
    switch (target.id) {
      case 'airCheck':
        emailSettingName = 'isPoor_air_quality_notify_enabled';
        setIsAirQualityEmailEnabled(target.checked);

        break;
      case 'offlineCheck':
        emailSettingName = 'isOffline_notify_enabled';
        setIsOfflineEmailEnabled(target.checked);
        break;
      case 'errorCheck':
        emailSettingName = 'isError_notify_enabled';
        setIsErrorEmailEnabled(target.checked);
        break;
      case 'batteryCheck':
        emailSettingName = 'isBattery_notify_enabled';
        setIsBatteryEmailEnabled(target.checked);
        break;
      default:
        console.error.apply('Error: Unknow notification.');
        return;
    }
    if (emailSettingName === undefined) {
      return;
    }

    //var currentDevice = { ...device };

    device[emailSettingName] = target.checked;

    setDevice(device);
    writeModel.path = utils.USERS_KEY + '/' + user.uid + '/' + utils.DEVICE_KEY;
    writeModel.doc = device.docId;
    writeModel.data = {
      [emailSettingName]: device[emailSettingName]
    };
    writeModel.callback = undefined;
    dbUpdate(writeModel);
  }
  return (
    <>
      <div className="container p-0 pb-3">
        <div className="card shadow">
          <div className="card-body px-3 py-3 pb-3">
            <div
              className={`flex-column d-flex mb-3 align-items-center w-100 ${hideBatterySettingsStr}`}
            >
              <div className="d-flex align-items-center w-100">
                <div className="flex-shrink-1 flex-grow-1">
                  <div>
                    <div className="container p-0">
                      <div className="row flex-grow-0 flex-nowrap panel-item-heading-with-link">
                        <div className="col d-flex flex-grow-1">
                          <h4>
                            {translation.remote_alarm_for_battery_replacement}
                          </h4>
                        </div>
                        <div className="col-auto text-end flex-grow-0 flex-shrink-1 justify-content-end flex-wrap">
                          <OverlayTrigger
                            trigger="click"
                            rootClose
                            placement="left"
                            overlay={
                              <Popover
                                id="popover-status"
                                style={{ margin: 0 }}
                              >
                                <Popover.Header as="h3">
                                  {translation.more_information}
                                </Popover.Header>
                                <Popover.Body>
                                  <p>{translation.i002_battery_replacement}</p>
                                </Popover.Body>
                              </Popover>
                            }
                          >
                            <p style={{ cursor: 'pointer' }}>
                              <FontAwesomeIcon
                                icon={faInfoCircle}
                                className="text-primary fs-1"
                              />
                            </p>
                          </OverlayTrigger>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex-shrink-1 flex-grow-1 w-100">
                <div>
                  <div className="container p-0">
                    <div className="row flex-grow-0 flex-nowrap panel-item-heading-with-link">
                      <div className="col">
                        <div className="form-check d-flex align-items-start form-switch mb-0">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="batteryCheck"
                            onChange={handleChange}
                            checked={isBatteryEmailEnabled}
                          />
                          <label
                            className="form-check-label mt-1"
                            htmlFor="formCheck-3"
                          >
                            {translation.email}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="bg-400 mb-0" />
              </div>
            </div>
            <div className="flex-column d-flex mb-3 align-items-center w-100">
              <div className="d-flex align-items-center w-100">
                <div className="flex-shrink-1 flex-grow-1">
                  <div>
                    <div className="container p-0">
                      <div className="row flex-grow-0 flex-nowrap panel-item-heading-with-link">
                        <div className="col d-flex flex-grow-1">
                          <h4>{translation.remote_alarm_for_device_errors}</h4>
                        </div>
                        <div className="col-auto text-end flex-grow-0 flex-shrink-1 justify-content-end flex-wrap">
                          <OverlayTrigger
                            trigger="click"
                            rootClose
                            placement="left"
                            overlay={
                              <Popover
                                id="popover-status"
                                style={{ margin: 0 }}
                              >
                                <Popover.Header as="h3">
                                  {translation.more_information}
                                </Popover.Header>
                                <Popover.Body>
                                  {translation.remote_alarm_for_device_errors}
                                </Popover.Body>
                              </Popover>
                            }
                          >
                            <p style={{ cursor: 'pointer' }}>
                              <FontAwesomeIcon
                                icon={faInfoCircle}
                                className="text-primary fs-1"
                              />
                            </p>
                          </OverlayTrigger>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex-shrink-1 flex-grow-1 w-100">
                <div>
                  <div className="container p-0">
                    <div className="row flex-grow-0 flex-nowrap panel-item-heading-with-link">
                      <div className="col">
                        <div className="form-check d-flex align-items-start form-switch mb-0">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="errorCheck"
                            onChange={handleChange}
                            checked={isErrorEmailEnabled}
                          />
                          <label
                            className="form-check-label mt-1"
                            htmlFor="errorLabelCheck"
                          >
                            {translation.email}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="bg-400 mb-0" />
              </div>
            </div>
            <div className="flex-column d-flex mb-1 align-items-center w-100">
              <div className="d-flex align-items-center w-100">
                <div className="flex-shrink-1 flex-grow-1">
                  <div>
                    <div className="container p-0">
                      <div className="row flex-grow-0 flex-nowrap panel-item-heading-with-link">
                        <div className="col d-flex flex-grow-1">
                          <h4>
                            {translation.remote_alarm_for_wifi_and_offline}
                          </h4>
                        </div>
                        <div className="col-auto text-end flex-grow-0 flex-shrink-1 justify-content-end flex-wrap">
                          <OverlayTrigger
                            trigger="click"
                            rootClose
                            placement="left"
                            overlay={
                              <Popover
                                id="popover-status"
                                style={{ margin: 0 }}
                              >
                                <Popover.Header as="h3">
                                  {translation.more_information}
                                </Popover.Header>
                                <Popover.Body>
                                  <p>{translation.i003_wifi_disconnected_p1}</p>
                                  <p>{translation.i003_wifi_disconnected_p2}</p>
                                  <p>
                                    {
                                      translation.m018_replace_batteries_1_bullets_1
                                    }
                                  </p>
                                  <p>
                                    {
                                      translation.m018_replace_batteries_1_bullets_2
                                    }
                                  </p>
                                  <p>
                                    {
                                      translation.m018_replace_batteries_1_bullets_3
                                    }
                                  </p>
                                </Popover.Body>
                              </Popover>
                            }
                          >
                            <p style={{ cursor: 'pointer' }}>
                              <FontAwesomeIcon
                                icon={faInfoCircle}
                                className="text-primary fs-1"
                              />
                            </p>
                          </OverlayTrigger>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex-shrink-1 flex-grow-1 w-100">
                <div>
                  <div className="container p-0">
                    <div className="row flex-grow-0 flex-nowrap panel-item-heading-with-link">
                      <div className="col">
                        <div className="form-check d-flex align-items-start form-switch mb-0">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="offlineCheck"
                            onChange={handleChange}
                            checked={isOfflineEmailEnabled}
                          />
                          <label
                            className="form-check-label mt-1"
                            htmlFor="formCheck-7"
                          >
                            {translation.email}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container p-0 pb-3">
        <h6 className="mt-4">{translation.add_ons}</h6>
        <div className="card shadow">
          <div className="card-body px-3 py-3 pb-3">
            <div className="flex-column d-flex align-items-center w-100">
              <div className="d-flex align-items-center w-100">
                <div className="flex-shrink-1 flex-grow-1">
                  <div>
                    <div className="container p-0">
                      <div className="row flex-grow-0 flex-nowrap panel-item-heading-with-link">
                        <div className="col d-flex flex-grow-1">
                          <h4>{translation.alarm_air_quality_exceeds}</h4>
                        </div>
                        <div className="col-auto text-end flex-grow-0 flex-shrink-1 justify-content-end flex-wrap">
                          <OverlayTrigger
                            trigger="click"
                            rootClose
                            placement="left"
                            overlay={
                              <Popover
                                id="popover-status"
                                style={{ margin: 0 }}
                              >
                                <Popover.Header as="h3">
                                  {translation.more_information}
                                </Popover.Header>
                                <Popover.Body>
                                  <p>
                                    {translation.alarm_air_quality_info_1}{' '}
                                    {translation.in_app}{' '}
                                    {translation.alarm_air_quality_info_2}
                                  </p>
                                </Popover.Body>
                              </Popover>
                            }
                          >
                            <p style={{ cursor: 'pointer' }}>
                              <FontAwesomeIcon
                                icon={faInfoCircle}
                                className="text-primary fs-1"
                              />
                            </p>
                          </OverlayTrigger>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex-shrink-1 flex-grow-1 w-100">
                <div>
                  <div className="container p-0">
                    <div className="row flex-grow-0 flex-nowrap panel-item-heading-with-link">
                      <div className="col">
                        <div className="form-check d-flex align-items-start form-switch mb-0">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="airCheck"
                            onChange={handleChange}
                            checked={isAirQualityEmailEnabled}
                          />
                          <label
                            className="form-check-label mt-1"
                            htmlFor="airCheck"
                          >
                            {translation.email}
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        role="dialog"
        tabIndex="-1"
        id="modal-email-verification"
        show={showEmailVerification}
        onHide={handleEmailVerificationClose}
      >
        <Modal.Header className="pe-4 border-0">
          <h4 className="modal-title pe-5">{user.email}</h4>
          <button
            type="button"
            className="btn-close"
            onClick={handleEmailVerificationClose}
            aria-label="Close"
          ></button>
        </Modal.Header>
        <Modal.Body>{modalBody}</Modal.Body>
        <Modal.Footer className="border-0 pt-0 pb-4">
          <div className="col">
            <button
              className="btn btn-primary w-100 shadow"
              type="button"
              data-bs-dismiss="modal"
              onClick={handleEmailVerificationClose}
            >
              {translation.cancel}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

NotificationAdditionalService.propTypes = {
  user: PropTypes.object,
  device: PropTypes.object,
  auth_user: PropTypes.object
};
