import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5locales_fi_FI from '@amcharts/amcharts5/locales/fi_FI';
import am5locales_en from '@amcharts/amcharts5/locales/en';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

class PowerChartComponent extends Component {
  // constructor(props) {
  //   super(props);
  //   //console.log(props);
  // }
  componentDidMount() {
    this.root = am5.Root.new(this.props.id);
    this.initChart();
  }

  getLanguage(language) {
    var am5Locale = am5locales_en;
    switch (language) {
      case 'fi':
        am5Locale = am5locales_fi_FI;
        break;

      default:
        am5Locale = am5locales_en;
        break;
    }
    return am5Locale;
  }

  initChart() {
    if (this.root.isDisposed()) {
      this.root = am5.Root.new(this.props.id);
    }
    this.root.setThemes([am5themes_Animated.new(this.root)]);
    this.root.locale = this.getLanguage(this.props.language);
    this.chart = this.root.container.children.push(
      am5xy.XYChart.new(this.root, {
        panY: false,
        wheelX: 'panX',
        wheelY: 'zoomX',
        layout: this.root.verticalLayout
      })
    );
    //this.chart.language.locale = am5locales_en_US;
    var cursor = this.chart.set(
      'cursor',
      am5xy.XYCursor.new(this.root, {
        behavior: 'zoomX'
      })
    );
    cursor.lineY.set('visible', false);

    // Define data
    let data = this.props.data;

    // Create Y-axis
    let yAxis = this.chart.yAxes.push(
      am5xy.ValueAxis.new(this.root, {
        renderer: am5xy.AxisRendererY.new(this.root, {})
      })
    );

    // Create Y-axis
    let yColumnAxis = this.chart.yAxes.push(
      am5xy.ValueAxis.new(this.root, {
        renderer: am5xy.AxisRendererY.new(this.root, {
          opposite: true
        }),
        visible: false
      })
    );

    // Create X-Axis
    let xAxis = this.chart.xAxes.push(
      am5xy.DateAxis.new(this.root, {
        baseInterval: { timeUnit: 'day', count: 1 },
        gridIntervals: [
          { timeUnit: 'day', count: 1 },
          { timeUnit: 'week', count: 1.1 },
          { timeUnit: 'month', count: 1 }
        ],
        renderer: am5xy.AxisRendererX.new(this.root, {}),
        tooltip: am5.Tooltip.new(this.root, {})
      })
    );

    xAxis.get('dateFormats')['day'] = 'MM/dd';
    xAxis.get('periodChangeDateFormats')['day'] = 'MMMM';

    xAxis.data.setAll(data);
    if (this.props.serieValues.power !== undefined) {
      let power = this.chart.series.push(
        am5xy.ColumnSeries.new(this.root, {
          name: this.props.translation.power_battery_chart_title_b,
          xAxis: xAxis,
          yAxis: yColumnAxis,
          valueYField: this.props.serieValues.power,
          valueXField: 'timestamp',
          stroke: am5.color('rgba(66,135,245,0.5)'),
          fill: am5.color('rgba(66,135,245,0.5)'),
          stacked: false,
          clustered: false
        })
      );

      power.columns.template.setAll({
        width: am5.percent(100)
      });
      var powerTooltip = power.set('tooltip', am5.Tooltip.new(this.root, {}));
      powerTooltip.label.set('text', '{valueY} ' + this.props.serieUnit.power);

      power.data.setAll(data);
    }
    // Create series
    if (this.props.serieValues.battery !== undefined) {
      let series1 = this.chart.series.push(
        am5xy.SmoothedXLineSeries.new(this.root, {
          name: this.props.translation.power_battery_chart_title_c,
          xAxis: xAxis,
          yAxis: yAxis,
          stroke: am5.color('#495947'),
          valueYField: this.props.serieValues.battery,
          valueXField: 'timestamp'
        })
      );

      var tooltip = series1.set('tooltip', am5.Tooltip.new(this.root, {}));
      tooltip.label.set('text', '{valueY} ' + this.props.serieUnit.serie1);

      series1.data.setAll(data);
    }
    // Add legend
    let legend = this.chart.children.push(am5.Legend.new(this.root, {}));
    legend.data.setAll(this.chart.series.values);

    // Add cursor
    //this.chart.set('cursor', am5xy.XYCursor.new(this.root, {}));

    this.chart.set(
      'scrollbarX',
      am5.Scrollbar.new(this.root, {
        orientation: 'horizontal'
      })
    );

    // xAxis.zoomToDates(new Date(2021, 0, 1), new Date(2022, 0, 1));
    // xAxis.onPrivate('selectionMin', function (value, target) {
    //   var start = new Date(value);
    //   console.log('Start date changed:', start);
    // });

    // xAxis.onPrivate('selectionMax', function (value, target) {
    //   var end = new Date(value);
    //   console.log(target);
    //   console.log('End date changed:', end);
    // });
  }

  componentWillUnmount() {
    if (this.root) {
      this.root.dispose();
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      if (this.chart._super) {
        this.chart.dispose();
      }
      this.root.dispose();
      this.initChart();
    }
  }

  render() {
    return (
      <div
        id={this.props.id}
        style={{ width: '100%', height: `${this.props.chartHeight}` }}
      ></div>
    );
  }
}

export default PowerChartComponent;

PowerChartComponent.propTypes = {
  data: PropTypes.array,
  serieNames: PropTypes.object,
  chartHeight: PropTypes.string,
  id: PropTypes.string,
  serieValues: PropTypes.object,
  serieUnit: PropTypes.object,
  language: PropTypes.string,
  translation: PropTypes.object
};
