/**
 * Wizard device name
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/

import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import TextSanitizing from 'components/TextSanitizing';
import useTranslation from 'hooks/translations';
import * as utils from 'utils';
import ProductCatalog from 'components/Product/ProductCatalog';

export default function WizardDeviceName({ props }) {
  //console.log(props);

  const translation = useTranslation();
  const deviceNameRef = useRef();
  //var disableButton = 'disabled';
  const [disable, setDisable] = useState('disabled');
  const [deviceName, setDeviceName] = useState('');
  const updateRef = useRef(props.update);
  useEffect(() => {
    const urlResult = new URLSearchParams(window.location.search);
    if (urlResult.has('name')) {
      setDeviceName(urlResult.get('name'));
      setDisable('');
      updateRef.current(
        'deviceName',
        TextSanitizing(urlResult.get('name'), false)
      );
    }
  }, []);

  var imageLink = 'img/Safera-Sense-Wi-Fi-front-transparent-1000x371.png';
  if (props.config !== undefined) {
    //console.log(props.config);
    if (props.config.hw_major !== undefined) {
      const product = ProductCatalog({
        hw_version: parseInt(props?.config?.hw_major),
        mechanic_variant: parseInt(props?.config?.mech_variant),
        variant: 0,
        hw_id: 0
      });
      // console.log(product);
      switch (product.id) {
        case utils.SAFERA_PRODUCTS['WIFI']:
        case utils.SAFERA_PRODUCTS['PRO']:
          if (
            product.mechanicVariant ===
            utils.SENSE_MECHANICAL_VARIANT['BLACK_BLACK']
          ) {
            imageLink = 'img/Safera-Sense-Pro-front-transparent-1000x371.png';
          } else {
            imageLink = 'img/Safera-Sense-Wi-Fi-front-transparent-1000x371.png';
          }
          break;
        case utils.SAFERA_PRODUCTS['CR']:
        case utils.SAFERA_PRODUCTS['FLOWHERO']:
          imageLink =
            'img/Safera-Sense-Integral-front-transparent-1000x371.png';
          break;
        default:
          imageLink = 'img/Safera-Sense-Wi-Fi-front-transparent-1000x371.png';
          break;
      }
    }
  }
  console.log(imageLink, props.config.hw_major);

  const deviceNameChanged = e => {
    if (e.target.value === '') {
      setDisable('disabled');
    } else {
      setDisable('');
    }
    props.update(e.target.name, TextSanitizing(e.target.value, false));
  };
  return (
    <div className="wizard-div-above-container p-0">
      <div className="container wizard-container p-0 shadow-lg">
        <div className="d-flex flex-column wizard-content pb-4">
          <div className="d-flex flex-column wizard-upper-content">
            <div className="container h-100 py-4">
              <div className="row d-flex flex-column justify-content-start align-items-center h-100">
                <div className="col d-flex justify-content-center align-items-center mb-2">
                  <i className="fas fa-link wizard-icon fs--2 me-2 text-light bg-dark p-1 rounded-pill"></i>
                  <h6 className="text-start d-inline-flex mb-0">
                    {translation.wizard_cloud_connect_device_step}
                  </h6>
                </div>
                <div className="col d-flex flex-grow-0 justify-content-center">
                  <h3 className="text-center">
                    {translation.wizard_cloud_connect_device_heading}
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-grow-1 align-items-start wizard-lower-content">
            <div className="row d-flex flex-column align-items-start w-100 m-0">
              <div className="col d-inline-flex flex-column justify-content-start">
                <div className="row">
                  <div className="col d-flex flex-column justify-content-center align-items-center">
                    <p className="text-center">
                      {translation.wizard_cloud_connect_device_p1}
                    </p>
                    <div className="px-5">
                      <img className="img-fluid" alt="" src={imageLink} />
                    </div>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col">
                    <div>
                      <label className="form-label">
                        {translation.wizard_cloud_connect_device_input_label}
                      </label>
                      <input
                        type="text"
                        name="deviceName"
                        className="form-control bg-white"
                        placeholder="Esim. Etunimi Sukunimi"
                        ref={deviceNameRef}
                        onChange={deviceNameChanged}
                        defaultValue={deviceName}
                      />
                    </div>
                  </div>
                </div>
                <div className="row mt-1">
                  <div className="col">
                    <button
                      className={`btn btn-primary text-center mb-3 btn-falcon-default bg-primary text-white border-primary w-100  ${disable}`}
                      type="button"
                      onClick={props.nextStep}
                    >
                      {translation.wizard_cloud_next_button}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

WizardDeviceName.propTypes = {
  props: PropTypes.object,
  nextStep: PropTypes.func,
  update: PropTypes.func,
  form: PropTypes.object,
  config: PropTypes.object
};
