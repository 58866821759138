import React, { Component } from 'react';
import PropTypes from 'prop-types';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5locales_fi_FI from '@amcharts/amcharts5/locales/fi_FI';
import am5locales_en from '@amcharts/amcharts5/locales/en';
import am5locales_fr from '@amcharts/amcharts5/locales/fr_FR';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';

class ChartComponent extends Component {
  constructor(props) {
    super(props);
    this.cookingTxt = 'Cooking';
    this.alarmTxt = 'Alarm';
  }
  componentDidMount() {
    this.root = am5.Root.new(this.props.id);
    this.initChart();
  }

  getLanguage(language) {
    var am5Locale = am5locales_en;
    switch (language) {
      case 'fi':
        am5Locale = am5locales_fi_FI;
        this.cookingTxt = 'Kokkaus';
        this.alarmTxt = 'Hälytys';
        break;
      case 'fr':
        am5Locale = am5locales_fr;
        this.cookingTxt = 'Cuisine';
        this.alarmTxt = 'Alarme';
        break;
      default:
        am5Locale = am5locales_en;
        break;
    }
    return am5Locale;
  }

  initChart() {
    if (this.root.isDisposed()) {
      this.root = am5.Root.new(this.props.id);
    }
    this.root.setThemes([am5themes_Animated.new(this.root)]);
    this.root.locale = this.getLanguage(this.props.language);
    this.chart = this.root.container.children.push(
      am5xy.XYChart.new(this.root, {
        panY: false,
        wheelX: 'panX',
        wheelY: 'zoomX',
        layout: this.root.verticalLayout
      })
    );
    //this.chart.language.locale = am5locales_en_US;
    var cursor = this.chart.set(
      'cursor',
      am5xy.XYCursor.new(this.root, {
        behavior: 'zoomX'
      })
    );
    cursor.lineY.set('visible', false);

    // Define data
    let data = this.props.data;

    // Create Y-axis
    let yAxis = this.chart.yAxes.push(
      am5xy.ValueAxis.new(this.root, {
        renderer: am5xy.AxisRendererY.new(this.root, {})
      })
    );

    // Create Y-axis
    let yColumnAxis = this.chart.yAxes.push(
      am5xy.ValueAxis.new(this.root, {
        renderer: am5xy.AxisRendererY.new(this.root, {
          opposite: true
        }),
        visible: false
      })
    );

    // Create X-Axis
    let xAxis = this.chart.xAxes.push(
      am5xy.DateAxis.new(this.root, {
        baseInterval: { timeUnit: 'minute', count: 30 },
        gridIntervals: [
          { timeUnit: 'day', count: 1 },
          { timeUnit: 'week', count: 1.1 },
          { timeUnit: 'month', count: 1 }
        ],
        renderer: am5xy.AxisRendererX.new(this.root, {}),
        tooltip: am5.Tooltip.new(this.root, {})
      })
    );

    xAxis.get('dateFormats')['day'] = 'MM/dd';
    xAxis.get('periodChangeDateFormats')['day'] = 'MMMM';

    xAxis.data.setAll(data);

    let cooking = this.chart.series.push(
      am5xy.ColumnSeries.new(this.root, {
        name: this.cookingTxt,
        xAxis: xAxis,
        yAxis: yColumnAxis,
        valueYField: this.props.serieValues.cooking,
        valueXField: 'sensor_timestamp',
        stroke: am5.color('rgba(255,221,189,0.5)'),
        fill: am5.color('rgba(255,221,189,0.5)'),
        stacked: false,
        clustered: false
      })
    );

    cooking.columns.template.setAll({
      width: am5.percent(100)
    });

    cooking.data.setAll(data);

    let alarm = this.chart.series.push(
      am5xy.ColumnSeries.new(this.root, {
        name: this.alarmTxt,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: this.props.serieValues.alarm,
        valueXField: 'sensor_timestamp',
        stroke: am5.color('rgba(220,20,20,0.9)'),
        fill: am5.color('rgba(220,20,20,0.8)'),
        stacked: false,
        clustered: false
      })
    );

    alarm.data.setAll(data);

    // Create series
    let series1 = this.chart.series.push(
      am5xy.SmoothedXLineSeries.new(this.root, {
        name: this.props.serieNames.serie1,
        xAxis: xAxis,
        yAxis: yAxis,
        stroke: am5.color('#118704'),
        valueYField: this.props.serieValues.serie1,
        valueXField: 'sensor_timestamp'
      })
    );

    var tooltip = series1.set('tooltip', am5.Tooltip.new(this.root, {}));
    tooltip.label.set('text', '{valueY} ' + this.props.serieUnit.serie1);

    series1.data.setAll(data);

    let series2 = this.chart.series.push(
      am5xy.SmoothedXLineSeries.new(this.root, {
        name: this.props.serieNames.serie2,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: this.props.serieValues.serie2,
        valueXField: 'sensor_timestamp',
        tooltip: am5.Tooltip.new(this.root, {})
      })
    );

    series2.data.setAll(data);

    series2
      .get('tooltip')
      .label.set('text', '{valueY} ' + this.props.serieUnit.serie2);

    if (this.props.serieValues.serie3 !== undefined) {
      let series3 = this.chart.series.push(
        am5xy.SmoothedXLineSeries.new(this.root, {
          name: this.props.serieNames.serie3,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: this.props.serieValues.serie3,
          valueXField: 'sensor_timestamp',
          tooltip: am5.Tooltip.new(this.root, {})
        })
      );

      series3.data.setAll(data);

      series3
        .get('tooltip')
        .label.set('text', '{valueY} ' + this.props.serieUnit.serie3);
    }

    // Add legend
    let legend = this.chart.children.push(am5.Legend.new(this.root, {}));
    legend.data.setAll(this.chart.series.values);

    // Add cursor
    //this.chart.set('cursor', am5xy.XYCursor.new(this.root, {}));

    this.chart.set(
      'scrollbarX',
      am5.Scrollbar.new(this.root, {
        orientation: 'horizontal'
      })
    );

    // xAxis.zoomToDates(new Date(2021, 0, 1), new Date(2022, 0, 1));
    // xAxis.onPrivate('selectionMin', function (value, target) {
    //   var start = new Date(value);
    //   console.log('Start date changed:', start);
    // });

    // xAxis.onPrivate('selectionMax', function (value, target) {
    //   var end = new Date(value);
    //   console.log(target);
    //   console.log('End date changed:', end);
    // });
  }

  componentWillUnmount() {
    if (this.root) {
      this.root.dispose();
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      if (this.chart._super) {
        this.chart.dispose();
      }
      this.root.dispose();
      this.initChart();
    }
  }

  render() {
    return (
      <div
        id={this.props.id}
        style={{ width: '100%', height: `${this.props.chartHeight}` }}
      ></div>
    );
  }
}

export default ChartComponent;

ChartComponent.propTypes = {
  data: PropTypes.array,
  serieNames: PropTypes.object,
  chartHeight: PropTypes.string,
  id: PropTypes.string,
  serieValues: PropTypes.object,
  serieUnit: PropTypes.object,
  language: PropTypes.string
};
