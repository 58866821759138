/**
 * PayPal subscription button.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import * as utils from '../../../utils';
import moment from 'moment';
import PaymentModal from './PaymentModal';
import useTranslation from 'hooks/translations';
import { doc, onSnapshot } from 'firebase/firestore';
import { useStoreActions, useStoreState } from 'easy-peasy';
import PhonenumberValidator from 'components/PhonenumberValidator';

export default function PayPalSubscriptionButton({
  paypalSubs,
  user,
  setNewState
}) {
  var activeSubscription;
  const translation = useTranslation();
  const [trialModal, setTrialModal] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const db = useStoreState(state => state.db);
  const updatePayPalSubs = useStoreActions(actions => actions.updatePayPalSubs);
  var button = (
    <button
      className="btn btn-success btn-sm rounded-pill"
      type="button"
      onClick={showTrialModal}
    >
      {translation.start_30_day_free_trial}
    </button>
  );
  const [activeSub, setActiveSub] = useState(activeSubscription);
  // const [orderButton, setOrderButton] = useState(button);

  const isSubscriptionActiveRef = useRef(false);
  const initRef = useRef(init);
  const buttonRef = useRef(button);
  useEffect(() => {
    initRef.current();
  }, []);

  // useEffect(() => {
  //   console.log(buttonRef.current);
  //   setOrderButton(buttonRef.current);
  // }, [paypalSubs]);

  function init() {
    try {
      var isApprovalPending = false;
      var approvalPendingSub;
      if (activeSub !== undefined && activeSub.order_id !== undefined) {
        PayPalStatusHandler(activeSub.order_id);
      }
      for (const sub of paypalSubs) {
        console.log(sub.status);
        if (sub.status === undefined) {
          if (!isSubscriptionActiveRef.current) {
            isApprovalPending = true;
          }
        } else if (sub.status === utils.PAYPAL_SUBSCRIPTION_ACTIVE) {
          console.log('ACTIVE!');
          isSubscriptionActiveRef.current = true;
          setActiveSub(sub);
          isApprovalPending =
            sub.in_progress !== undefined ? sub.in_progress : false;
          const now = new Date().getTime();
          const subscriptionCreated = new Date(sub.create_time).getTime();
          var start = moment(now);
          var end = moment(subscriptionCreated);
          const diffDays = start.diff(end, 'days');
          if (diffDays > 30) {
            buttonRef.current = getCancelOrderButton();
          } else {
            buttonRef.current = (
              <button
                className="btn btn-outline-primary btn-sm rounded-pill shadow"
                type="button"
                onClick={showCancelModal}
              >
                {translation.cancel_free_trial}
              </button>
            );
          }
        } else if (sub.status === utils.PAYPAL_SUBSCRIPTION_SUSPENDED) {
          if (!isSubscriptionActiveRef.current) {
            isApprovalPending =
              sub.in_progress !== undefined ? sub.in_progress : false;
            setActiveOrderButton();
            setActiveSub(sub);
          }
        } else if (sub.status === utils.PAYPAL_SUBSCRIPTION_APPROVAL_PENDING) {
          console.log('APPROVAL PENDING');

          if (!isSubscriptionActiveRef.current) {
            isApprovalPending = true;
            setActiveSub(sub);
          }
          approvalPendingSub = sub;
          getInProgressButton(sub);
        } else {
          if (!isSubscriptionActiveRef.current) {
            setActiveOrderButton();
          }
        }
      }
      if (!isSubscriptionActiveRef.current) {
        if (user.isSmsTrialUsed) {
          setActiveOrderButton();
        }
      }
      if (isApprovalPending) {
        getInProgressButton(approvalPendingSub);
      }
    } catch (error) {
      console.error(error);
    }
  }
  function modalClosed() {
    setTrialModal(false);
    setCancelModal(false);
  }

  function showTrialModal() {
    setTrialModal(true);
  }

  function showCancelModal() {
    setCancelModal(true);
  }

  function setInProgress() {
    getInProgressButton();
  }

  function PayPalStatusHandler(order_id) {
    try {
      onSnapshot(
        doc(
          db,
          utils.USERS_KEY + '/' + user.uid + '/' + utils.SMS_SUBSCRIPTION_KEY,
          order_id
        ),
        doc => {
          console.log('Current data: ', doc.data());
          if (doc.data() === undefined || doc.data().status === undefined) {
            return;
          }
          var oldStatus = '';
          var newSub = doc.data();
          newSub['order_id'] = doc.id;
          if (activeSubscription !== undefined) {
            oldStatus = activeSubscription.status;
          }
          if (doc.data().status === utils.PAYPAL_SUBSCRIPTION_ACTIVE) {
            console.log('Active BUTTON');
            buttonRef.current = getCancelOrderButton();
          } else if (
            doc.data().status === utils.PAYPAL_SUBSCRIPTION_SUSPENDED ||
            doc.data().status === utils.PAYPAL_SUBSCRIPTION_CANCELLED
          ) {
            buttonRef.current = getActiveOrderButton();
          } else {
            console.log('In Progress BUTTON');
            setInProgress();
          }
          if (doc.data().status !== oldStatus) {
            console.log('PayPal status changed:', doc.data().status);
            setNewState(newSub);
            updatePayPalSubs(newSub);
            setActiveSub(newSub);
          }
        },
        error => {
          console.error(error);
        }
      );
    } catch (err) {
      console.error(err);

      return;
    }
  }

  function getInProgressButton(sub) {
    buttonRef.current = (
      <>
        <button
          className="btn btn-primary btn-sm rounded-pill"
          type="button"
          onClick={showTrialModal}
          disabled
        >
          {translation.in_progress}
        </button>{' '}
        {getTryAgainButton(sub)}
      </>
    );
  }

  function getTryAgainButton(sub) {
    if (sub?.links === undefined) {
      console.log('No links');
      return '';
    }
    for (const link of sub.links) {
      if (link.rel === 'approve') {
        return (
          <a
            className="text-break"
            href={link.href}
            target="_blank"
            rel="noopener noreferrer"
          >
            {translation.try_again}
          </a>
        );
      }
    }
    return '';
  }

  function setActiveOrderButton() {
    buttonRef.current = getActiveOrderButton();
  }

  function getActiveOrderButton() {
    return (
      <button
        className="btn btn-primary btn-sm rounded-pill"
        type="button"
        onClick={showTrialModal}
      >
        {translation.subscribe_to_sms_alarm_service}
      </button>
    );
  }

  function getCancelOrderButton() {
    return (
      <button
        className="btn btn-outline-primary btn-sm rounded-pill shadow"
        type="button"
        onClick={showCancelModal}
      >
        {translation.cancel_the_subscription}
      </button>
    );
  }

  if (!isSubscriptionActiveRef.current) {
    if (!PhonenumberValidator(user.phoneNumber) || user.phoneNumber === '') {
      button = getPhoneNumberMissingButton();
    }
  }

  function getPhoneNumberMissingButton() {
    return (
      <button
        className="btn btn-warning btn-sm rounded-pill"
        type="button"
        onClick={modalClosed}
        disabled
      >
        {translation.phone_number_missing}
      </button>
    );
  }

  return (
    <>
      {buttonRef.current}
      <PaymentModal
        user={user}
        activeSub={activeSub}
        trialModal={trialModal}
        cancelModal={cancelModal}
        setInProgress={setInProgress}
        modalClosed={modalClosed}
        PayPalStatusHandler={PayPalStatusHandler}
      />
    </>
  );
}

PayPalSubscriptionButton.propTypes = {
  user: PropTypes.object,
  paypalSubs: PropTypes.array,
  setNewState: PropTypes.func,
  db: PropTypes.object
};
