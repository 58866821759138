/**
 * Device Info.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/
import React from 'react';
import PropTypes from 'prop-types';
import DeviceType from 'components/Product/DeviceType';
import useTranslation from 'hooks/translations';

export default function DeviceInfo({ device }) {
  const translation = useTranslation();
  if (device === undefined) {
    device = { data: {} };
  }
  const deviceData = device.data !== undefined ? device.data : {};

  var hex = deviceData.hw_id !== undefined ? deviceData.hw_id.toString(16) : 0;
  hex = '0'.repeat(8 - hex.length) + hex;
  var hwIdBytes = [];

  for (var c = 0; c < hex.length; c += 2) {
    hwIdBytes.push(parseInt(hex.substring(c, c + 2), 16));
  }

  const swVerMajor = hwIdBytes[3] !== undefined ? hwIdBytes[3] : 0;
  const swVerMinor = hwIdBytes[2] !== undefined ? hwIdBytes[2] : 0;
  const hwVer = hwIdBytes[1] !== undefined ? hwIdBytes[1] : 0;
  const MecVar = hwIdBytes[0] !== undefined ? hwIdBytes[0] : 0;

  const deviceId = device.device_id !== undefined ? device.device_id : '';
  const FWVersion = swVerMajor + '.' + swVerMinor;

  return (
    <>
      <h6 className="mt-4">{translation.information}</h6>
      <div className="container p-0 pb-3">
        <div className="card shadow">
          <div className="card-body px-3 py-3 pb-2">
            <div className="flex-column d-flex align-items-center">
              <div className="row flex-grow-1 justify-content-between panel-item-heading-with-link w-100">
                <div className="col-4 p-0">
                  <h4 className="text-break">{translation.device}</h4>
                </div>
                <div className="col-8 d-md-flex">
                  <p>
                    <DeviceType deviceData={deviceData} />
                  </p>
                </div>
              </div>
              <div className="row w-100">
                <div className="col p-0">
                  <hr className="mt-0" />
                </div>
              </div>
            </div>
            <div className="flex-column d-flex align-items-center">
              <div className="row flex-grow-1 justify-content-between panel-item-heading-with-link w-100">
                <div className="col-4 p-0">
                  <h4 className="text-break">{translation.device_id}</h4>
                </div>
                <div className="col-8 d-md-flex">
                  <p>{deviceId}</p>
                </div>
              </div>
              <div className="row w-100">
                <div className="col p-0">
                  <hr className="mt-0" />
                </div>
              </div>
            </div>
            <div className="flex-column d-flex align-items-center">
              <div className="row flex-grow-1 justify-content-between panel-item-heading-with-link w-100">
                <div className="col-4 p-0">
                  <h4 className="text-break">{translation.fw_version}</h4>
                </div>
                <div className="col-8 d-md-flex">
                  <p>{FWVersion}</p>
                </div>
              </div>
              <div className="row w-100">
                <div className="col p-0">
                  <hr className="mt-0" />
                </div>
              </div>
            </div>
            <div className="flex-column d-flex align-items-center">
              <div className="row flex-grow-1 justify-content-between panel-item-heading-with-link w-100">
                <div className="col-4 p-0">
                  <h4 className="text-break">{translation.hw_version}</h4>
                </div>
                <div className="col-8 d-md-flex">
                  <p>{hwVer}</p>
                </div>
              </div>
              <div className="row w-100">
                <div className="col p-0">
                  <hr className="mt-0" />
                </div>
              </div>
            </div>
            <div className="flex-column d-flex align-items-center">
              <div className="row flex-grow-1 justify-content-between panel-item-heading-with-link w-100">
                <div className="col-4 p-0">
                  <h4 className="text-break">
                    {translation.mechanical_variant}
                  </h4>
                </div>
                <div className="col-8 d-md-flex">
                  <p>{MecVar}</p>
                </div>
              </div>
              <div className="row w-100">
                <div className="col p-0">
                  <hr className="mt-0" />
                </div>
              </div>
            </div>
            <div className="flex-column d-flex align-items-center">
              <div className="row flex-grow-1 justify-content-between panel-item-heading-with-link w-100">
                <div className="col-4 p-0">
                  <h4 className="text-break">
                    {translation.hw_id_minor}&nbsp;
                  </h4>
                </div>
                <div className="col-8 d-md-flex">
                  <p>{deviceData.hw_minor_id}</p>
                </div>
              </div>
              <div className="row w-100">
                <div className="col p-0">
                  <hr className="mt-0" />
                </div>
              </div>
            </div>
            <div className="flex-column d-flex align-items-center mb-0">
              <div className="row flex-grow-1 justify-content-between panel-item-heading-with-link w-100">
                <div className="col-4 p-0">
                  <h4 className="text-break">
                    {translation.mechanics_version}&nbsp;&nbsp;
                  </h4>
                </div>
                <div className="col-8 d-md-flex">
                  <p>{deviceData.hw_mechanics_id}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

DeviceInfo.propTypes = { device: PropTypes.object };
