/**
 * Body.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/

import PropTypes from 'prop-types';
import * as utils from '../utils';
import AdvancedHomePage from './AdvancedHomePage';
import React, { useState, useEffect, useRef } from 'react';
//import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import AccountSettings from 'Personal/components/AccountSettings/AccountSettings';
import MessagePage from './MessagePage';
import { AdvancedContext } from 'context/Context';

export default function Advanced({
  db,
  setUser,
  user,
  updateUser,
  logout,
  changeLanguage,
  pageSettings
}) {
  if (db === undefined) {
    console.error('Data base error!');
  }
  const analyticIdRef = useRef(
    user.analytics_id !== undefined ? user.analytics_id : user.uid
  );
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        userId: analyticIdRef.current,
        userProject: 'Advanced',
        page: 'Device card'
      },
      dataLayerName: 'SaferaDataLayer'
    });
  }, []);

  var homePage = (
    <AdvancedHomePage
      user={user}
      setUser={setUser}
      logout={logout}
      pageSettings={undefined}
      setPage={setPage}
      db={db}
    />
  );

  var defaultPageSettings;
  if (pageSettings) {
    defaultPageSettings = pageSettings;
    console.log(pageSettings);
  }
  const [defaultPaheSettings, setDefaultPaheSettings] =
    useState(defaultPageSettings);

  if (defaultPaheSettings) {
    console.log('defaultPageSettings');
    homePage = getPageContent(pageSettings);
  }
  const [state, setState] = useState({
    content: homePage
  });

  function gaEventTracker(message) {
    console.log(message);
    const analyticId =
      user.analytics_id !== undefined ? user.analytics_id : user.uid;
    TagManager.dataLayer({
      dataLayer: {
        userId: analyticId,
        userProject: 'Advanced',
        page: message
      },
      dataLayerName: 'SaferaDataLayer'
    });
    // ReactGA.send({ hitType: 'pageview', page: message });
  }
  function setPage(pageSettings) {
    console.log(pageSettings);
    setDefaultPaheSettings();
    setState(() => ({
      content: getPageContent(pageSettings)
    }));
  }

  function getPageContent(pageSettings) {
    var pageContent;
    switch (pageSettings.page) {
      case utils.ADVANCED_PAGES['DEVICE_LIST']:
        pageContent = (
          <AdvancedHomePage
            user={user}
            setUser={setUser}
            logout={logout}
            setPage={setPage}
            pageSettings={pageSettings}
            db={db}
          />
        );
        gaEventTracker('Device card');

        break;
      case utils.ADVANCED_PAGES['MESSAGE']:
        pageContent = (
          <MessagePage
            user={user}
            setUser={setUser}
            logout={logout}
            setPage={setPage}
            pageSettings={pageSettings}
            db={db}
          />
        );
        break;
      case utils.PERSONAL_PAGES['ACCOUNT_SETTINGS']:
        pageContent = (
          <AccountSettings
            updateUser={updateUser}
            user={user}
            setPage={setPage}
            pageSettings={pageSettings}
            changeLanguage={changeLanguage}
            setUser={setUser}
            logout={logout}
          />
        );
        gaEventTracker('Account settings');

        break;
      default:
        pageContent = <></>;
        gaEventTracker('Error: Unknow Page');

        break;
    }
    return pageContent;
  }

  return (
    <AdvancedContext.Provider
      value={{
        db,
        setUser,
        user,
        updateUser,
        logout,
        changeLanguage,
        pageSettings
      }}
    >
      {state.content}
    </AdvancedContext.Provider>
  );
}

Advanced.propTypes = {
  userDeviceSettings: PropTypes.array,
  user: PropTypes.object,
  devices: PropTypes.array,
  setUser: PropTypes.func,
  updateUser: PropTypes.func,
  translation: PropTypes.object,
  logout: PropTypes.func,
  changeLanguage: PropTypes.func,
  db: PropTypes.object,
  pageSettings: PropTypes.func
};
