/**
 * Notifications settings.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { useStoreState, useStoreActions } from 'easy-peasy';
import PayPalSubscriptionButton from '../../AccountSettings/PayPalSubscriptionButton';
import PayPalSubscriptionNote from '../../AccountSettings/PayPalSubscriptionNote';
import SmsCheckBox from './SmsCheckBox';
import * as utils from '../../../../utils';
import moment from 'moment';
import writeModel from '../../../../components/DataBase/DataBaseWriteModel';
import useTranslation from 'hooks/translations';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import { ReadPayPalSubscriptions } from 'components/DataBase/ReadPayPalSubscriptions';

export default function NotificationSettings({ db, user, device }) {
  const translation = useTranslation();
  const [paypalSubs, setPaypalSubs] = useState(
    useStoreState(state => state.paypalSubscriptions)
  );
  const setDevice = useStoreActions(actions => actions.setDevice);
  const dbUpdate = useStoreActions(actions => actions.dbUpdate);

  if (paypalSubs.length === 0 && Object.keys(device.data).length === 0) {
    readPaypalSubs();
  }
  //debugger;
  var isSubscriptionDisabled = true;
  var activeSub;
  var paymentValueActive = 'line-through';
  var serviceInfo =
    translation.sms_service_info_1 +
    ' ' +
    utils.SMS_SUBSCRIPTION_PRICE +
    ' ' +
    translation.per_month +
    ' ' +
    translation.sms_service_info_2;
  const [isEmailEnabled, setIsEmailEnabled] = useState(
    device.isAlarm_notify_enabled !== undefined
      ? device.isAlarm_notify_enabled
      : true
  );

  setActiveSub(paypalSubs);

  function setActiveSub(payPalSubs) {
    for (const sub of payPalSubs) {
      if (sub.status === utils.PAYPAL_SUBSCRIPTION_ACTIVE) {
        activeSub = sub;
        isSubscriptionDisabled = false;
        const now = new Date().getTime();
        const subscriptionCreated = new Date(sub.create_time).getTime();
        var start = moment(now);
        var end = moment(subscriptionCreated);
        const diffDays = start.diff(end, 'days');
        if (diffDays > 30) {
          paymentValueActive = '';
        }
      } else if (
        sub.status === utils.PAYPAL_SUBSCRIPTION_SUSPENDED ||
        sub.status === utils.PAYPAL_SUBSCRIPTION_CANCELLED
      ) {
        if (isSubscriptionDisabled) {
          activeSub = sub;
        }
      }
    }
  }

  useEffect(() => {
    if (device.isAlarm_notify_enabled === undefined) {
      setIsEmailEnabled(true);
    } else if (device.isAlarm_notify_enabled) {
      setIsEmailEnabled(true);
    } else {
      setIsEmailEnabled(false);
    }
  }, [device]);

  const [activeSubscription, setActiveSubscription] = useState(activeSub);

  async function readPaypalSubs() {
    try {
      const pSubs = await ReadPayPalSubscriptions(db, user.uid);
      if (pSubs !== undefined) {
        setPaypalSubs(pSubs);
        setActiveSub(pSubs);

        setActiveSubscription(activeSub);
      }
    } catch (error) {
      console.error(error);
    }
  }
  function handleChange({ target }) {
    setIsEmailEnabled(target.checked);
    device.isAlarm_notify_enabled = target.checked;

    setDevice(device);
    writeModel.path = utils.USERS_KEY + '/' + user.uid + '/' + utils.DEVICE_KEY;
    writeModel.doc = device.docId;
    writeModel.data = {
      isAlarm_notify_enabled: device.isAlarm_notify_enabled
    };
    writeModel.callback = undefined;
    dbUpdate(writeModel);
  }

  function setNewState(sub) {
    console.log(sub);
    setActiveSubscription(sub);
  }

  return (
    <div className="container p-0 pb-3">
      <h6 className="mt-4">{translation.device_remote_alarm_settings}</h6>
      <div className="card shadow">
        <div className="card-body px-3 py-3 pb-3">
          <div className="flex-column d-flex mb-1 align-items-center">
            <div className="d-flex mb-0 align-items-center w-100">
              <div className="flex-shrink-1 flex-grow-1">
                <div>
                  <div className="container p-0">
                    <div className="row flex-grow-0 flex-nowrap panel-item-heading-with-link">
                      <div className="col d-flex flex-grow-1">
                        <h4>{translation.remote_alarm_cooking_hazard}</h4>
                      </div>
                      <div className="col-auto text-end flex-grow-0 flex-shrink-1 justify-content-end flex-wrap">
                        <OverlayTrigger
                          trigger="click"
                          rootClose
                          placement="left"
                          overlay={
                            <Popover id="popover-status" style={{ margin: 0 }}>
                              <Popover.Header as="h3">
                                {translation.more_information}
                              </Popover.Header>
                              <Popover.Body>
                                <p>
                                  {translation.i001_cooking_remote_alarm_p1}
                                </p>
                                <p>
                                  {translation.i001_cooking_remote_alarm_p2}
                                </p>
                              </Popover.Body>
                            </Popover>
                          }
                        >
                          <p style={{ cursor: 'pointer' }}>
                            <FontAwesomeIcon
                              icon={faInfoCircle}
                              className="text-primary fs-1"
                            />
                          </p>
                        </OverlayTrigger>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-shrink-1 flex-grow-1 w-100">
              <div>
                <div className="container p-0">
                  <div className="row flex-grow-0 flex-nowrap panel-item-heading-with-link">
                    <div className="col">
                      <div className="form-check d-flex align-items-start form-switch mb-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="emailCheck"
                          onChange={handleChange}
                          checked={isEmailEnabled}
                        />
                        <label
                          className="form-check-label mt-1"
                          htmlFor="emailCheck"
                        >
                          {translation.email}
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="row flex-grow-0 flex-nowrap panel-item-heading-with-link mb-1">
                    <div className="col">
                      <SmsCheckBox
                        device={device}
                        disabled={isSubscriptionDisabled}
                        setDevice={setDevice}
                        user={user}
                        dbUpdate={dbUpdate}
                      />
                    </div>
                    <div className="col-auto d-flex flex-grow-0 justify-content-start">
                      <p
                        className="m-0 mt-1"
                        style={{ textDecoration: `${paymentValueActive}` }}
                      >
                        {utils.SMS_SUBSCRIPTION_PRICE}
                        {translation.per_month}
                      </p>
                    </div>
                  </div>
                  <div className="row flex-column toggle-switch-info-text">
                    <div className="col mb-2">
                      <PayPalSubscriptionNote sub={activeSubscription} />
                    </div>
                    <div className="col">
                      <p className="fs--1 text-muted">{serviceInfo}</p>
                    </div>
                    <div className="col">
                      <PayPalSubscriptionButton
                        db={db}
                        paypalSubs={paypalSubs}
                        user={user}
                        setNewState={setNewState}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

NotificationSettings.propTypes = {
  user: PropTypes.object,
  device: PropTypes.object,
  db: PropTypes.object
};
