/**
 * Back to home card.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
//import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons';
import * as utils from 'utils';
import GroupDropdown from './GroupDropdown';
import RenameGroupName from 'components/DataBase/Group/RenameGroupName';
import { useStoreActions } from 'easy-peasy';
import RemoveGroup from 'components/DataBase/Group/RemoveGroup';
import MoveGroup from 'components/DataBase/Group/MoveGroup';

export default function BackCard({ setPage, pageSettings, db, user }) {
  //console.log(pageSettings);

  const grbName =
    pageSettings.groupName !== undefined ? pageSettings.groupName : '';
  const [groupName, setGroupName] = useState(grbName);
  const { setDevice, updateGroupName, updateGroupParent } = useStoreActions(
    actions => ({
      setDevice: actions.setDevice,
      updateGroupName: actions.updateGroupName,
      updateGroupParent: actions.updateGroupParent
    })
  );

  useEffect(() => {
    setGroupName(pageSettings.groupName);
  }, [pageSettings]);

  function goBack() {
    if (pageSettings.parent !== undefined) {
      setPage({
        groupId: pageSettings.parent,
        groupName: pageSettings.parentGroupName,
        groupDevices: pageSettings.parentDevices,
        page: utils.ADVANCED_PAGES['DEVICE_LIST']
      });
    } else {
      setPage({
        page: utils.ADVANCED_PAGES['DEVICE_LIST']
      });
    }
  }

  async function renameGroup(newName) {
    console.log(newName, pageSettings.isGuest);
    var retVal = await RenameGroupName(
      db,
      pageSettings.groupId,
      newName,
      pageSettings.isGuest,
      user.uid
    );
    console.log(retVal);
    setGroupName(newName);
    updateGroupName({ groupId: pageSettings.groupId, name: newName });
  }

  async function removeGroup() {
    try {
      const retVal = await RemoveGroup(
        db,
        pageSettings.groupDevices,
        pageSettings.isGuest,
        user.uid
      );
      if (!retVal) {
        console.error('Something went wrong!');
      }
      for (var device of pageSettings.groupDevices) {
        if (pageSettings.isGuest) {
          device.groupId = '';
        } else {
          device.data.groupId = '';
        }
        setDevice(device);
      }
      goBack();
    } catch (error) {
      console.log(error);
    }
  }

  async function moveGroup(parentGroupId) {
    console.log(parentGroupId);
    if (parentGroupId !== undefined) {
      await MoveGroup(
        db,
        pageSettings.groupId,
        pageSettings.isGuest,
        user.uid,
        parentGroupId
      );
      updateGroupParent({
        groupId: pageSettings.groupId,
        parent: parentGroupId
      });
    }
  }
  return (
    <>
      <div className="d-flex justify-content-center col">
        <div className="card device shadow-lg">
          <div className={`card-body px-3 py-0 rounded`}>
            <div>
              <div className="">
                <div
                  className="row row-cols-3 py-2 px-2 rounded"
                  style={{
                    backgroundImage: 'linear-gradient(#00426C, #025B95)'
                  }}
                >
                  <div
                    className="col-1 d-flex justify-content-center align-items-center"
                    onClick={goBack}
                  >
                    <FontAwesomeIcon
                      icon={faArrowCircleLeft}
                      className="text-info fa-lg"
                    />
                  </div>
                  <div
                    className="d-flex flex-grow-1 align-items-center p-0 ps-2"
                    onClick={goBack}
                  >
                    <h5 className="device-cards-name  m-0">Back</h5>
                  </div>
                  <GroupDropdown
                    isGuest={pageSettings.isGuest}
                    groupName={groupName}
                    groupId={pageSettings.groupId}
                    renameGroup={renameGroup}
                    removeGroup={removeGroup}
                    moveGroup={moveGroup}
                  />
                </div>
              </div>
              <div className="m-3">
                <b>{groupName}</b>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

BackCard.propTypes = {
  setPage: PropTypes.func,
  pageSettings: PropTypes.object,
  db: PropTypes.object,
  user: PropTypes.object
};
