/**
 * Safera WiFi.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2025. All rights reserved. Safera Oy
 **/
import * as utils from 'utils';

export default function Wifi(deviceData) {
  return {
    id: utils.SAFERA_PRODUCTS['WIFI'],
    mechanicVariant: deviceData.mechanic_variant,
    variant: 0
  };
}
