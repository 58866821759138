/**
 * Move device to group.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/
import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Modal, Dropdown } from 'react-bootstrap';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { v4 as uuidv4 } from 'uuid';
import TextSanitizing from 'components/TextSanitizing';
import CreateGroup from 'components/DataBase/Group/CreateGroup';
//import useTranslation from 'hooks/translations';

export default function MoveDeviceModal({
  showModal,
  handleClose,
  device,
  moveDevice,
  user
}) {
  const groups = useStoreState(state => state.groups);
  const db = useStoreState(state => state.db);
  const [dropTitle, setDropTitle] = useState('Select one');
  //const translation = useTranslation();

  const [selectedGroupId, setSelectedGroupId] = useState(undefined);
  const [groupNameInput, setGroupNameInput] = useState('');
  const [errorTextArea, setErrorTextArea] = useState('');
  const groupNameRef = useRef();

  const { addGroup } = useStoreActions(actions => ({
    addGroup: actions.addGroup
  }));

  var myDeviceName = device.name;
  var rootItem = '';
  var groupList = [];
  var groupId = device.isGuest ? device.groupId : device.data.groupId;

  for (const grbId in groups) {
    if (groupId !== undefined) {
      if (grbId === groupId) {
        rootItem = (
          <Dropdown.Item key={uuidv4()} onClick={() => groupSelected('')}>
            Root
          </Dropdown.Item>
        );
        //groupList.unshift({ groupId: '', name: 'Root' });
      } else {
        if (device.isGuest === groups[grbId].isGuest) {
          groupList.push({ groupId: grbId, name: groups[grbId].name });
        }
      }
    }
  }
  var dropdown = (
    <Dropdown className="mb-3">
      <Dropdown.Toggle variant="success" id="dropdown-basic">
        {dropTitle}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item onClick={() => groupSelected('NEW_GROUP')}>
          Create new group
        </Dropdown.Item>
        {rootItem}
        <Dropdown.Divider />

        {groupList.map(group => (
          <Dropdown.Item
            key={uuidv4()}
            onClick={() => groupSelected(group.groupId)}
          >
            {group.name}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );

  async function createNewGroup() {
    const newName = TextSanitizing(groupNameRef.current.value, false);
    if (newName === '') {
      setErrorTextArea(
        <p className="text-warning">The group name is missing.</p>
      );
    } else if (groupNameRef.current.value !== newName) {
      setErrorTextArea(
        <p className="text-warning">
          Use only numbers or letters. Characters that are not allowed have been
          removed.
        </p>
      );
    } else if (newName.lenght > 60) {
      setErrorTextArea(
        <p className="text-warning">The name is too long. Max 60 characters.</p>
      );
    } else {
      const newGroupId = await CreateGroup(db, newName, device, user.uid);
      const data = { isActive: true, name: newName };
      addGroup({ groupId: newGroupId, data: data });
      moveDevice(newGroupId);
      handleClose();
    }
  }

  function groupSelected(grbId) {
    console.log(grbId);
    if (grbId === '') {
      setDropTitle('Root');
    } else if (grbId === 'NEW_GROUP') {
      setDropTitle('Create new group');
      setGroupNameInput(
        <>
          <label className="py-2">Enter a group name.</label>
          <input
            type="text"
            className="form-control"
            placeholder=""
            ref={groupNameRef}
          />
        </>
      );
    } else {
      setDropTitle(groups[grbId].name);
    }
    setSelectedGroupId(grbId);
  }

  function moveDeviceHandler() {
    if (selectedGroupId === undefined) {
      console.log('Select group first');
    } else if (selectedGroupId === 'NEW_GROUP') {
      createNewGroup();
    } else {
      moveDevice(selectedGroupId);
      handleClose();
    }
  }
  return (
    <>
      <Modal role="dialog" tabIndex="-1" id="modal-move-group" show={showModal}>
        <Modal.Header className="pe-4 border-0">
          <h4 className="modal-title text-break pe-5">
            {myDeviceName}
            <br />
          </h4>
          <button
            type="button"
            className="btn-close"
            onClick={handleClose}
            aria-label="Close"
          ></button>
        </Modal.Header>
        <Modal.Body>
          <label className="pb-2">
            Select the group you want to move your device to.
          </label>
          {dropdown}
          {groupNameInput}
          {errorTextArea}
        </Modal.Body>
        <Modal.Footer className="border-0 pt-0 pb-4">
          <button
            className="btn btn-primary w-100 shadow"
            type="button"
            onClick={() => moveDeviceHandler()}
          >
            Move
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

MoveDeviceModal.propTypes = {
  showModal: PropTypes.bool,
  device: PropTypes.object,
  handleClose: PropTypes.func,
  moveDevice: PropTypes.func,
  user: PropTypes.object
};
