/**
 * Device type image.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/
import * as utils from '../../../utils';

export default function DeviceTypeImage(deviceData) {
  //console.log('deviceData', deviceData);
  var deviceProduct = deviceData.product;
  if (deviceProduct === undefined) {
    return 'wifi';
  }
  if (
    deviceProduct.id === utils.SAFERA_PRODUCTS['CR'] ||
    deviceProduct.id === utils.SAFERA_PRODUCTS['FLOWHERO']
  ) {
    return 'integral';
  } else if (deviceProduct.id === utils.SAFERA_PRODUCTS['PRO']) {
    return 'pro';
  } else if (deviceProduct.id === utils.SAFERA_PRODUCTS['WIFI']) {
    if (
      deviceProduct.mechanicVariant ===
      utils.SENSE_MECHANICAL_VARIANT['BLACK_BLACK']
    ) {
      return 'pro';
    } else {
      return 'wifi';
    }
  } else {
    console.log('Unknown device type', deviceProduct);
    return 'unknown';
  }
}
