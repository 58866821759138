/**
 * Remove user device.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/
import * as utils from '../../../utils';

export default async function RemoveDevice(db, device, uid) {
  try {
    console.log(device);
    console.log(uid);
    if (db === undefined || device === undefined || uid === undefined) {
      console.error('Data missing');
      return false;
    }
    if (uid === device.data.owner.id) {
      // mark all device users unregistered
      const deviceRef = db.collection(
        utils.DEVICE_KEY + '/' + device.device_id + '/' + utils.USERS_KEY
      );
      const devUsrDoc = await deviceRef.get();
      for (const doc of devUsrDoc.docs) {
        console.log(doc.data());
        const deviceDocRef = db
          .collection(
            utils.DEVICE_KEY + '/' + device.device_id + '/' + utils.USERS_KEY
          )
          .doc(doc.id);
        await deviceDocRef.set(
          {
            isOwner: false,
            isRegistered: false
          },
          { merge: true }
        );
      }
    } else {
      console.log(db);
      // mark device unregistered
      const deviceRef = db
        .collection(
          utils.DEVICE_KEY + '/' + device.device_id + '/' + utils.USERS_KEY
        )
        .doc(uid);
      await deviceRef.set(
        {
          isOwner: false,
          isRegistered: false
        },
        { merge: true }
      );
    }
    // mark user device unregistered
    let usrDevRef = db
      .collection(utils.USERS_KEY + '/' + uid + '/' + utils.DEVICE_KEY)
      .doc(device.docId);
    await usrDevRef.set(
      {
        isRegistered: false
      },
      { merge: true }
    );

    // remove device data
    let devRef = db.collection(utils.DEVICE_KEY).doc(device.device_id);
    await devRef.set({
      hw_capabilities_id:
        device.hw_capabilities_id !== undefined ? device.hw_capabilities_id : 0,
      hw_id: device.hw_id !== undefined ? device.hw_id : 0,
      hw_mechanics_id:
        device.hw_mechanics_id !== undefined ? device.hw_mechanics_id : 0,
      hw_minor_id: device.hw_minor_id !== undefined ? device.hw_minor_id : 0
    });

    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}
