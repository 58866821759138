/**
 * Message Button.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2023. All rights reserved. Safera Oy
 **/
import React, { useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import * as utils from 'utils';
import { AdvancedContext } from 'context/Context';
import { AddNewUserDevice } from 'components/DataBase/Device/AddNewUserDevice';
import Modal from 'react-bootstrap/Modal';

export default function MessageButton({ button, message }) {
  console.log(message);
  const advancedDispatch = useContext(AdvancedContext);
  const [msg, setMsg] = useState(message);
  const [showModal, setModalShow] = useState(false);
  const handleModalClose = () => setModalShow(false);

  async function handleRespond() {
    switch (message.type) {
      case utils.MESSAGE_TYPE['share']:
        setDevice(message.deviceId, true, button.text, message.deviceName);
        break;
      case utils.MESSAGE_TYPE['share_group']:
        setDevices(msg.devices, true, button.text);
        break;
      case utils.MESSAGE_TYPE['transfer_ownership']:
        setDevice(message.deviceId, false, button.text, message.deviceName);
        break;
      case utils.MESSAGE_TYPE['transfer_group_ownership']:
        setDevices(msg.devices, false, button.text);
        break;
      case utils.MESSAGE_TYPE['share_done']:
      case utils.MESSAGE_TYPE['share_group_done']:
      case utils.MESSAGE_TYPE['transfer_ownership_done']:
      case utils.MESSAGE_TYPE['transfer_group_ownership_done']:
      case utils.MESSAGE_TYPE['info']:
        // There should be no push button
        console.error('Wrong button press.');
        break;
      case utils.MESSAGE_TYPE['new_chat_message']:
        console.log('Chat is not yet supported.');
        break;
      default:
        console.error('Pressing an unknown button.');
        break;
    }
  }

  async function setDevices(devices, isGuest, buttonTxt) {
    var isErrors = false;
    if (devices === undefined) {
      return;
    }
    for (const deviceId in devices) {
      var retVal = await setDevice(
        deviceId,
        isGuest,
        buttonTxt,
        devices[deviceId].deviceName
      );

      if (retVal) {
        retVal = true;
      } else {
        isErrors = true;
      }
    }
    if (isErrors) {
      setModalShow(true);
      console.error('Device registration error');
    }
  }

  async function setDevice(deviceId, isGuest, buttonTxt, deviceName) {
    setMsg({
      disabled: 'disabled',
      hidden: '',
      text: buttonTxt,
      color: 'dark'
    });
    var ret = await AddNewUserDevice(advancedDispatch.db, {
      deviceId: deviceId,
      uid: advancedDispatch.user.uid,
      isGuest: isGuest,
      deviceName: deviceName
    });
    if (!ret) {
      setModalShow(true);
      console.error('Error! Failed to add device.');
    }
    return ret;
  }

  return (
    <>
      <Button
        variant={button.color}
        size="lg"
        className={`my-3 ${button.disabled} ${button.hidden}`}
        onClick={handleRespond}
      >
        {button.text}
      </Button>
      <Modal
        show={showModal}
        role="dialog"
        tabIndex="-1"
        id="modal-message-button-error"
      >
        <div className="modal-header pe-4 border-0">
          <h4 className="modal-title text-break pe-5">
            ERROR!
            <br />
          </h4>
          <button
            type="button"
            className="btn-close"
            onClick={handleModalClose}
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          Something went wrong: One or more devices did not update correctly.
        </div>
      </Modal>
    </>
  );
}

MessageButton.propTypes = {
  button: PropTypes.object,
  message: PropTypes.object
};
