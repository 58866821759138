/**
 * Parse daylog version 6 data.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/
import * as utils from 'utils';

export default function GetDayObjectV6(doc) {
  var dayObj = {};
  if (doc.data().data === undefined) {
    return dayObj;
  }
  var docOrdered = Object.keys(doc.data().data)
    .sort()
    .reduce((obj, key) => {
      obj[key] = doc.data().data[key];
      return obj;
    }, {});
  //console.log(docOrdered);
  const latestData =
    docOrdered[Object.keys(docOrdered)[Object.keys(docOrdered).length - 1]];
  //console.log(latestData);
  dayObj[utils.RECORD_VERSION_NAME] = doc.data()[utils.RECORD_VERSION_NAME];
  dayObj[utils.UNIX_EPOCH_NAME] = doc.data()[utils.UNIX_EPOCH_NAME];
  dayObj[utils.MIU_TIMEZONE_NAME] = doc.data()[utils.MIU_TIMEZONE_NAME];
  dayObj[utils.BATTERY_VOLTAGE_NAME] =
    latestData[utils.BATTERY_PSU_VOLTAGE_NAME];
  dayObj[utils.BATTERY_NAME] = latestData[utils.BATTERY_P_NAME];
  dayObj[utils.MIU_STOVE_TYPE_NAME] = latestData[utils.MIU_STOVE_TYPE_NAME];
  dayObj[utils.MIU_INST_HEIGHT_NAME] = latestData[utils.MIU_INST_HEIGHT_NAME];
  dayObj[utils.MIU_INST_POS_NAME] = latestData[utils.MIU_INST_POS_NAME];

  var tempAmbientMax = 0;
  var tempAmbientMin;
  var tempIrMax = 0;
  var tempIrMin;
  var rhMax = 0;
  var rhMin;
  var pm25Max = 0;
  var tvocMax = 0;
  var eco2Max = 0;
  var humanPresenceCount = 0; // human presence detected around the sensor (may be other activity than cooking also)
  var cookingCount = 0; // triggered after 2..3 min of cooking (and cooking confirm detection from AI)
  var otherApplianceCount = 0; // other appliance activity detected (for example dishwasher active, or over releasing steam/smoke)
  var alarmCount = 0; // stove guard triggered, alarm beep starts
  var poweroffCount = 0; // stove guard with PCU: triggered in 15s from alarm (PCU power cut) or without PCU: triggered in 15s just to see if the user was not present and did not cancel the alarm quickly

  for (var sensorDataTimestamp in docOrdered) {
    const slots = docOrdered[sensorDataTimestamp].measured_slot_count;
    humanPresenceCount +=
      docOrdered[sensorDataTimestamp][utils.HUMAN_PRESENCE_MAP_NAME] !==
      undefined
        ? docOrdered[sensorDataTimestamp][utils.HUMAN_PRESENCE_MAP_NAME] & 0xf
        : 0;
    cookingCount +=
      docOrdered[sensorDataTimestamp][utils.COOKIN_MAP_NAME] !== undefined
        ? docOrdered[sensorDataTimestamp][utils.COOKIN_MAP_NAME] & 0xf
        : 0;
    otherApplianceCount +=
      docOrdered[sensorDataTimestamp][utils.OTHER_APPLIANCE_MAP_NAME] !==
      undefined
        ? docOrdered[sensorDataTimestamp][utils.OTHER_APPLIANCE_MAP_NAME] & 0xf
        : 0;
    alarmCount +=
      docOrdered[sensorDataTimestamp][utils.ALARM_MAP_NAME] !== undefined
        ? docOrdered[sensorDataTimestamp][utils.ALARM_MAP_NAME] & 0xf
        : 0;
    poweroffCount +=
      docOrdered[sensorDataTimestamp][utils.POWEROFF_MAP_NAME] !== undefined
        ? docOrdered[sensorDataTimestamp][utils.POWEROFF_MAP_NAME] & 0xf
        : 0;
    //console.log(slots);
    var time = docOrdered[sensorDataTimestamp].unix_epoch - slots * 600;
    if (docOrdered[sensorDataTimestamp].temp_abient.max > tempAmbientMax) {
      tempAmbientMax = docOrdered[sensorDataTimestamp].temp_abient.max;
      var t_max = parseFloat(tempAmbientMax, 10).toFixed(2);
      dayObj[utils.TEMP_MAX] = t_max / 100 - 50;
      dayObj[utils.TEMP_MAX_TIME_NAME] =
        time + 600 * docOrdered[sensorDataTimestamp].temp_abient.max_slot;
    }
    if (
      tempAmbientMin === undefined ||
      docOrdered[sensorDataTimestamp].temp_abient.min < tempAmbientMin
    ) {
      tempAmbientMin = docOrdered[sensorDataTimestamp].temp_abient.min;
      var t_min = parseFloat(tempAmbientMin, 10).toFixed(2);
      dayObj[utils.TEMP_MIN] = t_min / 100 - 50;
      dayObj[utils.TEMP_MIN_TIME_NAME] =
        time + 600 * docOrdered[sensorDataTimestamp].temp_abient.min_slot;
    }
    if (docOrdered[sensorDataTimestamp].temp_ir.max > tempIrMax) {
      tempIrMax = docOrdered[sensorDataTimestamp].temp_ir.max;
      dayObj[utils.TEMP_IR_MAX_NAME] = tempIrMax;
      dayObj[utils.TEMP_IR_MAX_TIME_NAME] =
        time + 600 * docOrdered[sensorDataTimestamp].temp_ir.max_slot;
    }
    if (
      tempIrMin === undefined ||
      docOrdered[sensorDataTimestamp].temp_ir.min < tempIrMin
    ) {
      tempIrMin = docOrdered[sensorDataTimestamp].temp_ir.min;
      dayObj[utils.TEMP_IR_MIN_NAME] = tempIrMin;
      dayObj[utils.TEMP_IR_MIN_TIME_NAME] =
        time + 600 * docOrdered[sensorDataTimestamp].temp_ir.min_slot;
    }
    if (docOrdered[sensorDataTimestamp].rh.max > rhMax) {
      rhMax = docOrdered[sensorDataTimestamp].rh.max;
      dayObj[utils.RH_MAX] = rhMax;
      dayObj[utils.RH_MAX_TIME_NAME] =
        time + 600 * docOrdered[sensorDataTimestamp].rh.max_slot;
    }
    if (rhMin === undefined || docOrdered[sensorDataTimestamp].rh.min < rhMin) {
      rhMin = docOrdered[sensorDataTimestamp].rh.min;
      dayObj[utils.RH_MIN] = rhMin / 2;
      dayObj[utils.RH_MIN_TIME_NAME] =
        time + 600 * docOrdered[sensorDataTimestamp].rh.min_slot;
    }
    if (docOrdered[sensorDataTimestamp].pm25.max > pm25Max) {
      pm25Max = docOrdered[sensorDataTimestamp].pm25.max;
      dayObj[utils.PM25_MAX_NAME] = pm25Max / 2;
      dayObj[utils.PM25_MAX_TIME_NAME] =
        time + 600 * docOrdered[sensorDataTimestamp].pm25.max_slot;
    }
    if (docOrdered[sensorDataTimestamp].tvoc.max > tvocMax) {
      tvocMax = docOrdered[sensorDataTimestamp].tvoc.max;
      dayObj[utils.TVOC_MAX] = tvocMax;
      dayObj[utils.TVOC_MAX_TIME_NAME] =
        time + 600 * docOrdered[sensorDataTimestamp].tvoc.max_slot;
    }
    if (docOrdered[sensorDataTimestamp].eco2.max > eco2Max) {
      eco2Max = docOrdered[sensorDataTimestamp].eco2.max;
      dayObj[utils.ECO2_MAX] = eco2Max;
      dayObj[utils.ECO2_MAX_TIME_NAME] =
        time + 600 * docOrdered[sensorDataTimestamp].eco2.max_slot;
    }
  }

  dayObj[utils.HUMAN_PRESENCE_COUNT_NAME] = humanPresenceCount;
  dayObj[utils.COOKIN_COUNT_NAME] = cookingCount;
  dayObj[utils.OTHER_APPLIANCE_COUNT_NAME] = otherApplianceCount;
  dayObj[utils.ALARM_COUNT_NAME] = alarmCount;
  dayObj[utils.POWEROFF_COUNT_NAME] = poweroffCount;

  //console.log(dayObj);
  return dayObj;
}
