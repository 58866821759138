/**
 * Device list.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/

import React from 'react';
import Device from './Device/Device';
import { useStoreState } from 'easy-peasy';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import * as utils from 'utils';
import GroupCard from './Group/GroupCard';
import BackCard from './Group/BackCard';

export default function DeviceList({ setPage, user, pageSettings, db }) {
  const devices = useStoreState(state => state.devices);
  const groups = useStoreState(state => state.groups);
  const groupId = pageSettings !== undefined ? pageSettings.groupId : undefined;
  var showDevices = [];
  var showGroupDevices = [];
  var groupArr = [];
  var groupObj = {};
  var back = '';
  //console.log(pageSettings);
  for (const dev of devices) {
    const group_id = getDeviceGroupId(dev);
    if (group_id !== undefined && group_id !== '') {
      if (groupArr.indexOf(group_id) === -1) {
        groupArr.push(group_id);
        groupObj[group_id] = [dev];
      } else {
        groupObj[group_id].push(dev);
      }
    } else {
      showDevices.push(dev);
    }

    // Group view
    if (groupId !== undefined && groupId !== '') {
      if (group_id === groupId) {
        showGroupDevices.push(dev);
      }
    }

    if (
      dev.smartthings !== undefined &&
      dev.smartthings === utils.SMARTTHINGS_REGISTERED
    ) {
      user.isSmartThings = true;
    }
  }

  if (groupId !== undefined && groupId !== '') {
    showDevices = showGroupDevices;
    groupArr = getChildGroupIds(groupId);

    const parenGroupId = groups[groupId].parent;
    if (parenGroupId !== undefined && parenGroupId !== '') {
      pageSettings.parent = parenGroupId;
      pageSettings.parentGroupName = groups[parenGroupId].name;
      pageSettings.parentDevices = groupObj[parenGroupId];
    }
    back = (
      <>
        <BackCard
          setPage={setPage}
          pageSettings={pageSettings}
          db={db}
          user={user}
        />
      </>
    );
  } else {
    var tempGroupArr = [];
    for (const tempGroupId of groupArr) {
      if (isChildGroup(tempGroupId)) {
        tempGroupArr.push(tempGroupId);
      }
    }
    groupArr = tempGroupArr;
  }

  //console.log(groups);

  function isChildGroup(group_id) {
    if (groups[group_id] === undefined) {
      return false;
    }
    if (
      groups[group_id].parent !== undefined &&
      groups[group_id].parent !== ''
    ) {
      return false;
    }
    return true;
  }

  function getNumOfChildGroups(group_id) {
    var retVal = 0;
    for (const grb in groups) {
      if (groups[grb].parent !== undefined && groups[grb].parent === group_id) {
        retVal++;
      }
    }
    return retVal;
  }

  function getChildGroupIds(groupId) {
    var childGroupIds = [];
    for (const grbId of groupArr) {
      if (groups[grbId].parent === groupId) {
        childGroupIds.push(grbId);
      }
    }
    return childGroupIds;
  }

  function getDeviceGroupId(devData) {
    if (devData.groupId !== undefined) {
      return devData.groupId;
    } else {
      if (devData.data.groupId !== undefined) {
        return devData.data.groupId;
      }
    }
    return;
  }

  return (
    <>
      {back}
      {groupArr.map(groupId => (
        <GroupCard
          key={uuidv4()}
          groupId={groupId}
          setPage={setPage}
          groupDevices={groupObj[groupId]}
          numOfChildGroups={getNumOfChildGroups(groupId)}
          db={db}
          user={user}
        />
      ))}
      {showDevices.map(device => (
        <Device key={uuidv4()} device={device} setPage={setPage} user={user} />
      ))}
    </>
  );
}

DeviceList.propTypes = {
  setPage: PropTypes.func,
  devices: PropTypes.array,
  user: PropTypes.object,
  pageSettings: PropTypes.object,
  db: PropTypes.object
};
