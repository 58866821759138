/**
 * Device.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/

import React from 'react';
import PropTypes from 'prop-types';

import StatusIcon from './StatusIcon';
import DeviceTypeImage from './DeviceTypeImage';
import BattryOrGeaseFilterIcon from './BattryOrGeaseFilterIcon';
import DeviceDropdown from './DeviceDropdown';
import * as utils from 'utils';

export default function Device({ device, setPage, user }) {
  //console.log(device);
  const deviceData = device.data !== undefined ? device.data : {};
  // console.log(deviceData);
  return (
    <>
      <div className="d-flex justify-content-center col">
        <div className="card device shadow-lg">
          <div
            className={`card-body card-body-sense-${DeviceTypeImage(
              deviceData
            )} px-3 py-2 rounded`}
          >
            <div>
              <div className="container">
                <div className="row row-cols-3">
                  <div className="col-1 d-flex justify-content-center align-items-center p-0">
                    <StatusIcon device={device} />
                  </div>
                  <div className="col-1 d-flex flex-grow-1 align-items-center p-0 ps-2">
                    <h5 className="device-cards-name m-0">{device.name}</h5>
                  </div>
                  <div className="col-auto d-inline-flex justify-content-end align-items-center p-0 ps-3">
                    <DeviceDropdown
                      device={device}
                      setPage={setPage}
                      user={user}
                    />
                  </div>
                </div>
              </div>
              <div className="container">
                <BattryOrGeaseFilterIcon device={device} />
              </div>
              <div
                style={{ height: '90px' }}
                className="row"
                onClick={() =>
                  setPage({
                    device: device,
                    page: utils.PERSONAL_PAGES['DEVICE_SETTINGS'],
                    subPage:
                      utils.PERSONAL_DEVICE_SETTINGS_SUB_PAGE[
                        'NOTIFICATION_SETTINGS'
                      ]
                  })
                }
              ></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

Device.propTypes = {
  device: PropTypes.object,
  setPage: PropTypes.func,
  user: PropTypes.object
};
