/**
 * Front Page.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/

import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Personal from '../Personal/Personal';
import Advanced from 'advanced/Advanced';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { useLanguageContext } from '../context/LanguageContext';
import { CLOUD_PACKAGE } from 'utils';
import WizardHandler from './DeviceRegistartionWizard/WizardHandler';
import * as utils from 'utils';
import PolicyPage from './PolicyPage';
import TypeHandler from './TypeHandler';
import TagManager from 'react-gtm-module';
//import EmailVerificationPage from './EmailVerificationPage';

const LOCAL_DEVICE_STORAGE_KEY = 'safera-devices';
const LOCAL_GROUPS_STORAGE_KEY = 'safera-groups';

export default function FrontPage(props) {
  const urlResult = new URLSearchParams(window.location.search);
  //console.log(urlResult.values);
  var urlParameters;
  var pageSettings;

  const {
    readDevices,
    readPayPalSubscription,
    setDevices,
    setDb,
    setStoreUser,
    setGroups,
    readMessages
  } = useStoreActions(actions => ({
    readDevices: actions.readDevices,
    readPayPalSubscription: actions.readPayPalSubscription,
    setDevices: actions.setDevices,
    setDb: actions.setDb,
    setStoreUser: actions.setUser,
    setGroups: actions.setGroups,
    readMessages: actions.readMessages
  }));

  const readDevicesRef = useRef(readDevices);
  const readPayPalSubscriptionRef = useRef(readPayPalSubscription);
  const readMessagesRef = useRef(readMessages);
  const dbRef = useRef(props.db);
  const userRef = useRef(props.user);
  const setDbRef = useRef(setDb);
  const setDeVicesRef = useRef(setDevices);
  const setGroupsRef = useRef(setGroups);

  var analyticsUserId = props.user.uid;
  if (urlResult.has('analytics_id')) {
    analyticsUserId = urlResult.get('analytics_id');
  }

  if (urlResult.has('settings')) {
    pageSettings = {
      page: utils.PERSONAL_PAGES['DEVICE_SETTINGS'],
      subPage: utils.PERSONAL_DEVICE_SETTINGS_SUB_PAGE['NOTIFICATION_SETTINGS'],
      device: { device_id: urlResult.get('settings') }
    };
  }
  const { changeLanguage } = useLanguageContext();
  const changeLanguageRef = useRef(changeLanguage);
  const devices = useStoreState(state => state.devices);
  const groups = useStoreState(state => state.groups);
  //const storeUser = useStoreState(state => state.user);
  var content;
  if (urlResult.has('id')) {
    urlParameters = getUrlValues(urlResult);
    //console.log('Config:', urlParameters);
    content = (
      <WizardHandler
        db={props.db}
        user={props.user}
        config={urlParameters}
        auth={props.auth}
        firebaseSignout={props.firebaseSignout}
        showCustomPage={showCustomPage}
      />
    );
  } else if (urlResult.has('safera_type')) {
    content = (
      <TypeHandler
        db={props.db}
        user={props.user}
        urlResult={urlResult}
        auth={props.auth}
        firebaseSignout={props.firebaseSignout}
      />
    );
  } else if (!props.user.policyAccepted) {
    content = <PolicyPage db={props.db} user={props.user} />;
  } else if (!props.user.emailVerified) {
    content = getContent(props.user, pageSettings);
    // TODO: When E-mail verification is required
    // content = (
    //   <EmailVerificationPage
    //     auth_user={props.auth_user}
    //     sendEmailVerification={props.sendEmailVerification}
    //     showCustomPage={showCustomPage}
    //   />
    // );
  } else {
    content = getContent(props.user, pageSettings);
  }

  const [user, setUsr] = useState(props.user);
  const [pageContent, setPageContent] = useState(content);

  // const readDevices = useStoreActions(actions => actions.readDevices);
  // const setDevices = useStoreActions(actions => actions.setDevices);
  // const setDb = useStoreActions(actions => actions.setDb);
  // const setUser = useStoreActions(actions => actions.setUser);

  function getUrlValues(urlResult) {
    return {
      id: urlResult.has('id') ? urlResult.get('id') : '',
      hw_major: urlResult.has('hw_major') ? urlResult.get('hw_major') : '',
      hw_minor: urlResult.has('hw_minor') ? urlResult.get('hw_minor') : '',
      hw_params: urlResult.has('hw_params') ? urlResult.get('hw_params') : '',
      mech_variant: urlResult.has('mech_variant')
        ? urlResult.get('mech_variant')
        : '',
      firmware: urlResult.has('firmware') ? urlResult.get('firmware') : '',
      bootloader: urlResult.has('bootloader')
        ? urlResult.get('bootloader')
        : '',
      analytics_id: urlResult.has('analytics_id')
        ? urlResult.get('analytics_id')
        : '',
      serial: urlResult.has('serial') ? urlResult.get('serial') : ''
    };
  }

  function showCustomPage(pageSettings) {
    setPageContent(getContent(props.user, pageSettings));
  }

  function setUser(user) {
    console.log(user);
    setStoreUser(user);
    setUsr(user);
  }

  useEffect(() => {
    console.log('FrontPage');
    setDbRef.current(dbRef.current);
    //setUser(props.user);
    const storedUserDevices = JSON.parse(
      localStorage.getItem(LOCAL_DEVICE_STORAGE_KEY)
    );

    if (storedUserDevices) {
      setDeVicesRef.current(configStoredActivityTimestamp(storedUserDevices));
    }
    const storedGroups = JSON.parse(
      localStorage.getItem(LOCAL_GROUPS_STORAGE_KEY)
    );
    if (storedGroups) {
      setGroupsRef.current(storedGroups);
    }
    readDevicesRef.current({ db: dbRef.current, user: userRef.current });
    readPayPalSubscriptionRef.current({
      db: dbRef.current,
      user: userRef.current
    });
    readMessagesRef.current({ db: dbRef.current, user: userRef.current });
  }, []);

  useEffect(() => {
    console.log(user.language);
    changeLanguageRef.current(
      user.language !== undefined ? user.language : 'en'
    );
  }, [user]);

  useEffect(() => {
    //console.log(devices);
    localStorage.setItem(LOCAL_DEVICE_STORAGE_KEY, JSON.stringify(devices));
  }, [devices]);

  useEffect(() => {
    localStorage.setItem(LOCAL_GROUPS_STORAGE_KEY, JSON.stringify(groups));
  }, [groups]);

  function configStoredActivityTimestamp(deviceList) {
    var deviceArr = [];
    for (const dev of deviceList) {
      if (
        dev.data.last_activity_timestamp <
        new Date().getTime() - utils.TIME_24H
      ) {
        dev.data.last_activity_timestamp = 1;
      }
      deviceArr.push(dev);
    }
    return deviceArr;
  }
  function logout() {
    props.firebaseSignout(props.auth);
  }

  function getContent(user, pageSettings) {
    var page = '';

    switch (parseInt(user.cloudPackage)) {
      case CLOUD_PACKAGE['personal']:
        page = (
          <Personal
            db={props.db}
            setUser={setUser}
            user={user}
            updateUser={props.updateUser}
            logout={logout}
            changeLanguage={changeLanguage}
            pageSettings={pageSettings}
            auth_user={props.auth_user}
          />
        );
        break;
      case CLOUD_PACKAGE['care']:
      case CLOUD_PACKAGE['care_expired']:
      case CLOUD_PACKAGE['care_plus']:
      case CLOUD_PACKAGE['advanced']:
        TagManager.dataLayer({
          dataLayer: {
            userId: analyticsUserId,
            userProject: 'Advanced',
            page: 'Advanced Page'
          },
          dataLayerName: 'SaferaDataLayer'
        });
        window.location.replace('/advanced/index.html');
        break;
      case CLOUD_PACKAGE['personal_pro']:
        page = (
          <Advanced
            db={props.db}
            setUser={setUser}
            user={user}
            updateUser={props.updateUser}
            logout={logout}
            changeLanguage={changeLanguage}
            pageSettings={pageSettings}
          />
        );
        break;
      default:
        break;
    }
    return page;
  }

  return pageContent;
}

FrontPage.propTypes = {
  db: PropTypes.object,
  user: PropTypes.object,
  firebaseSignout: PropTypes.func,
  updateUser: PropTypes.func,
  auth: PropTypes.object
};
