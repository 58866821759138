import { action, thunk } from 'easy-peasy';
import { ReadDevice } from './DataBase/Device/ReadDevice';
import { EventListener } from './DataBase/EventListener';
import * as utils from '../utils';
import Update from './DataBase/Update';
import RemoveDevice from './DataBase/Device/RemoveDevice';
import { ReadPayPalSubscriptions } from './DataBase/ReadPayPalSubscriptions';
import UnlinkSmartThings from './DataBase/Vendor/UnlinkSmartThings';
import LinkSmartThings from './DataBase/Vendor/LinkSmartThings';
import ReadGroup from './DataBase/Group/ReadGroup';
import { ReadUserMessages } from './DataBase/Message/ReadUserMessages';
import ReadDayLogs from 'data/SensorData/ReadDayLogs';
import ReadOwner from './DataBase/Device/ReadOwner';
import ProductCatalog from './Product/ProductCatalog';

export default {
  loading: false,
  db: undefined,
  user: {},
  devices: [],
  groups: {},
  paypalSubscriptions: [],
  messages: [],
  // Thunks
  readDevices: thunk(async (actions, dataObj) => {
    //console.log(db);
    async function readUserDevices(db) {
      // console.log(dataObj.user);
      try {
        if (db === null) {
          console.log('NULL');
        }
        actions.setLoading(true);
        //const uid = props.user.uid;
        var userDevices = [];
        //const uid = 'buV4aaLMoiNfpRRAtQ2Rn3l3UmM2';
        const usrDevRef = db
          .collection(
            utils.USERS_KEY + '/' + dataObj.user.uid + '/' + utils.DEVICE_KEY
          )
          .where('isRegistered', '==', true);
        const usrDevDoc = await usrDevRef.get();

        for (const doc of usrDevDoc.docs) {
          //console.log(doc.data());
          var devData;
          if (doc.data().data !== undefined) {
            devData = doc.data().data;
            const owner = await ReadOwner(db, doc.data().device_id);
            devData.owner = owner;
          } else {
            devData = await ReadDevice(
              db,
              doc.data().device_id,
              dataObj.user.uid
            );
          }
          var deviceData = doc.data();
          devData.product = ProductCatalog(devData);
          deviceData['docId'] = doc.id;
          if (devData !== undefined) {
            EventListener(actions, db, doc.data().device_id);
            deviceData.data = devData;
            userDevices.push(deviceData);
          }
        }

        return userDevices;
      } catch (error) {
        console.error(error);
      }
    }

    async function setGroupsData(devices) {
      var groupObj = {};
      for (const dev of devices) {
        const group_id = getGroupId(dev);
        if (group_id !== undefined && group_id !== '') {
          const group = await ReadGroup(
            dataObj.db,
            group_id,
            dev.isGuest,
            dataObj.user.uid
          );

          if (group !== undefined) {
            groupObj[group_id] = group;
          }
          //actions.addGroup({ groupId: group_id, data: group });
        }
      }
      //console.log(groupObj);
      actions.setGroups(checkIfParentExists(groupObj));
    }

    function checkIfParentExists(groupObj) {
      if (groupObj === undefined) {
        return {};
      }

      for (const groupId in groupObj) {
        if (
          groupObj[groupId].parent !== undefined &&
          groupObj[groupId].parent !== ''
        ) {
          if (groupObj[groupObj[groupId].parent] === undefined) {
            groupObj[groupId].parent = '';
          }
        }
      }
      return groupObj;
    }

    function getGroupId(devData) {
      if (devData.groupId !== undefined) {
        return devData.groupId;
      } else {
        if (devData.data.groupId !== undefined) {
          return devData.data.groupId;
        }
      }
      return;
    }
    const data = await readUserDevices(dataObj.db);
    await setGroupsData(data);
    actions.setLoading(false);
    actions.setDevices(data);
  }),
  readPayPalSubscription: thunk(async (actions, dataObj) => {
    try {
      const subsData = await ReadPayPalSubscriptions(
        dataObj.db,
        dataObj.user.uid
      );
      actions.setPayPalSubscriptions(subsData);
    } catch (error) {
      console.error(error);
    }
  }),
  readMessages: thunk(async (actions, dataObj) => {
    try {
      const msgData = await ReadUserMessages(dataObj.db, dataObj.user);
      actions.setMessages(msgData);
      //console.log(msgData);
    } catch (error) {
      console.error(error);
    }
  }),
  // Actions
  setUser: action((state, user) => {
    state.user = user;
    //console.log(user);
  }),
  getUser: action(state => {
    return state.user;
  }),
  setDb: action((state, db) => {
    state.db = db;
  }),
  setDevice: action((state, newDeviceData) => {
    console.log(newDeviceData);
    var devList = [...state.devices];
    const targetIndex = devList.findIndex(
      dev => dev.device_id === newDeviceData.device_id
    );
    //console.log(targetIndex);
    devList[targetIndex] = newDeviceData;
    state.devices = devList;

    //console.log(state.devices);
  }),
  addDevice: action((state, device) => {
    state.devices.unshift(device);
  }),
  getDevice: action((state, deviceId) => {
    console.log(deviceId);
    var devList = [...state.devices];
    const targetIndex = devList.findIndex(dev => dev.device_id === deviceId);
    console.log(state.devices);
    return devList[targetIndex];
  }),
  setDevices: action((state, devices) => {
    //console.log(devices);
    state.devices = devices;
  }),
  setGroups: action((state, groups) => {
    //console.log(devices);
    state.groups = groups;
  }),
  addGroup: action((state, groupObj) => {
    //console.log(groupObj);
    state.groups[groupObj.groupId] = groupObj.data;
  }),
  updateGroupName: action((state, groupObj) => {
    //console.log(groupObj);
    var groups = { ...state.groups };
    groups[groupObj.groupId].name = groupObj.name;
    state.groups = groups;
  }),
  updateGroupParent: action((state, groupObj) => {
    //console.log(groupObj);
    var groups = { ...state.groups };
    groups[groupObj.groupId].parent = groupObj.parent;
    state.groups = groups;
  }),
  userUpdate: action((state, user) => {
    console.log(user);
    state.user = user;
  }),
  setPayPalSubscriptions: action((state, subs) => {
    //console.log(subs);
    state.paypalSubscriptions = subs;
  }),
  setMessages: action((state, messages) => {
    //console.log(subs);
    state.messages = messages;
  }),
  updateMessageRead: action((state, messageObj) => {
    var messages = [...state.messages];
    const targetIndex = messages.findIndex(msg => msg.id === messageObj.id);
    if (targetIndex >= 0) {
      messages[targetIndex].read = messageObj.read;
      state.messages = messages;
    }
  }),
  dbUpdate: action((state, writeModel) => {
    console.log(writeModel);
    Update(writeModel, state.db);
  }),
  readDayLog: action((state, dataObj) => {
    ReadDayLogs(state.db, dataObj);
  }),
  removeDevice: thunk(async (action, device, { getState }) => {
    console.log(getState().user.uid);
    console.log(device.name);
    const saved = await RemoveDevice(
      getState().db,
      device,
      getState().user.uid
    );
    return saved;
  }),
  updateRemovedDevice: action((state, device) => {
    var devList = [...state.devices];
    const targetIndex = devList.findIndex(
      dev => dev.device_id === device.device_id
    );
    devList.splice(targetIndex, 1);
    state.devices = devList;
  }),
  updateEvent: action((state, eventData) => {
    //TODO: Implement live changes
    console.log(eventData);
  }),
  updatePayPalSubs: action((state, sub) => {
    var paypalSubs = [...state.paypalSubscriptions];
    const targetIndex = paypalSubs.findIndex(
      subData => subData.order_id === sub.order_id
    );
    if (targetIndex >= 0) {
      paypalSubs[targetIndex] = sub;
    } else {
      paypalSubs.push(sub);
    }
    state.paypalSubscriptions = paypalSubs;
  }),
  setLoading: action((state, isLoading) => {
    state.loading = isLoading;
  }),
  unlinkSmartThings: action((state, eventData) => {
    console.log(state.user);
    UnlinkSmartThings(state.db, eventData);
  }),
  linkSmartThings: action((state, eventData) => {
    console.log(state.user);
    LinkSmartThings(state.db, eventData);
  })
};
