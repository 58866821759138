/**
 * Mark message to read/Unread.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/
import * as utils from 'utils';

export async function SetMessageIsRead(db, msgObj) {
  try {
    if (
      db === undefined ||
      msgObj === undefined ||
      msgObj.uid === undefined ||
      msgObj.read === undefined ||
      msgObj.messageId === undefined
    ) {
      console.error('ERROR: Invalid message');
      return;
    }
    var messageRef = db
      .collection(utils.USERS_KEY + '/' + msgObj.uid + '/' + utils.MESSAGE_KEY)
      .doc(msgObj.messageId);
    await messageRef.set(
      { isRead: msgObj.read, readTimestamp: new Date().getTime() },
      { merge: true }
    );
    return;
  } catch (error) {
    console.error(error);
  }
}
