/**
 * Phonenumber.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/

import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useStoreActions } from 'easy-peasy';
import PhonenumberValidator from '../../../components/PhonenumberValidator';
import ToastMessage from '../ToastMessage';
import * as utils from '../../../utils';
import writeModel from '../../../components/DataBase/DataBaseWriteModel';
import useTranslation from '../../../hooks/translations';
import Modal from 'react-bootstrap/Modal';
import Input from 'react-phone-number-input/input';

export default function Phonenumber({ user, setUser, updateCloudPackage }) {
  const translation = useTranslation();
  const [show, setShow] = useState(false);
  const [showModal, setModalShow] = useState(false);
  const handleModalShow = () => setModalShow(true);
  const handleModalClose = () => setModalShow(false);
  const [toastContent, setToastContent] = useState({
    message: '',
    success: true
  });
  const [value, setValue] = useState(
    user.phoneNumber !== undefined ? user.phoneNumber : ''
  );
  const { userUpdate, dbUpdate } = useStoreActions(actions => ({
    userUpdate: actions.userUpdate,
    dbUpdate: actions.dbUpdate
  }));
  const [warningText, setWarningText] = useState('');
  //console.log(user);
  var phone = user.phoneNumber;

  const phonenumberRef = useRef();
  const withoutNumber = (
    <div className="flex-shrink-1 flex-grow-1 w-100">
      <h4 className="text-break">{translation.mobile_phone}</h4>
      <div className="text-truncate w-100">
        <span className="w-100">
          <p
            className="text-break"
            onClick={handleModalShow}
            style={{ cursor: 'pointer', color: '#2294dd' }}
          >
            {translation.add_phonenumber}
          </p>
        </span>
      </div>
      <hr className="bg-400 mb-0 mt-2" />
    </div>
  );
  const withPhonumber = (
    <div className="flex-shrink-1 flex-grow-1 w-100">
      <div className="w-100">
        <div className="container p-0">
          <div className="row flex-grow-0 flex-nowrap panel-item-heading-with-link">
            <div className="col d-flex flex-grow-1">
              <h4 className="text-break">{translation.mobile_phone}</h4>
            </div>
            <div className="col-auto text-end flex-grow-0 flex-shrink-1 justify-content-end flex-wrap">
              <p
                className="text-break"
                onClick={handleModalShow}
                style={{ cursor: 'pointer', color: '#2294dd' }}
              >
                {translation.change}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="text-truncate mb-2">
        <span className="text-break">{phone}</span>
      </div>
      <hr className="bg-400 mb-0 mt-2" />
    </div>
  );
  const state = { content: withoutNumber };

  state.content = PhonenumberValidator(user.phoneNumber)
    ? withPhonumber
    : withoutNumber;

  function updatePhonenumber() {
    const newPhonenumber = phonenumberRef.current.value;
    if (PhonenumberValidator(newPhonenumber)) {
      user.phoneNumber = newPhonenumber;
      userUpdate(user);
      setUser(user);
      updateCloudPackage(user);
      state.content = withPhonumber;
      setToastContent({
        message: translation.changes_saved,
        success: true
      });
      savePhonenumberToDataBase(newPhonenumber, user.uid);
      setModalShow(false);
    } else {
      setWarningText(
        <>
          <p className="text-warning mt-2 mb-0 fs--1">
            {translation.please_enter_the_mobile_number_in_format}
          </p>
          <p className="text-warning mb-0 fs--1">+000 00 000 0000.</p>
        </>
      );
    }
  }

  function showToast(isSaved) {
    console.log(isSaved);
    if (!isSaved) {
      setToastContent({ message: translation.saving_failed, success: false });
    }
    setShow(isSaved);
  }

  async function savePhonenumberToDataBase(phone, uid) {
    try {
      console.log(uid);

      writeModel.path = utils.USERS_KEY;
      writeModel.doc = uid;
      writeModel.data = {
        phone_number: phone !== undefined ? phone : ''
      };
      writeModel.callback = showToast;
      dbUpdate(writeModel);
    } catch (error) {
      console.error(error);
      setToastContent({
        message: translation.saving_failed,
        success: false
      });
      setShow(true);
    }
  }

  return (
    <>
      {state.content}
      <Modal
        show={showModal}
        role="dialog"
        tabIndex="-1"
        id="modal-phone-number"
      >
        <div className="modal-header pe-4 border-0">
          <h4 className="modal-title text-break pe-5">
            {translation.enter_your_mobile_number}
            <br />
          </h4>
          <button
            type="button"
            className="btn-close"
            onClick={handleModalClose}
            aria-label="Close"
          ></button>
        </div>
        <div className="modal-body">
          <label className="form-label">{translation.mobile_number}</label>
          <Input
            type="tel"
            className="form-control"
            placeholder={translation.mobile_format}
            ref={phonenumberRef}
            value={value}
            onChange={setValue}
          />
          {warningText}
        </div>
        <div className="modal-footer border-0 pt-0 pb-4">
          <div className="w-100">
            <button
              className="btn btn-primary w-100 shadow"
              type="button"
              onClick={() => updatePhonenumber()}
            >
              {translation.save}
            </button>
          </div>
        </div>
      </Modal>
      <ToastMessage
        setShow={setShow}
        success={toastContent.success}
        show={show}
        message={toastContent.message}
      />
    </>
  );
}

Phonenumber.propTypes = {
  setShow: PropTypes.func,
  user: PropTypes.object,
  setUser: PropTypes.func,
  updateCloudPackage: PropTypes.func
};
