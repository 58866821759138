/**
 * Safera Product catalog.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/
import * as utils from 'utils';
import Wifi from './Wifi';
import Pro from './Pro';
import CR from './CR';
import FlowHero from './FlowHero';
import WAQM from './WAQM';

export default function ProductCatalog(deviceData) {
  const hwVersion =
    deviceData.hw_version !== undefined
      ? deviceData.hw_version
      : getHWVersion(deviceData.hw_id);
  var product;
  switch (hwVersion) {
    case utils.SENSE_HW_MAJOR['BATTERY']:
    case utils.SENSE_HW_MAJOR['BATTERY_V2']:
      if (
        deviceData.hw_capabilities_id === utils.SENSE_HW_CAPABILITIES['WIFI'] ||
        deviceData.mechanic_variant ===
          utils.SENSE_MECHANICAL_VARIANT['BLACK_WHITE']
      ) {
        product = Wifi(deviceData);
      } else {
        product = Pro(deviceData);
      }
      break;
    case utils.SENSE_HW_MAJOR['CR']:
    case utils.SENSE_HW_MAJOR['CR_V2']:
      product = CR(deviceData);
      break;
    case utils.SENSE_HW_MAJOR['DCKV']:
    case utils.SENSE_HW_MAJOR['DCKV_V2']:
    case utils.SENSE_HW_MAJOR['DCKV_HUB']:
    case utils.SENSE_HW_MAJOR['DCKV_HUB_V2']:
      product = FlowHero(deviceData);
      break;
    case utils.SENSE_HW_MAJOR['WAQM_V1']:
      product = WAQM(deviceData);
      break;
    default:
      console.log(
        hwVersion,
        utils.SENSE_HW_MAJOR['DCKV'],
        utils.SENSE_HW_MAJOR['BATTERY']
      );
      console.log(deviceData);
      product = Wifi(deviceData);
      break;
  }

  function getHWVersion(hwId) {
    var hex = hwId !== undefined ? hwId.toString(16) : 0;
    hex = '0'.repeat(8 - hex.length) + hex;
    var hwIdBytes = [];

    for (var c = 0; c < hex.length; c += 2) {
      hwIdBytes.push(parseInt(hex.substring(c, c + 2), 16));
    }

    return hwIdBytes[1] !== undefined ? hwIdBytes[1] : 0;
  }
  return product;
}
