/**
 * Wizard connect device
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/

import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLink,
  faUserCircle,
  faCheckCircle,
  faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons';
import useTranslation from 'hooks/translations';
import DeviceRegistration from 'components/DataBase/Device/DeviceRegistration';
import DeviceTypeImage from 'Personal/components/Device/DeviceTypeImage';
import * as utils from 'utils';
import ChangeUserCloudPackage from 'components/DataBase/User/ChangeUserCloudPackage';
import ProductCatalog from 'components/Product/ProductCatalog';

export default function WizardConnectDevice({ props }) {
  // console.log(props);
  const propRef = useRef(props);
  const translation = useTranslation();
  const [statusColor, setStatusColor] = useState('bg-secondary');
  const [statusIcon, setStatusIcon] = useState({
    icon: faCheckCircle,
    color: 'text-muted'
  });

  const [nextButton, setNextButton] = useState(
    <button
      className="btn btn-primary text-center mb-3 btn-falcon-default bg-primary text-white border-primary w-100 disabled"
      type="button"
      onClick={props.nextStep}
    >
      {translation.wizard_cloud_next_button}
    </button>
  );
  const [errorTxt, setErrorTxt] = useState('');
  const [title, setTitle] = useState(
    translation.wizard_cloud_connect_device_heading2
  );
  const urlResult = new URLSearchParams(window.location.search);
  var hw = 'wifi';
  const mechVariant = urlResult.has('mech_variant')
    ? parseInt(urlResult.get('mech_variant'))
    : 0;
  const hwMajor = urlResult.has('hw_major')
    ? parseInt(urlResult.get('hw_major'))
    : 0;
  if (urlResult.has('hw_major')) {
    const product = ProductCatalog({
      hw_version: hwMajor,
      mechanic_variant: mechVariant,
      variant: 0,
      hw_id: 0
    });

    hw = DeviceTypeImage({ product: product });
  }

  const [DeviceTypeTxt] = useState(hw);
  //const step = useRef(props.currentStep);

  const deviceInfoRef = useRef({
    deviceId: props.deviceId,
    deviceName: props.form.deviceName,
    config: props.config
  });

  const registerNewDeviceRef = useRef(register);
  //console.log('HWID:', DeviceTypeTxt);
  useEffect(() => {
    console.log('Step:', props.currentStep);
    if (props.currentStep === 3) {
      if (
        propRef.current.form !== undefined &&
        propRef.current.form.deviceName !== undefined &&
        propRef.current.db !== undefined &&
        propRef.current.user !== undefined
      ) {
        console.log('Registering device');
        registerNewDeviceRef.current();
      } else {
        console.error('Error!');
      }
    }
  }, [props.currentStep]);

  function register() {
    deviceInfoRef.current.deviceName = propRef.current.form.deviceName;
    registerNewDevice(
      propRef.current.db,
      deviceInfoRef.current,
      propRef.current.user
    );
  }

  async function registerNewDevice(db, deviceInfo, user) {
    try {
      // console.log(db, deviceInfo, user);
      const retVal = await DeviceRegistration(db, deviceInfo, user);
      //console.log(retVal);
      var status = { ...statusIcon };

      if (retVal.valid && retVal.registrationDone) {
        await new Promise(resolve => setTimeout(resolve, 1000));
        setSuccessfully();
      } else {
        status.icon = faExclamationTriangle;
        setStatusIcon({ icon: faExclamationTriangle, color: 'text-danger' });
        await new Promise(resolve => setTimeout(resolve, 1000));
        setStatusColor('bg-danger');
        errorText(retVal);
      }
    } catch (error) {
      console.error(error);
    }
  }

  function errorText(statusObj) {
    var text = translation.wizard_cloud_connect_device_failed4_p1;
    var btn = (
      <button
        className="btn btn-primary text-center mb-3 btn-falcon-default bg-primary text-white border-primary w-100"
        type="button"
        onClick={props.nextStep}
      >
        {translation.wizard_cloud_next_button}
      </button>
    );

    if (statusObj !== undefined) {
      if (!statusObj.deviceExists) {
        text = (
          <>
            {translation.wizard_cloud_connect_device_failed5_p1}
            <br />
            <a href={translation.support_wifi_error_url}>
              {translation.support_wifi_error_url}
            </a>
          </>
        );
        btn = (
          <button
            className="btn btn-primary text-center mb-3 btn-falcon-default bg-primary text-white border-primary w-100"
            type="button"
            onClick={props.previousStep}
          >
            {translation.wizard_cloud_connect_device_failed_button}
          </button>
        );
      } else if (!statusObj.registrationAvailable) {
        text = translation.wizard_cloud_connect_device_failed2_p1;
        btn = (
          <button
            className="btn btn-primary text-center mb-3 btn-falcon-default bg-primary text-white border-primary w-100"
            type="button"
            onClick={() => window.location.replace('index.html')}
          >
            {translation.wizard_cloud_connect_device_failed2_button}
          </button>
        );
      } else if (!statusObj.deviceAvailable) {
        text = (
          <>
            {translation.wizard_cloud_connect_device_failed1_p1}
            <br />
            <a href={translation.support_wifi_error_url}>
              {translation.support_wifi_error_url}
            </a>
          </>
        );

        btn = (
          <button
            className="btn btn-primary text-center mb-3 btn-falcon-default bg-primary text-white border-primary w-100"
            type="button"
            onClick={props.previousStep}
          >
            {translation.wizard_cloud_connect_device_failed_button}
          </button>
        );
      }
    }
    setErrorTxt(
      <p className="text-black">
        <FontAwesomeIcon
          icon={faExclamationTriangle}
          className="me-2 text-danger"
        />
        {text}
      </p>
    );
    setTitle(translation.wizard_cloud_connect_device_failed_heading);
    setNextButton(btn);
  }

  function setSuccessfully() {
    if (
      hwMajor === utils.SENSE_HW_MAJOR['DCKV'] ||
      hwMajor === utils.SENSE_HW_MAJOR['DCKV_HUB'] ||
      hwMajor === utils.SENSE_HW_MAJOR['DCKV_V2'] ||
      hwMajor === utils.SENSE_HW_MAJOR['DCKV_HUB_V2']
    ) {
      console.log(props.user);
      if (props.user.cloudPackage !== utils.CLOUD_PACKAGE['advanced']) {
        ChangeUserCloudPackage(
          props.db,
          props.user.uid,
          utils.CLOUD_PACKAGE['advanced']
        );
        // var usr = { ...props.user };
        // usr.cloudPackage = utils.CLOUD_PACKAGE['advanced'];
        // props.setUser(usr);
      }
    }
    setStatusColor('bg-success');
    setNextButton(
      <button
        className="btn btn-primary text-center mb-3 btn-falcon-default bg-primary text-white border-primary w-100"
        type="button"
        onClick={props.nextStep}
      >
        {translation.wizard_cloud_next_button}
      </button>
    );
    setTitle(translation.wizard_cloud_connect_device_heading3);
  }

  return (
    <div className="wizard-div-above-container p-0">
      <div className="container wizard-container p-0 shadow-lg">
        <div className="d-flex flex-column wizard-content pb-4">
          <div className="d-flex flex-column wizard-upper-content">
            <div className="container h-100 py-4">
              <div className="row d-flex flex-column justify-content-start align-items-center h-100">
                <div className="col d-flex justify-content-center align-items-center mb-2">
                  <FontAwesomeIcon
                    icon={faLink}
                    className="wizard-icon fa-lg me-2 text-light bg-dark p-1 rounded-pill"
                  />
                  <h6 className="text-start d-inline-flex mb-0">
                    {translation.wizard_cloud_connect_device_step}
                  </h6>
                </div>
                <div className="col d-flex flex-grow-0 justify-content-center">
                  <h3 className="text-center">{title}</h3>
                </div>
              </div>
            </div>
          </div>

          <div className="d-flex flex-grow-1 align-items-start wizard-lower-content">
            <div className="row d-flex flex-column align-items-start w-100 m-0 h-100">
              <div className="col d-inline-flex flex-column flex-grow-1 justify-content-start h-100">
                <div className="row">
                  <div className="col d-flex flex-column justify-content-center align-items-center">
                    <div className="card card-user-account">
                      <div className="card-body px-3">
                        <div className="d-flex align-items-center w-100 h-100">
                          <div className="me-2">
                            <FontAwesomeIcon
                              icon={faUserCircle}
                              className="text-dark fa-lg"
                            />
                          </div>
                          <div className="text-truncate">
                            <h5 className="text-truncate mb-0">
                              {props.user !== undefined ? props.user.email : ''}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-2 mb-0 pb-2">
                      <FontAwesomeIcon
                        icon={faLink}
                        className={`wizard-icon fa-lg text-light ${statusColor} p-1 rounded-pill`}
                      />
                    </div>
                    <div className="card wizard-device shadow w-100">
                      <div
                        className={`card-body d-flex align-items-center card-device-body-wizard card-body-sense-${DeviceTypeTxt} px-3 rounded`}
                      >
                        <div className="w-100">
                          <div className="container p-0 w-100">
                            <div className="row d-flex flex-nowrap m-0 w-100">
                              <div className="col-auto d-flex justify-content-center align-items-center p-0"></div>
                              <div className="col-auto d-flex flex-grow-1 align-items-center p-0 pe-3">
                                <h5 className="device-cards-name m-0">
                                  {props.form.deviceName !== undefined
                                    ? props.form.deviceName
                                    : ''}
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col">
                    <div></div>
                  </div>
                </div>

                <div className="row mb-3">
                  <div className="col">
                    <div></div>
                  </div>
                </div>
                {errorTxt}
                <div className="row mt-3">
                  <div className="col">{nextButton}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

WizardConnectDevice.propTypes = {
  props: PropTypes.object,
  nextStep: PropTypes.func,
  previousStep: PropTypes.func,
  currentStep: PropTypes.func,
  form: PropTypes.object,
  user: PropTypes.object,
  deviceId: PropTypes.string,
  db: PropTypes.func,
  deviceInfo: PropTypes.object,
  config: PropTypes.object
};
