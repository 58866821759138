/**
 * Wizard new user
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import useTranslation from 'hooks/translations';
import SetUserPolicy from 'components/DataBase/SetUserPolicy';

export default function WizardNewUser({ props }) {
  console.log(props);
  const translation = useTranslation();
  const [disable, setDisable] = useState('disabled');
  async function handleChange({ target }) {
    if (target.checked) {
      setDisable('');
    } else {
      setDisable('disabled');
    }
    await SetUserPolicy(props.db, props.user.uid, target.checked);
  }
  return (
    <div className="wizard-div-above-container p-0">
      <div className="container wizard-container p-0 shadow-lg">
        <div className="d-flex flex-column wizard-content pb-4">
          <div className="d-flex flex-column wizard-upper-content">
            <div className="container h-100 py-4">
              <div className="row d-flex flex-column justify-content-start align-items-center h-100">
                <div className="col d-flex justify-content-center align-items-center mb-2">
                  <i className="fas fa-user-circle fs-1 me-2"></i>
                  <h6 className="text-start d-inline-flex mb-0">
                    {translation.wizard_cloud_log_in_step}
                  </h6>
                </div>
                <div className="col d-flex flex-grow-0 justify-content-center">
                  <h3 className="text-center">
                    {translation.wizard_cloud_new_account_heading}
                  </h3>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex flex-grow-1 align-items-start wizard-lower-content">
            <div className="row d-flex flex-column align-items-start w-100 m-0 h-100">
              <div className="col d-inline-flex flex-column flex-grow-1 justify-content-start h-100">
                <div className="row mb-3">
                  <div className="col d-flex justify-content-center">
                    <div className="card card-user-account">
                      <div className="card-body px-3">
                        <div className="d-flex align-items-center w-100 h-100">
                          <div className="me-2">
                            <i className="fa fa-user-circle text-dark fa-lg"></i>
                          </div>
                          <div className="text-truncate">
                            <h5 className="text-truncate mb-0">
                              {props.user !== undefined ? props.user.email : ''}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col">
                    <div>
                      <div className="form-check d-flex align-items-start form-switch mb-2">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="formCheck-2"
                          onChange={handleChange}
                        />
                        <label
                          className="form-check-label mt-1 text-black fs--1 lh-base"
                          htmlFor="formCheck-2"
                        >
                          {translation.i_agree_to_the_terms_and_policy}
                          {'. '}
                          <a
                            className="text-break"
                            href={translation.terms_and_policy_url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <br />
                            {translation.open}
                          </a>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-1">
                  <div className="col">
                    <button
                      className={`btn btn-primary text-center mb-3 btn-falcon-default bg-primary text-white border-primary w-100 ${disable}`}
                      type="button"
                      onClick={props.nextStep}
                    >
                      {translation.wizard_cloud_next_button}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

WizardNewUser.propTypes = {
  props: PropTypes.object,
  user: PropTypes.object,
  nextStep: PropTypes.func,
  db: PropTypes.func
};
