/**
 * Change user cloud package.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/

import * as utils from 'utils';

export default async function ChangeUserCloudPackage(db, uid, cloudPackage) {
  try {
    if (
      db === undefined ||
      uid === undefined ||
      uid === '' ||
      cloudPackage === undefined
    ) {
      console.error('Invalid Cloud package information!');
      return;
    }
    const usrRef = db.collection(utils.USERS_KEY).doc(uid);

    await usrRef.set(
      {
        cloud_package: cloudPackage
      },
      { merge: true }
    );

    return;
  } catch (error) {
    console.error(error);
    return;
  }
}
