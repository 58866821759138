/**
 * Add device to user info
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/
import * as utils from '../../../utils';
import CheckAccess from '../CheckAccess';
/**
 *
 * @param {*} db
 * @param {deviceId: deviceId, uid: uid, isGuest: isGuest, deviceName:deviceName} deviceObj
 * @returns
 */
export async function AddNewUserDevice(db, deviceObj) {
  if (
    deviceObj === undefined ||
    deviceObj.deviceId === undefined ||
    deviceObj.uid === undefined ||
    deviceObj.isGuest === undefined ||
    deviceObj.deviceName === undefined
  ) {
    console.error('Device details missing!');
    return false;
  }
  const access = await CheckAccess(db, deviceObj.deviceId, deviceObj.uid);
  if (!access.isValid) {
    console.log('Device read: Access denied!');
    return false;
  }

  if (!deviceObj.isGuest) {
    if (!access.isOwner) {
      deviceObj.isGuest = true;
    }
  }

  try {
    const usrDevRef = db
      .collection(
        utils.USERS_KEY + '/' + deviceObj.uid + '/' + utils.DEVICE_KEY
      )
      .doc(deviceObj.deviceId);
    await usrDevRef.set({
      device_id: deviceObj.deviceId,
      isGuest: deviceObj.isGuest,
      isRegistered: true,
      name: deviceObj.deviceName
    });
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
}
