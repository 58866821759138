/**
 * Safera Pro.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2025. All rights reserved. Safera Oy
 **/

import * as utils from 'utils';

export default function Pro(deviceData) {
  return {
    id: utils.SAFERA_PRODUCTS['PRO'],
    mechanicVariant: deviceData.mechanic_variant,
    variant: 0
  };
}
