/**
 * Policy page content.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import useTranslation from 'hooks/translations';
import SetUserPolicy from 'components/DataBase/SetUserPolicy';
import TagManager from 'react-gtm-module';
//import * as utils from 'utils';

export default function PolicyPage({ db, user }) {
  const uidRef = useRef(user.uid);
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        userId: uidRef.current,
        userProject: 'Personal',
        page: 'Policy page'
      },
      dataLayerName: 'SaferaDataLayer'
    });
  }, []);

  const translation = useTranslation();
  const [disable, setDisable] = useState('disabled');

  async function handleChange({ target }) {
    if (target.checked) {
      setDisable('');
    } else {
      setDisable('disabled');
    }
    await SetUserPolicy(db, user.uid, target.checked);
  }
  return (
    <div className="d-flex flex-column device-settings-page">
      <div className="container d-flex flex-fill justify-content-center align-items-center align-items-sm-center log-in-container">
        <div className="row d-flex justify-content-center log-in-panel bg-100 rounded-3 shadow-lg">
          <div className="col-auto d-flex justify-content-center p-3 pt-5 pt-sm-4 pb-0 pb-sm-4 w-100 w-sm-auto">
            <div className="row d-flex flex-column align-items-center log-in-welcome-panel h-25 m-0 h-md-100 w-100">
              <div className="col-6 d-flex flex-column flex-fill justify-content-between align-items-center w-100">
                <div className="row w-100 mb-3">
                  <div className="col p-0">
                    <div className="d-flex justify-content-center justify-content-sm-start mb-2">
                      <img
                        alt="Safera logo"
                        className="log-in-logo"
                        src="img/Safera-logo-cloud.svg"
                      />
                    </div>
                  </div>
                </div>
                <div className="row d-inline-flex flex-column flex-fill m-0 w-auto">
                  <div className="col d-flex align-items-end w-auto p-0">
                    <h2 className="text-nowrap text-center text-sm-start w-auto mb-1">
                      {translation.short_brand_name}
                    </h2>
                  </div>
                </div>
                <div className="row d-flex flex-fill w-100 mb-sm-4 mb-0">
                  <div className="col w-100 p-0">
                    <h5 className="text-center text-sm-start text-primary w-100">
                      {translation.welcome}
                    </h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col d-flex flex-column justify-content-center align-items-center log-in-continue-with-panel p-3">
            <div className="row w-100">
              <div className="col w-100">
                <hr className="horizontal-divider w-100" />
              </div>
            </div>
            <div className="row d-flex w-100 my-3">
              <div className="col d-flex flex-column justify-content-end align-items-start">
                <h3 className="text-center w-100">
                  {translation.terms_of_service}
                </h3>
              </div>
            </div>
            <div className="row d-flex flex-column w-100 m-0">
              <div className="col d-inline-flex flex-column flex-grow-1 justify-content-center">
                <div className="col">
                  <div>
                    <div className="form-check d-flex align-items-start form-switch mb-2">
                      <input
                        className="form-check-input mt-2"
                        type="checkbox"
                        id="formCheck-2"
                        onChange={handleChange}
                      />
                      <label
                        className="form-check-label mt-1 text-black fs--1 lh-base"
                        htmlFor="formCheck-2"
                      >
                        {translation.i_agree_to_the_terms_and_policy}
                        {'. '}
                        <p>
                          <a
                            className="text-break"
                            href={translation.terms_url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <br />
                            {translation.open} {translation.terms_of_service}
                          </a>

                          <a
                            className="text-break"
                            href={translation.terms_and_policy_url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <br />
                            {translation.open} {translation.privacy_policy}
                          </a>
                        </p>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mt-1">
              <div className="col">
                <button
                  className={`btn btn-primary text-center mb-3 btn-falcon-default bg-primary text-white border-primary w-100 ${disable}`}
                  type="button"
                  onClick={() => window.location.assign('/index.html')}
                >
                  {translation.wizard_cloud_next_button}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
PolicyPage.propTypes = {
  user: PropTypes.object,
  db: PropTypes.object
};
