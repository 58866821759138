/**
 * Utils.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/
export const RECORD_VERSION_NAME = 'record_version';
export const MEAS_COUNT_30MIN = 'meascount_30min';
export const TEMP_AMBIENT_NAME = 'temp_ambient';
export const TEMP_AMBIENT_30MIN_NAME = 'temp_ambient_30min';
export const RH_30MIN_NAME = 'rh_30min';
export const ECO2_30MIN_NAME = 'eco2_30min';
export const TVOC_30MIN_NAME = 'tvoc_30min';
export const PM25_30MIN_NAME = 'pm25_30min';
export const AQI_FINAL_NAME = 'aqi_final_30min';
export const SENSOR_TIMESTAMP_NAME = 'sensor_timestamp';
export const SENSOR_DATA_NAME = 'sensor_data';
export const IS_ALARM_NAME = 'is_alarm';
export const IS_POWEROFF_NAME = 'is_poweroff';
export const IS_COOKING_NAME = 'is_cooking';
export const BATTERY_NAME = 'battery_voltage_mean';
export const TOTAL_ALARM_COUNT_NAME = 'total_alarm_count';
export const TOTAL_POWER_OFF_COUNT_NAME = 'total_power_off_count';
export const TOTAL_COOKING_COUNT_NAME = 'total_cooking_count';
export const COOKING_KWH_NAME = 'cooking_kwh';
export const ALARM_ON_VALUE = 500;
export const TEMP_MAX = 'temp_ambient_max';
export const TEMP_MAX_TIME_NAME = 'temp_ambient_max_time';
export const TEMP_MIN = 'temp_ambient_min';
export const TEMP_MIN_TIME_NAME = 'temp_ambient_min_time';
export const TEMP_IR_MAX_NAME = 'temp_ir_max';
export const TEMP_IR_MAX_TIME_NAME = 'temp_ir_max_time';
export const TEMP_IR_MIN_NAME = 'temp_ir_min';
export const TEMP_IR_MIN_TIME_NAME = 'temp_ir_min_time';
export const RH_MAX = 'rh_max';
export const RH_MAX_TIME_NAME = 'rh_max_time';
export const RH_MIN = 'rh_min';
export const RH_MIN_TIME_NAME = 'rh_min_time';
export const PM25_MAX_NAME = 'pm25_short_peak_max';
export const PM25_MAX_TIME_NAME = 'pm25_short_peak_time';
export const TVOC_MAX = 'tvoc_short_peak_max';
export const TVOC_MAX_TIME_NAME = 'tvoc_short_peak_time';
export const ECO2_MAX = 'eco2_short_peak_max';
export const ECO2_MAX_TIME_NAME = 'eco2_short_peak_time';
export const PCU_STATUS_NAME = 'pcu_status';
export const MIU_FAILURE_NAME = 'miu_failure_code_sum';
export const PCU_FAILURE_NAME = 'pcu_failure_code_sum';
export const UNIX_EPOCH_NAME = 'unix_epoch';
export const MIU_STOVE_TYPE_NAME = 'miu_stovetype';
export const MIU_INST_HEIGHT_NAME = 'miu_inst_height_cm';
export const MIU_INST_POS_NAME = 'miu_inst_pos';
export const MIU_TIMEZONE_NAME = 'timezone_sec';

// V6 Daylog
export const RECORD_10MIN_VERSION = 6;
export const RH_NAME = 'rh';
export const ECO2_NAME = 'eco2';
export const TVOC_NAME = 'tvoc';
export const PM25_NAME = 'pm25';
export const BATTERY_VOLTAGE_NAME = 'battery_voltage';
export const BATTERY_P_NAME = 'battery_p';
export const BATTERY_PSU_VOLTAGE_NAME = 'battery_psu_voltage';
export const PCU_COOKING_KWH_NAME = 'pcu_cooking_kwh';
export const TEMP_IR_NAME = 'temp_ir'; // Stove surface temperature in Celcius = temp_ir.data/100 - 50
export const PM1_NAME = 'pm1';
export const PM10_NAME = 'pm10';
export const PCU_POWER_NAME = 'pcu_power'; // watt-seconds = pcu_power.data * 20
export const LIGHT_NAME = 'light'; // use as is, no unit yet
export const SOUND_AVG_NAME = 'sound_avg'; // decibes = (sound_avg.data/2) + 30
export const SOUND_DETECTION_NAME = 'sound_detections'; // kitchen sound events detection rate: 0...100%
export const AREA_ACTIVITY_NAME = 'area_activity'; // sensor area human activity: 0...100%
export const VENT_CONTROL_NAME = 'vent_control'; // main ventilation control command: 0...100%
export const MOTOR1_SPEED_NAME = 'motor1_speed'; // motor 1 speed command: 0...100%
export const MOTOR2_SPEED_NAME = 'motor2_speed'; // motor 2 speed command: 0...100%
export const MOTOR1_POWER_NAME = 'motor1_power'; // motor 1 power in watts = motor1_power.data * 10
export const MOTOR2_POWER_NAME = 'motor2_power'; // motor 2 power in watts = motor2_power.data * 10
export const VALVE_STATE_NAME = 'valve_state'; // valve open state: 0...100%
export const AUX_LOG_NAME = 'aux_log'; // one extra logging slot for AUX / future use
export const IS_HUMAN_PRESENCE = 'isHumanPresence';
export const IS_OTHER_APPLIANCE = 'isOtherAppliance';
export const HUMAN_PRESENCE_MAP_NAME = 'human_presence_count_and_timemap'; // human presence detected around the sensor (may be other activity than cooking also)
export const HUMAN_PRESENCE_COUNT_NAME = 'humanPresenceCount';
export const COOKIN_MAP_NAME = 'cooking_count_and_timemap'; // triggered after 2..3 min of cooking (and cooking confirm detection from AI)
export const COOKIN_COUNT_NAME = 'cookingCount';
export const OTHER_APPLIANCE_MAP_NAME = 'other_appliance_count_and_timemap'; // other appliance activity detected (for example dishwasher active, or over releasing steam/smoke)
export const OTHER_APPLIANCE_COUNT_NAME = 'otherApplianceCount'; // other appliance activity detected (for example dishwasher active, or over releasing steam/smoke)
export const ALARM_MAP_NAME = 'alarm_count_and_timemap'; // stove guard triggered, alarm beep starts
export const ALARM_COUNT_NAME = 'alarmCount'; // stove guard triggered, alarm beep starts
export const POWEROFF_MAP_NAME = 'poweroff_count_and_timemap'; // stove guard with PCU: triggered in 15s from alarm (PCU power cut) or without PCU: triggered in 15s just to see if the user was not present and did not cancel the alarm quickly
export const POWEROFF_COUNT_NAME = 'poweroffCount'; // stove guard with PCU: triggered in 15s from alarm (PCU power cut) or without PCU: triggered in 15s just to see if the user was not present and did not cancel the alarm quickly

export const AMBIENT_COOKING_NAME = 'ambient_cooking';
export const AMBIENT_ALARM_NAME = 'ambient_alarm';

export const USERS_KEY = 'users';
export const DEVICE_KEY = 'device';
export const MESSAGE_KEY = 'message';
export const GUEST_CODE_KEY = 'guest_code';
export const PARTNERS_KEY = 'partners';
export const EVENT_KEY = 'event';
export const GROUP_KEY = 'group';
export const PACKAGE_KEY = 'package';
export const SMS_KEY = 'sms';
export const SMS_POOL_KEY = 'pool';
export const SMS_SUBSCRIPTION_KEY = 'sms_subscriptions';
export const SMS_SUBSCRIPTION_HISTORY_KEY = 'sms_subscriptions_history';
export const HISTORY_KEY = 'history';
export const NOTIFICATIONS_KEY = 'notifications';
export const PHONE_NUMBER_KEY = 'phone';
export const EMAIL_KEY = 'email';
export const COMPANY_KEY = 'company';
export const HOME_CONNECT_KEY = 'home_connect';
export const COMMENT_KEY = 'comment';
export const DAYLOG_KEY = 'daylog';
export const REPORT_KEY = 'report';
export const SETUP_KEY = 'setup';
export const SMARTTHINGS_COLLECTION_KEY = 'smartthings';
export const SENSOR_DATA_KEY = 'sensor_data';

export const COOKING_ALARM_DOC = 'cooking_alarm';
export const ATTENTION_NOTICED_DOC = 'attention_noticed';
export const BEHAVIOUR_ANALYSIS_DOC = 'behaviour_analysis';

export const SESSION_TIMEOUT = 43200000; //ms
export const MAX_30_MIN_SLOTS = 48;
export const START_YEAR = 2020;
export const TIME_24H = 86400000; // ms
export const TIME_1H = 3600000; // ms
export const FIRST_SENSOR_BATCH_SIZE = 12;
export const HERE_MAP_API_KEY = 'fhtxNqUgTfpNY1DTPVIKf71TtrS8ZTumGapvYPtS45M';

export const SENSE_HW_CAPABILITIES = {
  BT: 1,
  WIFI: 67,
  PRO: 127,
  CR_PRO: 255
};

export const SENSE_HW_MAJOR = {
  BATTERY: 2,
  CR: 3,
  DCKV: 5,
  DCKV_HUB: 6,
  CR_V2: 7,
  DCKV_V2: 8,
  DCKV_HUB_V2: 9,
  BATTERY_V2: 10,
  WAQM_V1: 12 // Mirka waqm, subversion 1
};

export const SENSE_MECHANICAL_VARIANT = {
  BLACK_BLACK: 0,
  BLACK_WHITE: 1
};

export const SAFERA_PRODUCTS = {
  BT: 1,
  WIFI: 2,
  PRO: 3,
  CR: 4,
  FLOWHERO: 5,
  WAQM: 6
};

export const WIFI_IVAL = {
  WIFI_IVAL_1: 0, // interval: 1 days/cycles: no skipping, run each cycle / day
  WIFI_IVAL_2: 1, // interval: 2 days/cycles: skip 1 cycle (run every other day)
  WIFI_IVAL_3: 2, // interval: 3 days/cycles: skip 2 cycles
  WIFI_IVAL_4: 3, // interval: 4 days/cycles: skip 3 cycles
  WIFI_IVAL_7: 4, // interval: 7 days/cycles: skip 6 cycles
  WIFI_IVAL_10: 5, // interval: 10 days/cycles: skip 9 cycles
  WIFI_IVAL_14: 6 // interval: 14 days/cycles: skip 13 cycles
};

export const SENSE_HW_CAPABILITY_BITS = {
  HWCAPS_VALID: 0, // if this bit is 0, the byte is not valid and we must assume that this is from old Sense Pro
  HWCAPS_WIFI: 1, // Has Wi-Fi capability (ESP32 or ESP8266)
  HWCAPS_LPCV1: 2, // Has PM2.5 measurement capability
  HWCAPS_ZMOD: 3, // Has gas sensor (tVOC & eCO2)
  HWCAPS_TOF: 4, // Has automatic installation height (tof measurement) capability
  HWCAPS_MIC: 5, // Has sound microphone capability
  HWCAPS_EMF: 6, // Has electromagnetic field measurement capability
  HWCAPS_HOOD_CTRL: 7, // Has cooker hood control capability
  HWCAPS_EXC1_REMOVED: 8, // NOTE this is an inverse flag for historical reasons! mark high if EXC1 sensor is NOT installed on PCB.
  HWCAPS_EXT_FLASH_REMOVED: 9 // NOTE this is an inverse flag for historical reasons! mark high if External SPI Flash is NOT installed on PCB.
};

export const VIEW_TYPE = {
  day: 1,
  range: 2,
  raw: 3,
  status: 12,
  shop: 5,
  settings: 6,
  care: 7,
  admin: 8,
  api: 9,
  homeconnect: 10,
  device: 11,
  card: 4
};
export const MESSAGE_TYPE = {
  share: 1,
  info: 2,
  ack: 3,
  share_done: 4,
  share_group: 5,
  share_group_done: 6,
  transfer_ownership: 7,
  transfer_ownership_done: 8,
  transfer_group_ownership: 9,
  transfer_group_ownership_done: 10,
  new_chat_message: 11
};

export const CLOUD_PACKAGE = {
  advanced: 0,
  care: 1,
  care_plus: 2,
  care_expired: 3,
  personal: 4,
  personal_pro: 5
};

export const STOVE_TYPE = {
  RT_UNKNOWN: 0,
  RT_OVEN: 1,
  RT_CERAMIC: 2,
  RT_INDUCTION: 3,
  RT_IRON: 4,
  RT_IRON_COIL: 5,
  RT_GAS: 6,
  DCKV_INDUCTION_HOB: 10,
  DCKV_CERAMIC_HOB: 11,
  DCKV_IRON_HOB: 12,
  DCKV_GAS_HOB: 13,
  DCKV_ELECTRIC_FRY_TOP: 20,
  DCKV_GAS_FRY_TOP: 21,
  DCKV_PRESS_GRILL: 30,
  DCKV_ELECTRIC_WOK: 40,
  DCKV_GAS_WOK: 41,
  DCKV_ELECTRIC_TEPPANYAKI: 42,
  DCKV_GAS_TEPPANYAKI: 43,
  DCKV_ELECTRIC_GRILL: 50,
  DCKV_GAS_GRILL: 51,
  DCKV_CHARCOAL_GRILL: 52,
  DCKV_DEEP_FRYER: 60,
  DCKV_OVEN: 70,
  DCKV_PRESSURE_OVEN: 71,
  DCKV_DISHWASHER: 80
};

export const PERSONAL_PAGES = {
  DEVICE_LIST: 1,
  DEVICE_SETTINGS: 2,
  ACCOUNT_SETTINGS: 3
};

export const ADVANCED_PAGES = {
  DEVICE_LIST: 1,
  MESSAGE: 2
};

export const PERSONAL_DEVICE_SETTINGS_SUB_PAGE = {
  NOTIFICATION_SETTINGS: 1,
  HISTORY: 2,
  DEVICE_INFO: 3
};

export const PERSONAL_ACCOUNT_SETTINGS_SUB_PAGE = {
  CONTACT: 1,
  ACCOUT_SETTINGS: 2,
  PAYMENT: 3,
  POLICY: 4
};
export const CONFIG_BANK_ID = 0;
export const HUB_BANK_ID = 1;
export const ANALYSIS_BANK_ID = 2;
export const NAME_BANK_ID = 3;
export const VENTILATION_CAL_0_BANK_ID = 200;
export const VENTILATION_CAL_1_BANK_ID = 201;
export const VENTILATION_CAL_2_BANK_ID = 202;
export const VENTILATION_CAL_3_BANK_ID = 203;
export const VENTILATION_CAL_4_BANK_ID = 204;
export const VENTILATION_CAL_5_BANK_ID = 205;
export const VENTILATION_CAL_6_BANK_ID = 206;
export const VENTILATION_CAL_7_BANK_ID = 207;
export const VENTILATION_CAL_8_BANK_ID = 208;
export const VENTILATION_CAL_9_BANK_ID = 209;
export const NODE_BANK_ID = 10001;

export const SAFERA_FLOWHERO_VARIANT = {
  DCKV: 1,
  HUB: 2,
  NODE: 3,
  NODE_HUB: 4
};

// safera client-id: AQPLIYvwKk-BhvLWayBSPE4qRshWiD7fFWJNKuMsscZBUsC0-p5KMqhjXZRduTQQ8hFD0EfAhg6an4tp
// Safera plan_id: 'P-9XY1967319883150CMKBV6EA'
export const PAYPAL_KEY =
  'AQPLIYvwKk-BhvLWayBSPE4qRshWiD7fFWJNKuMsscZBUsC0-p5KMqhjXZRduTQQ8hFD0EfAhg6an4tp';
export const PAYPAL_PLAN_ID = 'P-9XY1967319883150CMKBV6EA';

// Sandbox
// export const PAYPAL_KEY =
//   'AXamSun9cdBeqohASELtaecra94gfJU5tgqm9yyJHADpCzB2T5CNhZvDHKwht01rM4gDh-7uvyNui2ui';
//export const PAYPAL_PLAN_ID = 'P-1GD685062Y699523FMHKDSOY'; // month test plan
// export const PAYPAL_PLAN_ID = 'P-2G365585S2494212WMHPMEDA'; // day test plan

export const PAYPAL_SUBSCRIPTION_APPROVAL_PENDING = 'APPROVAL_PENDING';
export const PAYPAL_SUBSCRIPTION_APPROVED = 'APPROVED';
export const PAYPAL_SUBSCRIPTION_ACTIVE = 'ACTIVE';
export const PAYPAL_SUBSCRIPTION_SUSPENDED = 'SUSPENDED';
export const PAYPAL_SUBSCRIPTION_CANCELLED = 'CANCELLED';
export const PAYPAL_SUBSCRIPTION_EXPIRED = 'EXPIRED';

export const PAYPAL_CANCEL_SUBSCRIPTION = 'cancel';
export const PAYPAL_SUSPEND_SUBCRIPTION = 'suspend';
export const PAYPAL_ACTIVATE_SUBCRIPTION = 'activate';
export const PAYPAL_READ_SUBCRIPTION = 'read';
export const PAYPAL_SELF_SUBSCRIPTION = 'self';

export const SMS_SUBSCRIPTION_PRICE = '1.99 €';

export const HOME_CONNECT_TYPE = { default: 1 };

export const SELECT_DEVICE_TITLE = 'Select device ';
export const PIKADAY_STYLE = { no: 0, day: 1, range: 2 };

export const SMARTTHINGS_ACTIVATED = 'activated';
export const SMARTTHINGS_REGISTERED = 'registered';
export const SMARTTHINGS_REMOVED = 'removed';

// Event code
export const WE_NONE = 0;
export const WE_STOVE_ALARM = 1;
export const WE_STOVE_ALARM_PCU_DISCONNECTED = 2;
export const WE_COOKING_ACTION_START = 3;
export const WE_COOKING_ACTION_STOP = 4;
export const WE_AIR_QUALITY_ALARM = 5;
export const WE_OK_BUTTON_PRESS = 6;
export const WE_1_BUTTON_PRESS = 7;
export const WE_2_BUTTON_PRESS = 8;
export const WE_FACTORY_RESET_DONE = 9; // This Sensor Unit is going to forget everything, please reset my Cloud and release me for new claiming
export const WE_CONNECTION_TEST = 10; // This is sent always when a Sensor Unit joins a new WiFi network on the field. If never started, a claim process should be started now. (parameter could be the signal strenght)
export const WE_STOVE_NOT_USED = 11; // Alert for 'stove unused for too long' according to settings
export const WE_STOVE_USAGE_STRANGE = 12; // Alert for strange use pattern ie. middle of the night
export const WE_BATTERY_LOW = 13; // Sudden critically low battery level that should be reported immediately (for some reason, cannot wait until midnight daylog)
export const WE_CRITICAL_ERROR = 14; // Critical error in Sensor Unit or PCU error. param = miu_error_code | (pcu_error_code<<16)
export const WE_PCU_WATER_LEAKAGE_ALARM = 15; // PCU Water Leakage Sensor detects moisture
export const WE_CTU_FIRE_EXTINGUISH_ALARM = 16; // CTU has been used for fire extinguishing
export const WE_FACTORY_CONNECTION_TEST = 17; // This is sent always when a Sensor Unit tests cloud connectivity at the factory. Claim process should not be started.
export const WE_AIR_QUALITY_ALARM_CLEARED = 18; // should occur some time after any Air Quality Alarm to mark that the air quality has returned to acceptable level at all aspects
export const WE_CRITICAL_ERROR_PCU_SHUTDOWN = 19; // PCU power shut down due to critical (MIU) error. param = miu_error_code | (pcu_error_code<<16)
export const WE_CRITICAL_ERROR_CLEARED = 20; // triggers if a WE_CRITICAL_ERROR ends
export const WE_STOVE_ALARM_CLEARED = 21; // OK button pressed after an stove alarm - alarm cleared and if a PCU is connected, power is resumed
export const WE_PCU_WATER_LEAKAGE_ALARM_CLEARED = 22; // PCU Water Leakage alarm cleared: OK button pressed and sensor is detected as dry
export const WE_DEVICE_REBOOT = 23; // Device has rebooted and makes a test connection for checking clock/calendard and internet connectivity (params = device information bytes)
export const WE_CONNECTION_TEST_MANUAL = 24; // Manually triggered connection test. This is a separate event and it signals especially the case where the user manually requests a connection test (from the App).
export const WE_AIR_QUALITY_ALARM_TEMP_LOW = 100; // Alert for acutely low air temp: param = air temp C + 100
export const WE_AIR_QUALITY_ALARM_TEMP_HIGH = 101; // Alert for acutely high air temp: param = air temp C + 100
export const WE_AIR_QUALITY_ALARM_HUM_LOW = 102; // Alert for acutely low humidity: param = RH percentage
export const WE_AIR_QUALITY_ALARM_HUM_HIGH = 103; // Alert for acutely high humidity: param = RH percentage
export const WE_AIR_QUALITY_ALARM_PM25_HIGH = 104; // Alert for acutely high pm2.5: param = ug/m3
export const WE_AIR_QUALITY_ALARM_ECO2_HIGH = 105; // Alert for acutely high eCO2: param = ppm
export const WE_AIR_QUALITY_ALARM_TVOC_HIGH = 106; // Alert for acutely high tvoc: param = ug/m3
export const WE_AIR_QUALITY_ALARM_AQI_HIGH = 107; // Alert for acutely high combined Air Quality Index: param = AQI
export const WE_DAYLOG_READ = 0xff00;
export const WE_OFFLINE = 0xff01;
export const WE_COLLECTIONS_OF_EVENT = 0xff02;
export const WE_SMS_SUBSCRIPTION_TRIAL_START = 0xff03; // id = uid, param = orderId
export const WE_SMS_SUBSCRIPTION_CANCELLED = 0xff04; // id = uid, param = orderId
export const WE_SMS_SUBSCRIPTION_PAYMENT_DONE = 0xff05; // id = uid, param = orderId, p2 = amount
export const WE_SMS_SUBSCRIPTION_PAYMENT_FAILED = 0xff06; // id = uid, param = orderId
export const WE_SMS_SUBSCRIPTION_SERVICE_ENDED = 0xff07; // The SMS alarm service fee could not be charged // id = uid, param = orderId
export const WE_SMS_SUBSCRIPTION_SERVICE_RESUMED = 0xff08; // id = uid, param = orderId
export const WE_PHONE_NUMBER_UPDATED = 0xff0a; // id = uid, param = phone number, p2 = email address, p3 = language
export const WE_DEVICE_REGISTERED = 0xff0b; // id = deviceId, param = email address, p2 = device name
export const WE_WELCOME = 0xff0c; // id = uid, param = phone number, p2 = email address, p3 = language
export const WE_MERGE_DEVICE = 0xff0d; // param = source deviceId, p2 = firstDaylogEpoch, p3 = lastDaylogEpoch

export const COLOR_MUTED = '#b7bfd5';
export const COLOR_SUCCESS = '#269b43';

export const TVOC_ALARM_LIMIT = 3000;
export const ECO2_ALARM_LIMIT = 1200;
export const PM25_ALARM_LIMIT = 12;
export const PM25_ALARM_HI_LIMIT = 100;
export const TEMP_ALARM_MAX_LIMIT = 27;
export const TEMP_ALARM_MIN_LIMIT = 21;
export const RH_ALARM_MAX_LIMIT = 62;
export const RH_ALARM_MIN_LIMIT = 37;

export const DEFAULT_GROUP_NAME = 'defaultGroup';

export const BATTERY_WARNING_LEVEL = 30;
export const BATTERY_ALERT_LEVEL = 20;

export function getFormatedTime(timestamp, isPreviousDay) {
  //console.log("timestamp", timestamp);
  var _date = new Date(timestamp * 1000);
  if (isNaN(_date)) {
    return;
  }
  if (isPreviousDay) {
    _date.setTime(_date.getTime() - 24 * 60 * 60 * 1000);
  }
  return (
    _date.getFullYear() +
    '-' +
    ('0' + (_date.getMonth() + 1)).slice(-2) +
    '-' +
    ('0' + _date.getDate()).slice(-2)
  );
}
