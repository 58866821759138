/**
 * Read device sensor data.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2023. All rights reserved. Safera Oy
 **/

import ReadDayLogDocs from 'components/DataBase/Device/ReadDayLogDocs';
import * as utils from 'utils';
import GetDayObject from './GetDayObject';
import CollectSensorData from './CollectSensorData';

export default async function ReadDayLogs(db, dataObj) {
  try {
    var daylogsObj;

    const dayLogDocs = await ReadDayLogDocs(db, dataObj);
    //console.log(dayLogDocs);
    if (dayLogDocs.docs === undefined) {
      return {};
    }
    for (const doc of dayLogDocs.docs) {
      if (daylogsObj === undefined) {
        daylogsObj = {};
      }

      if (doc.data().unix_epoch !== 0) {
        var daylogDate = utils.getFormatedTime(doc.data().unix_epoch, true);
        if (doc.data().record_version === utils.RECORD_10MIN_VERSION) {
          daylogDate = doc.id;
        }

        daylogsObj[daylogDate] = GetDayObject(doc);
        daylogsObj[daylogDate]['sensor_data'] = CollectSensorData(doc.data());
      }
    }
    return daylogsObj;
  } catch (error) {
    console.error(error);
  }
}
