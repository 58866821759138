/**
 * Body.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/

import PropTypes from 'prop-types';
import DeviceSettings from './components/Device/DeviceSettings';
import * as utils from '../utils';
import PersonalHomePage from './PersonalHomePage';
import AccountSettings from './components/AccountSettings/AccountSettings';
import React, { useState, useEffect, useRef } from 'react';
//import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';

export default function Personal({
  db,
  setUser,
  user,
  updateUser,
  logout,
  changeLanguage,
  pageSettings,
  auth_user
}) {
  const analyticIdRef = useRef(
    user.analytics_id !== undefined ? user.analytics_id : user.uid
  );
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        userId: analyticIdRef.current,
        userProject: 'Personal',
        page: 'Device card'
      },
      dataLayerName: 'SaferaDataLayer'
    });
  }, []);

  var homePage = (
    <PersonalHomePage
      user={user}
      setUser={setUser}
      logout={logout}
      setPage={setPage}
    />
  );

  var defaultPageSettings;
  if (pageSettings) {
    defaultPageSettings = pageSettings;
    console.log(pageSettings);
  }
  const [defaultPaheSettings, setDefaultPaheSettings] =
    useState(defaultPageSettings);

  if (defaultPaheSettings) {
    console.log('defaultPaheSettings');
    homePage = getPageContent(pageSettings);
  }
  const [state, setState] = useState({
    content: homePage
  });

  function gaEventTracker(message) {
    console.log(message);
    const analyticId =
      user.analytics_id !== undefined ? user.analytics_id : user.uid;
    TagManager.dataLayer({
      dataLayer: {
        userId: analyticId,
        userProject: 'Personal',
        page: message
      },
      dataLayerName: 'SaferaDataLayer'
    });
    // ReactGA.send({ hitType: 'pageview', page: message });
  }
  function setPage(pageSettings) {
    //console.log(pageSettings);
    setDefaultPaheSettings();
    setState(() => ({
      content: getPageContent(pageSettings)
    }));
  }

  function getPageContent(pageSettings) {
    var pageContent;
    switch (pageSettings.page) {
      case utils.PERSONAL_PAGES['DEVICE_SETTINGS']:
        pageContent = (
          <DeviceSettings
            db={db}
            user={user}
            setPage={setPage}
            pageSettings={pageSettings}
            auth_user={auth_user}
          />
        );
        gaEventTracker('Device settings');

        break;
      case utils.PERSONAL_PAGES['DEVICE_LIST']:
        pageContent = (
          <PersonalHomePage
            user={user}
            setUser={setUser}
            logout={logout}
            setPage={setPage}
          />
        );
        gaEventTracker('Device card');

        break;
      case utils.PERSONAL_PAGES['ACCOUNT_SETTINGS']:
        pageContent = (
          <AccountSettings
            updateUser={updateUser}
            user={user}
            setPage={setPage}
            pageSettings={pageSettings}
            changeLanguage={changeLanguage}
            setUser={setUser}
            logout={logout}
          />
        );
        gaEventTracker('Account settings');

        break;
      default:
        pageContent = <></>;
        gaEventTracker('Error: Unknow');

        break;
    }
    return pageContent;
  }

  return state.content;
}

Personal.propTypes = {
  userDeviceSettings: PropTypes.array,
  user: PropTypes.object,
  devices: PropTypes.array,
  setUser: PropTypes.func,
  updateUser: PropTypes.func,
  translation: PropTypes.object,
  logout: PropTypes.func,
  changeLanguage: PropTypes.func,
  auth_user: PropTypes.object
};
