/**
 * Read user message data
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2023. All rights reserved. Safera Oy
 **/
import * as utils from 'utils';
import avatar from 'assets/img/team/avatar.png';

export async function ReadUserMessages(db, user) {
  try {
    const uid = user.uid;
    var msgArr = [];
    let msgRef = db
      .collection(utils.USERS_KEY + '/' + uid + '/' + utils.MESSAGE_KEY)
      .where('isVisible', '==', true);
    let msgDoc = await msgRef.get();
    if (msgDoc.empty) {
      console.log('Messages is not found.');
      return msgArr;
    } else {
      for (const doc of msgDoc.docs) {
        var image = doc.data().icon !== undefined ? doc.data().icon : avatar;

        var data = {
          id: doc.id,
          user: doc.data().sender,
          img: image !== '' ? image : avatar,
          title: doc.data().title,
          description: doc.data().message,
          message: doc.data().message,
          time: getInternationalTimeFormat(doc.data().timestamp, user.language),
          timestamp: doc.data().timestamp,
          token: doc.data().hiddenValue,
          senderUID: doc.data().sender_uid,
          read: doc.data().isRead,
          type: doc.data().type,
          group: isGroup(doc.data().type),
          share: isShare(doc.data().type),
          transfer: isTransfer(doc.data().type),
          groupId: doc.data().groupId,
          groupName:
            doc.data().groupName !== undefined ? doc.data().groupName : '',
          deviceId: doc.data().deviceId,
          deviceName:
            doc.data().deviceName !== undefined ? doc.data().deviceName : ''
        };
        if (isAttachments(doc.data().type)) {
          data.attachments = attachments(doc.data());
        }
        if (isGroupAction(doc.data().type)) {
          const devices = await groupAction(doc.id);
          data.devices = devices;
        }
        msgArr.push(data);
      }
      msgArr.sort((a, b) => b.timestamp - a.timestamp);
      return msgArr;
    }
  } catch (error) {
    console.error(error);
    return;
  }

  function isGroupAction(type) {
    if (type === undefined) {
      return false;
    }
    if (
      type === utils.MESSAGE_TYPE['share_group'] ||
      type === utils.MESSAGE_TYPE['transfer_group_ownership'] ||
      type === utils.MESSAGE_TYPE['share_group_done'] ||
      type === utils.MESSAGE_TYPE['transfer_group_ownership_done']
    ) {
      return true;
    }
    return false;
  }

  async function groupAction(messageId) {
    try {
      let msgRef = db.collection(
        utils.USERS_KEY +
          '/' +
          user.uid +
          '/' +
          utils.MESSAGE_KEY +
          '/' +
          messageId +
          '/device'
      );
      let msgDoc = await msgRef.get();
      var retVal = {};
      for (const doc of msgDoc.docs) {
        retVal[doc.id] = { name: doc.data().name };
      }
      return retVal;
    } catch (error) {
      console.error(error);
      return {};
    }
  }

  function isAttachments(type) {
    if (type === undefined) {
      return false;
    }
    if (
      type === utils.MESSAGE_TYPE['share'] ||
      type === utils.MESSAGE_TYPE['share_group'] ||
      type === utils.MESSAGE_TYPE['transfer_ownership'] ||
      type === utils.MESSAGE_TYPE['transfer_group_ownership']
    ) {
      return true;
    }
    return false;
  }

  function isTransfer(type) {
    if (type === undefined) {
      return false;
    }
    if (
      type === utils.MESSAGE_TYPE['transfer_ownership'] ||
      type === utils.MESSAGE_TYPE['transfer_ownership_done']
    ) {
      return true;
    }
    return false;
  }

  function isShare(type) {
    if (type === undefined) {
      return false;
    }
    if (
      type === utils.MESSAGE_TYPE['share'] ||
      type === utils.MESSAGE_TYPE['ack']
    ) {
      return true;
    }
    return false;
  }

  function isGroup(type) {
    if (type === undefined) {
      return false;
    }
    if (
      type === utils.MESSAGE_TYPE['share_group'] ||
      type === utils.MESSAGE_TYPE['share_group_done'] ||
      type === utils.MESSAGE_TYPE['transfer_group_ownership_done'] ||
      type === utils.MESSAGE_TYPE['transfer_group_ownership']
    ) {
      return true;
    }
    return false;
  }

  function attachments(docData) {
    var name = docData.deviceName !== undefined ? docData.deviceName : '';
    var icon = 'file-alt';
    if (
      docData.type === utils.MESSAGE_TYPE['share_group'] ||
      docData.type === utils.MESSAGE_TYPE['transfer_group_ownership']
    ) {
      icon = 'layer-group';
      name = docData.groupName !== undefined ? docData.groupName : '';
    }
    return [{ id: 1, type: 'doc', icon: icon, fileName: name }];
  }

  function getInternationalTimeFormat(timestamp, lang) {
    var date = new Date(timestamp);

    var options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric'
    };
    return new Intl.DateTimeFormat(lang, options).format(date);
  }
}
