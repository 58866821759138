/**
 * Account Settings.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2022. All rights reserved. Safera Oy
 **/

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import * as utils from '../../../utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEllipsisH,
  faChevronLeft,
  faUserCircle,
  faCube,
  faShieldAlt
} from '@fortawesome/free-solid-svg-icons';
import {
  faAddressCard,
  faCreditCard
} from '@fortawesome/free-regular-svg-icons';
import useTranslation from 'hooks/translations';
import ContactInfo from './ContactInfo';
import CloudPackage from './CloudPackage';
import UserSettings from './UserSettings';
import Policy from './Policy';
import Payment from './Payment';
//import ReactGA from 'react-ga4';
import { v4 as uuidv4 } from 'uuid';
import TagManager from 'react-gtm-module';

export default function AccountSettings(props) {
  const translation = useTranslation();
  const scrollContainer = useRef(null);
  const analyticId =
    props.user.analytics_id !== undefined
      ? props.user.analytics_id
      : props.user.uid;

  const subPageRef = useRef(props.pageSettings.subPage);
  useEffect(() => {
    //console.log('scrollContainer: ', scrollContainer.current);
    if (
      subPageRef.current === utils.PERSONAL_ACCOUNT_SETTINGS_SUB_PAGE['PAYMENT']
    ) {
      scrollContainer.current.scrollLeft = 200;
    } else if (
      subPageRef.current === utils.PERSONAL_ACCOUNT_SETTINGS_SUB_PAGE['POLICY']
    ) {
      scrollContainer.current.scrollLeft = 450;
    }
  }, []);
  const contactSettingsMenuRef = useRef();
  const accountSettingsMenuRef = useRef();
  const paymentMenuRef = useRef();
  const policyMenuRef = useRef();
  const [cloudPackage, setPackage] = useState(
    <CloudPackage user={props.user} />
  );

  function updateCloudPackage(user) {
    setPackage(
      <div key={uuidv4()}>
        <CloudPackage user={user} />
      </div>
    );
  }

  function newTabSelected(page) {
    const SMALL_SCREEN_SIZE_WIDTH = 650;
    if (scrollContainer.current.clientWidth < SMALL_SCREEN_SIZE_WIDTH) {
      var pageInfo = 'Account settings';
      switch (page) {
        case utils.PERSONAL_ACCOUNT_SETTINGS_SUB_PAGE['CONTACT']:
          moveScrollToPosition(0);
          //ReactGA.send({ hitType: 'pageview', page: 'Contact information' });
          pageInfo = 'Contact information';
          break;
        case utils.PERSONAL_ACCOUNT_SETTINGS_SUB_PAGE['ACCOUT_SETTINGS']:
          moveScrollToPosition(
            contactSettingsMenuRef.current.clientWidth -
              accountSettingsMenuRef.current.clientWidth / 4
          );
          //ReactGA.send({ hitType: 'pageview', page: 'Account settings' });
          pageInfo = 'Account settings';
          break;
        case utils.PERSONAL_ACCOUNT_SETTINGS_SUB_PAGE['PAYMENT']:
          moveScrollToPosition(
            contactSettingsMenuRef.current.clientWidth +
              accountSettingsMenuRef.current.clientWidth -
              paymentMenuRef.current.clientWidth / 4
          );
          //ReactGA.send({ hitType: 'pageview', page: 'Payment' });
          pageInfo = 'Payment';
          break;
        case utils.PERSONAL_ACCOUNT_SETTINGS_SUB_PAGE['POLICY']:
          moveScrollToPosition(
            contactSettingsMenuRef.current.clientWidth +
              accountSettingsMenuRef.current.clientWidth +
              paymentMenuRef.current.clientWidth
          );
          //ReactGA.send({ hitType: 'pageview', page: 'Policy' });
          pageInfo = 'Policy';
          break;
        default:
          break;
      }
      TagManager.dataLayer({
        dataLayer: {
          userId: analyticId,
          userProject: 'Personal',
          page: pageInfo
        },
        dataLayerName: 'SaferaDataLayer'
      });
    }
  }

  async function moveScrollToPosition(position) {
    const currentPosition = scrollContainer.current.scrollLeft;
    if (currentPosition > position) {
      for (let index = 0; index < currentPosition - position; index++) {
        await new Promise(resolve => setTimeout(resolve, 2));
        if (scrollContainer.current !== null) {
          scrollContainer.current.scrollLeft--;
        }
      }
    } else {
      for (let index = 0; index < position - currentPosition; index++) {
        await new Promise(resolve => setTimeout(resolve, 2));
        if (scrollContainer.current !== null) {
          scrollContainer.current.scrollLeft++;
        }
      }
    }
  }
  var contactActive = 'active';
  var accountSettingsActive = '';
  var paymentActive = '';
  var policyActive = '';
  var subpageInfo = 'Account settings';

  switch (props.pageSettings.subPage) {
    case utils.PERSONAL_ACCOUNT_SETTINGS_SUB_PAGE['CONTACT']:
      contactActive = 'active';
      accountSettingsActive = '';
      paymentActive = '';
      policyActive = '';
      //ReactGA.send({ hitType: 'pageview', page: 'Contact information' });
      subpageInfo = 'Contact information';
      break;
    case utils.PERSONAL_ACCOUNT_SETTINGS_SUB_PAGE['ACCOUT_SETTINGS']:
      contactActive = '';
      accountSettingsActive = 'active';
      paymentActive = '';
      policyActive = '';
      //ReactGA.send({ hitType: 'pageview', page: 'Account settings' });
      subpageInfo = 'Account settings';
      break;
    case utils.PERSONAL_ACCOUNT_SETTINGS_SUB_PAGE['PAYMENT']:
      contactActive = '';
      accountSettingsActive = '';
      paymentActive = 'active';
      policyActive = '';
      //ReactGA.send({ hitType: 'pageview', page: 'Payment' });
      subpageInfo = 'Payment';
      break;
    case utils.PERSONAL_ACCOUNT_SETTINGS_SUB_PAGE['POLICY']:
      contactActive = '';
      accountSettingsActive = '';
      paymentActive = '';
      policyActive = 'active';
      //ReactGA.send({ hitType: 'pageview', page: 'Policy' });
      subpageInfo = 'Policy';
      break;
    default:
      break;
  }

  TagManager.dataLayer({
    dataLayer: {
      userId: analyticId,
      userProject: 'Personal',
      page: subpageInfo
    },
    dataLayerName: 'SaferaDataLayer'
  });

  return (
    <div className="device-settings-page h-100">
      <div className="settings-page-container container p-0 h-100 pb-md-3">
        <div className="container settings-container sense-wifi h-100">
          <div className="row hero-background-gradient h-100">
            <div className="col hero-image h-100">
              <div className="row sticky-top">
                <div className="col">
                  <div className="row justify-content-start flex-nowrap nav-bar-settings-page">
                    <div className="col-4 d-flex justify-content-start align-items-center settings-navbar-left-column overflow-hidden p-0">
                      <p
                        className="btn btn-link flex-shrink-0 text-white lh-base"
                        role="button"
                        onClick={() =>
                          props.setPage({
                            page: utils.PERSONAL_PAGES['DEVICE_LIST']
                          })
                        }
                      >
                        <FontAwesomeIcon
                          icon={faChevronLeft}
                          className="text-primary me-2"
                        />
                        {translation.back}
                      </p>
                    </div>
                    <div className="col-4 d-flex flex-grow-0 justify-content-center align-items-center p-0 ps-2">
                      <h4 className="text-nowrap text-start text-white m-0 p-0 lh-base">
                        {translation.user_account}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-auto d-inline-flex hero-settings-page sense-wifi w-100 p-0 py-3">
                  <div className="w-100">
                    <div className="container h-100 h-md-75 pb-4 pb-md-2">
                      <div className="row row-cols-1 justify-content-end h-100 mb-1">
                        <div className="col-3">
                          <div></div>
                        </div>
                        <div className="col-6 d-flex justify-content-center align-bottom">
                          <FontAwesomeIcon
                            icon={faUserCircle}
                            style={{
                              display: 'inline-block',
                              alignSelf: 'flex-end'
                            }}
                            className="d-flex align-items-end text-dark fs-7 align-bottom"
                          />
                        </div>
                        <div className="col-3 d-inline-flex justify-content-end align-items-start p-0">
                          <div className="dropdown d-inline-flex justify-content-end align-items-center justify-content-sm-center align-items-sm-center">
                            <button
                              className="btn bg-white d-inline-flex justify-content-center align-items-center flex-nowrap m-auto btn-falcon-default btn-circle-with-icon shadow"
                              aria-expanded="false"
                              data-bs-toggle="dropdown"
                              type="button"
                            >
                              <FontAwesomeIcon
                                icon={faEllipsisH}
                                className="text-primary"
                              />
                            </button>
                            <div className="dropdown-menu dropdown-menu-end">
                              <p
                                className="dropdown-item"
                                onClick={props.logout}
                                style={{ cursor: 'pointer' }}
                              >
                                {translation.sign_out}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row flex-column">
                        <div className="col d-flex justify-content-center px-3">
                          <h4 className="text-truncate justify-content-center my-auto py-1 d-inline-block">
                            {props.user.email !== undefined
                              ? props.user.email
                              : ''}
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row d-flex flex-column row-tabs-system-container">
                <div className="col tabs-system-container-settings-page p-0 h-100 w-100">
                  <div className="d-md-flex tabbable col-auto h-100">
                    <ul
                      className="nav nav-pills flex-shrink-0 align-items-end flex-md-column align-items-md-start flex-md-nowrap tab-items-settings-page w-auto h-md-100 overflow-scroll p-3 pb-md-5 gap-md-2 gap-3 py-3"
                      role="tablist"
                      ref={scrollContainer}
                    >
                      <li
                        className="nav-item col-auto w-md-100"
                        role="presentation"
                        ref={contactSettingsMenuRef}
                      >
                        <a
                          className={`nav-link d-flex align-items-center ${contactActive}`}
                          role="tab"
                          data-bs-toggle="pill"
                          href="#tab-1"
                          onClick={() =>
                            newTabSelected(
                              utils.PERSONAL_ACCOUNT_SETTINGS_SUB_PAGE[
                                'CONTACT'
                              ]
                            )
                          }
                        >
                          <div className="d-inline-flex dropdown-menu-icon">
                            <FontAwesomeIcon icon={faAddressCard} />
                          </div>
                          {translation.contact_information}
                        </a>
                      </li>
                      <li
                        className="nav-item col-auto w-md-100"
                        role="presentation"
                        ref={accountSettingsMenuRef}
                      >
                        <a
                          className={`nav-link d-flex align-items-center ${accountSettingsActive}`}
                          role="tab"
                          data-bs-toggle="pill"
                          href="#tab-2"
                          onClick={() =>
                            newTabSelected(
                              utils.PERSONAL_ACCOUNT_SETTINGS_SUB_PAGE[
                                'ACCOUT_SETTINGS'
                              ]
                            )
                          }
                        >
                          <div className="d-inline-flex dropdown-menu-icon">
                            <FontAwesomeIcon icon={faCube} />
                          </div>
                          {translation.plans_and_subscriptions}
                        </a>
                      </li>
                      <li
                        className="nav-item col-auto w-md-100"
                        role="presentation"
                        ref={paymentMenuRef}
                      >
                        <a
                          className={`nav-link d-flex align-items-center ${paymentActive}`}
                          role="tab"
                          data-bs-toggle="pill"
                          href="#tab-3"
                          onClick={() =>
                            newTabSelected(
                              utils.PERSONAL_ACCOUNT_SETTINGS_SUB_PAGE[
                                'PAYMENT'
                              ]
                            )
                          }
                        >
                          <div className="d-inline-flex dropdown-menu-icon">
                            <FontAwesomeIcon icon={faCreditCard} />
                          </div>
                          {translation.billing}
                        </a>
                      </li>
                      <li
                        className="nav-item col-auto w-md-100 pe-3"
                        role="presentation"
                        ref={policyMenuRef}
                      >
                        <a
                          className={`nav-link d-flex align-items-center ${policyActive}`}
                          role="tab"
                          data-bs-toggle="pill"
                          href="#tab-4"
                          onClick={() =>
                            newTabSelected(
                              utils.PERSONAL_ACCOUNT_SETTINGS_SUB_PAGE['POLICY']
                            )
                          }
                        >
                          <div className="d-inline-flex dropdown-menu-icon">
                            <FontAwesomeIcon icon={faShieldAlt} />
                          </div>
                          {translation.privacy}
                        </a>
                      </li>
                    </ul>
                    <div className="tab-content flex-grow-1 tab-content-settings-page w-auto p-3 mb-sm-3 h-md-100 pt-5">
                      <div
                        className={`tab-pane ${contactActive}`}
                        role="tabpanel"
                        id="tab-1"
                      >
                        <ContactInfo
                          user={props.user}
                          changeLanguage={props.changeLanguage}
                          setUser={props.setUser}
                          updateCloudPackage={updateCloudPackage}
                        />
                      </div>
                      <div
                        className={`tab-pane ${accountSettingsActive}`}
                        role="tabpanel"
                        id="tab-2"
                      >
                        {cloudPackage}
                        <UserSettings />
                      </div>
                      <div
                        className={`tab-pane ${paymentActive}`}
                        role="tabpanel"
                        id="tab-3"
                      >
                        <Payment user={props.user} />
                      </div>
                      <div
                        className={`tab-pane ${policyActive}`}
                        role="tabpanel"
                        id="tab-4"
                      >
                        <Policy />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

AccountSettings.propTypes = {
  user: PropTypes.object,
  setPage: PropTypes.func,
  pageSettings: PropTypes.object,
  updateUser: PropTypes.func,
  changeLanguage: PropTypes.func,
  setUser: PropTypes.func,
  updateCloudPackage: PropTypes.func,
  logout: PropTypes.func
};
