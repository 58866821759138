import React, { useReducer, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { EmailContext, AdvancedContext } from 'context/Context';
import { emailReducer } from 'reducers/emailReducer';
//import rawEmails from 'data/email/emails';
import { useStoreState } from 'easy-peasy';
import useTranslation from 'hooks/translations';
import * as utils from 'utils';

const EmailProvider = ({ children }) => {
  const advancedDispatch = useContext(AdvancedContext);
  console.log(advancedDispatch);
  const translation = useTranslation();
  const messages = useStoreState(state => state.messages);
  const updatedMessages = getTranslatedMessages(messages);
  const [emailState, emailDispatch] = useReducer(emailReducer, {
    emails: updatedMessages,
    allEmails: updatedMessages,
    filters: ['all', 'unread', 'share', 'transfer', 'group'],
    currentFilter: 'all',
    db: advancedDispatch.db,
    user: advancedDispatch.user
  });

  useEffect(() => {
    console.log(emailState);
  }, [emailState]);

  function getTranslatedMessages(messages) {
    var retVal = [];
    if (messages !== undefined) {
      for (var msg of messages) {
        retVal.push(getTranslatedMessage(msg));
      }
    }
    return retVal;
  }

  function getTranslatedMessage(msg) {
    var description = '';
    var title = '';
    switch (msg.type) {
      case utils.MESSAGE_TYPE['share']:
      case utils.MESSAGE_TYPE['share_done']:
        description = msg.user + ' ' + translation.message_share_header;
        title = translation.message_share_title;
        break;

      case utils.MESSAGE_TYPE['share_group']:
      case utils.MESSAGE_TYPE['share_group_done']:
        description = msg.user + ' ' + translation.message_share_group_header;
        title = translation.message_group_title;
        break;
      case utils.MESSAGE_TYPE['transfer_ownership']:
      case utils.MESSAGE_TYPE['transfer_ownership_done']:
        description = msg.user + ' ' + translation.message_ownership_header;
        title = translation.message_ownership_title;
        break;
      case utils.MESSAGE_TYPE['transfer_group_ownership']:
      case utils.MESSAGE_TYPE['transfer_group_ownership_done']:
        description =
          msg.user + ' ' + translation.message_group_ownership_header_a;
        title = translation.message_ownership_title;
        break;
      default:
        description = msg.message;
        title = msg.title;
        break;
    }
    msg.description = description;
    msg.title = title;
    return msg;
  }
  return (
    <EmailContext.Provider value={{ emailState, emailDispatch }}>
      {children}
    </EmailContext.Provider>
  );
};

EmailProvider.propTypes = { children: PropTypes.node };

export default EmailProvider;
