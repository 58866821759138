/**
 * Remove Group name.
 *
 * Safera Oy
 * Author: Heikki Pirttinen
 *
 * © Copyright 2023. All rights reserved. Safera Oy
 **/

import * as utils from '../../../utils';
import CheckAccess from '../CheckAccess';

export default async function RemoveGroup(db, groupDevices, isGuest, uid) {
  try {
    for (const device of groupDevices) {
      if (isGuest) {
        if (device.docId !== undefined && device.docId !== '') {
          const grbRef = db
            .collection(utils.USERS_KEY + '/' + uid + '/' + utils.DEVICE_KEY)
            .doc(device.docId);
          await grbRef.set({ groupId: '' }, { merge: true });
        }
      } else {
        if (device.device_id !== undefined && device.device_id !== '') {
          const access = await CheckAccess(db, device.device_id, uid);
          if (access.isValid && access.isOwner) {
            const grbRef = db
              .collection(utils.DEVICE_KEY)
              .doc(device.device_id);
            await grbRef.set({ groupId: '' }, { merge: true });
          }
        }
      }
    }
    return true;
  } catch (err) {
    console.error(err);
    return false;
  }
}
